import ApiService from './ApiService';

class TransactionConfirmApi extends ApiService {
    constructor() {
        super('transaction-confirm');
    }

    getTransactionInfo({ id, secret }, payload) {
        return this.get(`info?id=${id}&secret=${secret}`, payload);
    }

    transactionConfirmAccept({ id, secret }, payload) {
        return this.get(`accept?id=${id}&secret=${secret}`, payload);
    }

    transactionConfirmAirdropStatus({ id, secret }, payload) {
        return this.get(`airdrop-status?id=${id}&secret=${secret}`, payload);
    }
}

export default TransactionConfirmApi;