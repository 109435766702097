import classnames from 'classnames';
import './style.scss';

const ModalFooter = (props) => {    
    const {
        saveButtonText,
        saveButtonClass,
        onConfirm,
        onCancel,
        cancelButtonText,
        disableSaveButton,
        classNameCustom,
    } = props;
    const classButton = saveButtonClass ? saveButtonClass : 'btn-bg-cs-1';
    
    return (
        <div className={classnames('modal-footer', classNameCustom)}>
            {cancelButtonText && (
                <button
                    type='button'
                    className='btn-default btn-cancel cancel-button'
                    data-dismiss='modal'
                    onClick={onCancel}
                >
                    {cancelButtonText}
                </button>
            )}
            {
                !disableSaveButton && (
                    <button
                        type='button'
                        className={`${classButton} btn-default`}
                        onClick={onConfirm}
                    >
                        {saveButtonText}
                    </button>
                )
            }
        </div>
    );
};

export default ModalFooter;
