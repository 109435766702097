/* eslint-disable indent */
import { useNavigate, useParams } from 'react-router-dom';
// eslint-disable-next-line max-len
import { TEXT, ACCOUNT_STATUS, MSG, FORMAT_DATE_TIME, HOLDER_TYPE, HOLDER_TYPE_MAPPING, MEMBERSHIP_TYPE, FORMAT_DATE_ISO } from '../../../core/configs/constants';
import { observer } from 'mobx-react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import yup from '../../../core/utils/yupValidate';
import { useStore } from '../../../core/utils/hook';
import { useEffect, useMemo } from 'react';
import { DateTimeCalendarCustom, ReactNotifications, Table } from '../../../components';
import moment from 'moment';
import { calculateNextDateAfterMonth } from '../../../core/utils/common';

const AccountInfo = observer((props) => {

    // other
    const navigate = useNavigate();
    const { id } = useParams();
    //store
    const { accountStore: { account, getAccount, updateAccount, setAttrObservable } } = useStore()
    //state
    const validateSchema = yup.object().shape({
        userName: yup.string().trim().max(12, MSG['error.length_12']).required(MSG['error.required']),
        status: yup.string().required(MSG['error.required']),
        reason: yup.mixed().when('status', {
            is: ACCOUNT_STATUS.FORCED_TERMIMATION.key.toString(),
            then: yup.string().max(150, MSG['error.length_150']).nullable().required(MSG['error.required']),
            otherwise: yup.string().nullable()
        }),
        activeHolders: yup.array().of(yup.object().shape({
            id: yup.number(),
            type: yup.number(),
            isPayMonthly: yup.boolean().required(MSG['error.required']),
            expiryStartDate: yup.mixed().when(['type', 'isPayMonthly'], {
                is: (type, isPayMonthly) => type === HOLDER_TYPE.FEE && isPayMonthly,
                then: yup.string().nullable().required(MSG['error.required']),
                otherwise: yup.string().nullable().transform(_ => null)
            })
        })).min(0).notRequired(),
        membershipType: yup.mixed().when('status', {
            is: ACCOUNT_STATUS.SUBSCRIBED.key.toString(),
            then: yup.string().nullable().required(MSG['error.required']),
            otherwise: yup.string().nullable().notRequired()
        }),
        registerDate: yup.mixed().when('status', {
            is: ACCOUNT_STATUS.SUBSCRIBED.key.toString(),
            then: yup.string().nullable().required(MSG['error.required']),
            otherwise: yup.string().nullable().notRequired()
        })
    })
    const { register, handleSubmit, formState: { errors, isSubmitting }, watch, getValues, reset, setValue } = useForm({
        resolver: yupResolver(validateSchema), mode: 'onChange',
    })

    const watchStatus = watch('status');
    const watchMembershipType = watch('membershipType');

    const aMonthBeforeDate = new Date(moment().subtract(1, 'M').add(1, 'd').format(FORMAT_DATE_ISO));
    const aYearBeforeDate = new Date(moment().subtract(1, 'y').add(1, 'd').format(FORMAT_DATE_ISO));
    const minRegisterDate = useMemo(() => {
        if(watchMembershipType == MEMBERSHIP_TYPE.MONTH.key) return aMonthBeforeDate;
        if(watchMembershipType == MEMBERSHIP_TYPE.YEAR.key) return aYearBeforeDate;
        return null;
    }, [watchMembershipType])

    useEffect(() => {
        const { userName, status, reason = '', activeHolders, membershipType, registerDate } = account;
        reset({ userName, status: String(status), reason, activeHolders, membershipType, registerDate });
    }, [account])

    //modal
    //function
    const onSubmitUpdate = async (data) => {
        const { userName, status, reason, activeHolders, membershipType, registerDate } = data;

        let res = await updateAccount(id, { 
            userName, 
            status: Number(status), 
            reason: status == ACCOUNT_STATUS.FORCED_TERMIMATION.key ? reason : '', 
            membershipType:  status == ACCOUNT_STATUS.SUBSCRIBED.key ? Number(membershipType) : null,
            registerDate:  status == ACCOUNT_STATUS.SUBSCRIBED.key ? registerDate : null,
            activeHolders: activeHolders?.length > 0 ? activeHolders.map(
                ({id, type, isPayMonthly, expiryStartDate}, _) => ({ id, type, isPayMonthly, expiryStartDate })
            ) : [] 
        });
        if (res) {
            ReactNotifications('success', MSG['inform.success.update']);
            if(status != ACCOUNT_STATUS.SUBSCRIBED.key) {
                setValue('activeHolders', [], { shouldValidate: true });
            }
            getAccount(id);
        }
    }

    const onChangeSelectIsPayMonthly = (e, index) => {
        const expiryStartDate = e?.target?.checked ? calculateNextDateAfterMonth(moment(), 1).format('YYYY-MM-DD') : null;
        setValue(`activeHolders.${index}.expiryStartDate`, expiryStartDate, { shouldValidate: true });
    }
    
    const onChangeStatus = (e) => {
        const value = e.target.value;
        const isSubcribed = value == ACCOUNT_STATUS.SUBSCRIBED.key;
        setValue('membershipType', isSubcribed ? MEMBERSHIP_TYPE.MONTH.key : null);
        setValue('registerDate', isSubcribed ? moment().format(FORMAT_DATE_ISO) : null);
    }

    const onChangeMembershipType = (e) => {
        const value = e.target.value;
        let registerDate = getValues('registerDate');
        if(!registerDate) {
            registerDate = moment().format(FORMAT_DATE_ISO);
        } else {
            if(value == MEMBERSHIP_TYPE.MONTH.key && moment(registerDate).isBefore(aMonthBeforeDate)) {
                registerDate = aMonthBeforeDate;
            }
        }
        setValue('registerDate', registerDate);
    }

    // columns
    const columns = [
        {
            Header: 'Holder',
            accessor: 'id',
            disableSortBy: true,
            Cell: ({ row: { original, index } }) => {
                return (
                    <div>
                        Holder {index + 1}
                    </div>
                );
            },
            width: '20%'
        },
        {
            Header: '種類',
            accessor: 'type',
            disableSortBy: true,
            Cell: ({ row: { original } }) => {
                return (
                    <div>
                        {HOLDER_TYPE_MAPPING.LABEL[original?.type]}
                    </div>
                );
            },
            width: '20%'
        },
        {
            Header: '毎月のHolder料金を支払う対象',
            accessor: 'isPayMonthly',
            disableSortBy: true,
            Cell: ({ row: { original, index } }) => {
                return (
                    <div>
                        {
                            original?.type === HOLDER_TYPE.FEE && 
                            <>
                                <div>
                                    <input {...register(`activeHolders.${index}.isPayMonthly`, {
                                    onChange: e => onChangeSelectIsPayMonthly(e, index)
                                    })} type='checkbox'/>
                                </div>
                                {
                                    errors.activeHolders?.[index]?.isPayMonthly &&
                                    <div className='text-danger fs-error mg-t-5 pd-0 text-start'>
                                        {errors.activeHolders[index].isPayMonthly?.message}
                                    </div>
                                }
                            </>
                        }
                    </div>
                );
            },
            width: '30%'
        },
        {
            Header: '有効期限',
            accessor: 'expiryStartDate',
            disableSortBy: true,
            Cell: ({ row: { original, index } }) => {
                return (
                    <div>
                        {
                            original?.type === HOLDER_TYPE.FEE && original?.isPayMonthly &&
                            <>
                                <div>
                                    <DateTimeCalendarCustom
                                        startDate={getValues(`activeHolders.${index}.expiryStartDate`) || null}
                                        onChange={date => {
                                            setValue(`activeHolders.${index}.expiryStartDate`, date ? moment(date).format('YYYY-MM-DD') : null, 
                                                { shouldValidate: true });
                                        }}
                                    />
                                </div>
                                {
                                    errors.activeHolders?.[index]?.expiryStartDate &&
                                    <div className='text-danger fs-error mg-t-5 pd-0 text-start'>
                                        {errors.activeHolders[index].expiryStartDate?.message}
                                    </div>
                                }
                            </>
                            
                        }
                    </div>
                );
            }
        },
    ]

    return (
        <div className='account-info-screen'>
            <div className='row justify-content-center align-items-center'>
                <form onSubmit={handleSubmit(onSubmitUpdate)}>
                    <div className='row justify-content-center align-items-center'>
                        <div className='row justify-content-between mg-t-20'>
                            <div className='row col-6 align-items-center'>
                                <span className='col-3 fw-semibold'>ユーザーID</span>
                                <span className='col-8 pd-0'>{account.user?.code}</span>
                            </div>
                            <div className='row col-6 align-items-center justify-content-end'>
                                <span className='col-3 fw-semibold'>名前-本名</span>
                                <span className='col-8 pd-0'>{account.user?.fullName}</span>
                            </div>
                        </div>
                        <div className='row justify-content-between mg-t-20'>
                            <div className=' row col-6'>
                                <span className='col-3 fw-semibold'>メールアドレス</span>
                                <span className='col-8 pd-0'>{account.user?.email}</span>
                            </div>
                        </div>
                        <div className='row border-bottom mg-t-20'></div>
                        <div className='row justify-content-between mg-t-25'>
                            <div className=' row col-6 align-items-center'>
                                <span className='col-3 fw-semibold'>アカウントID</span>
                                <span className='col-8 pd-0'>{account?.code}</span>
                            </div>
                            <div className='row col-6 align-items-center justify-content-end'>
                                <span className='col-3 fw-semibold'>作成日</span>
                                <span className='col-8 pd-0'>{account?.createdAt ?
                                    moment(new Date(account.createdAt)).format(FORMAT_DATE_TIME) : ''}</span>
                            </div>
                        </div>
                        <div className='row justify-content-between mg-t-20'>
                            <div className='row col-6 align-items-center'>
                                <label className='col-3 justify-content-start field-required' >ユーザーネーム</label>
                                <div className='col-8 pd-0'>
                                    <input type='text' {...register('userName')} className='w-100' maxLength={12}/>
                                    {
                                        errors.userName &&
                                        <div className='text-danger fs-error mg-t-5 pd-0'>{errors.userName?.message}</div>
                                    }
                                </div>
                            </div>
                            <div className='row col-6 align-items-center justify-content-end'>
                                <label className='col-3 field-required'>ステータス</label>
                                <select {...register('status', {
                                    onChange: onChangeStatus
                                })} className='col-8' required>
                                    {Object.values(ACCOUNT_STATUS).map(({key, label}) =>
                                        <option key={key} value={key}>
                                            {label}
                                        </option>)}
                                </select>
                                {
                                    errors.status &&
                                    <div className='text-danger fs-error mg-t-5 pd-0'>{errors.status?.message}</div>
                                }
                            </div>
                        </div>
                        {
                            watchStatus == ACCOUNT_STATUS.FORCED_TERMIMATION.key &&
                            <div className='row justify-content-between mg-t-20'>
                                <div className='row col-6'>
                                    <label className='col-3 field-required'>理由</label>
                                    <div className='col-8 pd-0'>
                                        <textarea {...register('reason')} className='w-100' maxLength={150}/>
                                        {errors.reason &&
                                            <div className='text-danger fs-error mg-t-5 pd-0'>{errors.reason?.message}</div>}
                                    </div>
                                </div>

                            </div>
                        }
                        {
                            watchStatus == ACCOUNT_STATUS.SUBSCRIBED.key &&
                            <div className='row justify-content-between mg-t-20'>
                                <div className='row col-6 align-items-center'>
                                    <label className='col-3 field-required'>有料会員ステータス</label>
                                    <div className='col-8 pd-0'>
                                        <select {...register('membershipType', {
                                            onChange: onChangeMembershipType
                                        })} className='w-100'>
                                            {Object.values(MEMBERSHIP_TYPE).map(({key, label}) =>
                                                <option key={key} value={key}>
                                                    {label}
                                                </option>)}
                                        </select>
                                        {
                                            errors.membershipType &&
                                            <div className='text-danger fs-error mg-t-5 pd-0'>{errors.membershipType?.message}</div>
                                        }
                                    </div>
                                </div>
                                <div className='row col-6 align-items-center justify-content-end'>
                                    <label className='col-3 justify-content-start field-required' >有料会員開始日</label>
                                    <div className='col-8 pd-0'>
                                        <DateTimeCalendarCustom
                                            minDate={minRegisterDate}
                                            startDate={getValues('registerDate') || null}
                                            onChange={date => {
                                                setValue('registerDate', date ? moment(date).format('YYYY-MM-DD') : null, 
                                                    { shouldValidate: true });
                                            }}
                                        />
                                        {
                                            errors.registerDate &&
                                            <div className='text-danger fs-error mg-t-5 pd-0'>{errors.registerDate?.message}</div>
                                        }
                                    </div>
                                </div>
                            </div>
                        }
                        <div className='row mg-t-20'>
                            <label className='col-12'>有効なHolderの一覧</label>
                            <Table
                                columns={columns}
                                data={getValues('activeHolders') || []}
                                disablePaging={true}
                                className={'mg-t-10'}
                            />
                        </div>
                    </div>
                    <div className='d-flex justify-content-center align-items-center mg-t-100'>
                        <button type='button' className='btn-default btn-cancel width-150'
                            onClick={() => navigate(`/user/account-list/${account?.user?.id}`)}>
                            {TEXT.CANCEL}
                        </button>
                        <button type='submit' className='btn btn-bg-cs-1 width-150 mg-l-50'>
                            {TEXT.UPDATE}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
});

export default AccountInfo;