import React, { useEffect } from 'react';
import { Chart } from 'react-chartjs-2';
import { CARD_DASHBOARD_TYPE, CHART_LABELS_DEFAULT, DASHBOARD_TYPE } from '../../../../core/configs/constants';
import { useNavigate } from 'react-router-dom';
import { useStore } from '../../../../core/utils/hook';
import { observer } from 'mobx-react';

const CardByMonthStatistical = observer(({year}) => {

    //other
    const navigate = useNavigate();

    //store
    const { dashboardStore: { cardList, getDashboardCard } } = useStore();

    //life cycle
    useEffect(() => {
        fetchData();
    }, [year])

    //function
    const fetchData = async() => {
        await getDashboardCard({type:DASHBOARD_TYPE.OVERVIEW , year: year});
    }

    const handleBarClick = (event, elements) => {
        if (elements.length === 0) {
            // No bars were clicked
            return;
        }    
        const firstElement = elements[0];
        const datasetIndex = firstElement.datasetIndex;
        const index = firstElement.index;
        if ( datasetIndex === 0) {
            return;
        }
        navigate(`/dashboard/realease-card/${index+1}-${year}-${datasetIndex}`);
    };

    const handleBarHover = (event, chartElement) => {
        event.native.target.style.cursor = chartElement[0] ? 'pointer' : 'default';
    }

    // options chart
    const options = {
        onClick: (event, elements) => {
            handleBarClick(event, elements);
        },
        onHover: handleBarHover,
        responsive: true,
        maintainAspectRatio: true,
        plugins: {
            legend: {
                position: 'bottom',
            },
            title: {
                display: true,
            },
            tooltip: {
                callbacks: {
                    label: (context) => {
                        let label = '';
                        if (context.parsed.y) {
                            label = context.dataset.label + ': ' + context.parsed.y + ' 枚'
                        }
                        return label;
                       
                    }
                }
            },
        },
    };

    const data = {
        labels: CHART_LABELS_DEFAULT,
        datasets: [
            {
                type: 'line',
                label: '総カード枚数 ',
                borderColor: '#c00000',
                backgroundColor: '#c00000',
                borderWidth: 2,
                fill: false,
                data: cardList?.total?.map((item) => item.value),
            },
            {
                type: 'bar',
                label: '選択可能カード枚数',
                backgroundColor: '#3366bf',
                data: cardList?.noOwner?.map((item) => item.value),
                borderColor: 'white',
                borderWidth: 2,
            },
            {
                type: 'bar',
                label: 'HOLD済みカード枚数',
                backgroundColor: '#f4833d',
                data: cardList?.owning?.map((item) => item.value),
                borderColor: 'white',
                borderWidth: 2,
            },
            {
                type: 'bar',
                label: '当月販売済みカード枚数',
                backgroundColor: '#9f9f9f',
                data: cardList?.purchased?.map((item) => item.value),
                borderColor: 'white',
                borderWidth: 2,
            },
            {
                type: 'bar',
                label: '新規作成カード枚数',
                backgroundColor: '#ffc614',
                data: cardList?.new?.map((item) => item.value),
                borderColor: 'white',
                borderWidth: 2,
            }
        ],
    };

    return (
        <div className='d-flex justify-content-center align-items-center w-full height-500'>
            <Chart type='bar' data={data} options={options} />
        </div>
    );
})

export default CardByMonthStatistical;
