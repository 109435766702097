import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../style.scss'
import moment from 'moment';
import {
    Chart as ChartJS,
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
    LineController,
    BarController,
} from 'chart.js';
import { observer } from 'mobx-react';
import { useStore } from '../../../core/utils/hook';
import { DASHBOARD_TYPE, MSG } from '../../../core/configs/constants';
import { ReactNotifications } from '../../../components';
import { saveAs } from 'file-saver';

ChartJS.register(
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
    LineController,
    BarController,
);

const ChartContainer = observer((props) => {

    //other
    const { title = '', Children = null, enableSwitchTable = false, isNavigateDetail = false, path = '' } = props;
    const currentYear = moment(new Date()).year();
    const yearOptions = (function (currentYear) {
        let years = [];
        for (let year = currentYear; year >= 1970; year--) {
            years.push(year);
        }
        return years;
    })(currentYear);
    const navigate = useNavigate();

    //store
    const { dashboardStore: { getFileExport } } = useStore();

    //state
    const [switchTable, setSwitchTable] = useState(false)
    const [year, setYear] = useState(currentYear);

    //function
    const handleClickViewDetails = () => {
        navigate(path);
    }
    const handleExportCSV = () => {
        const res = getFileExport({
            title,
            year,
            type: DASHBOARD_TYPE.EXPORT
        })  
    }

    return <div className='container-content chart-container-content'>
        <div className='row'>
            <div className='col-6'>
                <h5><b>{title}</b></h5>
            </div>
            <div className='col-6 d-flex align-items-center justify-content-end select-option-group'>
                <label className='mg-r-15'>年</label>
                <select value={year} onChange={(e) => setYear(e.target.value)} className='min-width-100'>
                    {yearOptions.map((e) => <option key={e} value={e}>{e}</option>)}
                </select>
                <button 
                    onClick={handleExportCSV}
                    className='btn-icon-large btn-icon-orange min-width-40 mg-l-10'>
                    <i className="fa-solid fa-download fs-label"></i>
                </button>
                {enableSwitchTable && (<button type='button' className='btn-icon-large btn-icon-orange min-width-40 mg-l-10 btn-switch-table'
                    onClick={() => setSwitchTable(!switchTable)}>
                    {!switchTable ? <i className="fa-solid fa-table fs-label" /> : <i className="fa-solid fa-chart-line fs-label" />}</button>)}
            </div>
            <div className='mg-t-10'>※ 注意: グラフ上のデータが現時点まで計算されています。</div>
            <div className='mg-t-20'>{Children && <Children year={year} isOnTable={switchTable} />}</div>

            {isNavigateDetail && <div className='text-center mg-t-30 d-flex justify-content-end align-items-center'>
                <button className='btn btn-bg-cs-1 text-white width-150' onClick={handleClickViewDetails}>
                    <span className='mg-l-10'>詳細を見る</span>
                    <i className="fa-solid fa-angles-right"></i>
                </button>
            </div>}

        </div>
    </div>
})

export default ChartContainer;