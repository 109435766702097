/* eslint-disable max-len */
import { useEffect, useState } from 'react';
import { FORMAT_DATE, FORMAT_DATE_TIME, CONTRACT_STATUS, TEXT, MSG, ROLE, REDUND_STATUS, CONTRACT_HISTORY_TYPE } from '../../core/configs/constants';
import { useStore } from '../../core/utils/hook';
import { useForm } from 'react-hook-form';
import moment from 'moment';
import { observer } from 'mobx-react';
import {FormatNumber, Table} from '../../components';
import { ReactNotifications } from '../../components';
import { Link, useParams } from 'react-router-dom';

const ContractDetailScreen = observer((props) => {
    //other
    const { id } = useParams();
    //store
    const {
        subscriptionContractStore: { getContract, updateContract, setAttrObservable, contract, histories, historyPaging },
        modalStore: {hide, hideAll}
    } = useStore()
    //state
    const { register, handleSubmit, getValues, reset } = useForm();
    //lifecycle
    useEffect(() => {
        onFetchData();
        return () => {
            setAttrObservable('contract', {});
            setAttrObservable('histories', []);
        }
    }, [])
    useEffect(() => {
        const { refundStatus } = contract;
        reset({ refundStatus });
    }, [contract])
    //modal
    //function
    const onFetchData = (tableState) => {
        setAttrObservable('historyPaging',tableState, true, false)
        getContract(id, tableState)
    }
    const onSubmitUpdateRefund = async (data) => {
        let res = await updateContract({ id, payload: { ...data } });
        if (res) {
            ReactNotifications('success', MSG['inform.success.update']);
            onFetchData();
        }
    }

    //columns
    const columns = [
        {
            Header: 'No.',
            accessor: 'id',
            disableSortBy: true,
            Cell: ({ row: { original, index }, initialState }) => {
                return <div>{initialState?.initialTableState?.pageSize * initialState?.initialTableState?.pageIndex + index + 1}</div>
            },
            width: '5%'
        },
        {
            Header: '日時',
            accessor: 'time',
            disableSortBy: true,
            Cell: ({ row: { original } }) => {
                return (
                    <div>
                        {original?.createdAt ? moment(new Date(original?.createdAt)).format(FORMAT_DATE_TIME) : ''}
                    </div>
                );
            },
            width: '15%'
        },
        {
            Header: 'アクション内容',
            accessor: 'content',
            disableSortBy: true,
            Cell: ({ row: { original } }) => {
                const activityType = Object.keys(CONTRACT_HISTORY_TYPE).filter(e => CONTRACT_HISTORY_TYPE[e].key === original?.type)?.[0] ?? null;
                return <div>
                    <span>{activityType && CONTRACT_HISTORY_TYPE[activityType]?.label}</span>
                    <>{original?.type === CONTRACT_HISTORY_TYPE.REFUND.key && <span> (<FormatNumber statusDisplay='text' value={original?.refundAmount} decimalScale={0} suffix=' 円' />)</span>}</>
                    <>{[CONTRACT_HISTORY_TYPE.CONTRACT_CREATION.key, CONTRACT_HISTORY_TYPE.MEMBERSHIP_FEE.key].includes(original?.type) && original?.transaction && <span> (<FormatNumber statusDisplay='text' value={original?.transaction?.totalPayment} decimalScale={0} suffix=' 円'/ >)</span>}</>
                    <>{original?.type === CONTRACT_HISTORY_TYPE.FORCED_TERMINATION.key && original?.reason && <span> (理由: {original?.reason})</span> }</>
                </div>
            },
            with: '10%'
        },
        {
            Header: '実行者',
            accessor: 'full_name',
            disableSortBy: true,
            Cell: ({ row: { original } }) => {
                return <div>{original?.implementer && original?.implementer.fullName ? original?.implementer?.fullName : 'ユーザー'}</div>
            },
            with: '10%'
        },
    ]
    
    return (<div className='setting-contract-screen'>
        <div className='container-title'>サブスク契約の詳細</div>
        <form onSubmit={handleSubmit(onSubmitUpdateRefund)}>
            <div className='contract-info container-content row mg-0'>
                <div className='col-6'>
                    <div className='info-item row'>
                        <p className='col-3'>契約ID</p>
                        <p className='col-8'>{contract?.code}</p>
                    </div>
                    <div className='info-item mg-t-5 row'>
                        <p className='col-3'>ユーザーID</p>
                        <div className='col-8'><Link to={`/user/${contract?.account?.user?.id}`}>{contract?.account?.user?.code}</Link></div>
                    </div>
                    <div className='info-item mg-t-5 row'>
                        <p className='col-3'>送信者</p>
                        <p className='col-8'>{contract?.account?.user?.fullName}</p>
                    </div>
                    <div className='info-item mg-t-5 row'>
                        <p className='col-3'>メールアドレス</p>
                        <p className='col-8'>{contract?.account?.user?.email}</p>
                    </div>
                    <div className='info-item mg-t-5 row'>
                        <p className='col-3'>ステータス</p>
                        <div className='col-8'>{contract?.contractStatus ? Object.keys(CONTRACT_STATUS).filter((status) => CONTRACT_STATUS[status].key === contract.contractStatus)
                            .map((contractStatusKey) => <div style={{'color': CONTRACT_STATUS[contractStatusKey]?.color}}>
                                {CONTRACT_STATUS[contractStatusKey]?.label }
                            </div>)[0] || null : ''}</div>
                    </div>
                </div>
                <div className='col-6'>
                    <div className='info-item row d-flex align-items-center justify-content-end'>
                        <p className='col-3'>作成日</p>
                        <p className='col-8'>{contract?.createdAt ? moment(new Date(contract?.createdAt)).format(FORMAT_DATE_TIME) : ''}</p>
                    </div>
                    <div className='info-item mg-t-5 row d-flex align-items-center justify-content-end'>
                        <p className='col-3'>アカウントID</p>
                        <p className='col-8'><Link to={`/user/account/${contract?.account?.id}`}>{contract?.account?.code}</Link></p>
                    </div>
                    <div className='info-item mg-t-5 row d-flex align-items-center justify-content-end'>
                        <p className='col-3'>ユーザーネーム</p>
                        <p className='col-8'>{contract?.account?.userName}</p>
                    </div>
                    <div className='info-item mg-t-5 row d-flex align-items-center justify-content-end'>
                        <p className='col-3'>電話番号</p>
                        <p className='col-8'>{contract?.account?.user?.phoneNumber}</p>
                    </div>
                    <div className='info-item mg-t-5 row d-flex align-items-center justify-content-end'>
                        <p className='col-3'>返金額</p>
                        <p className='col-8'><FormatNumber statusDisplay='text' value={contract?.refundAmount} decimalScale={0} suffix=' 円'/ ></p>
                    </div>
                    <div className='info-item mg-t-5 row d-flex align-items-center justify-content-end'>
                        <p className='col-3'>返金ステータス</p>
                        {contract?.refundStatus != null ? <select {...register('refundStatus')} className='col-8'>
                            {
                                Object.keys(REDUND_STATUS).map(e =>
                                    REDUND_STATUS[e].key !== REDUND_STATUS.NONE.key &&
                                    <option key={REDUND_STATUS[e].key} value={REDUND_STATUS[e].key} >{REDUND_STATUS[e].label}</option>)
                            }
                        </select> : <p className='col-8'></p>}
                    </div>
                </div>
            </div>
            <div className='contract-list-activities container-content mg-t-40'>
                <h5 className='fw-bold'>関連アクション</h5>
                <div className='table-activites'>
                    <Table
                        columns={columns}
                        data={histories}
                        disableSelectionPageSize={true}
                        disablepaging={false}
                        enableServerSidePaging={true}
                        initialTableState={historyPaging}
                        onFetch={onFetchData}
                        className='lst-activity-table'
                    />
                </div>
            </div>
            {contract && contract.refundStatus != null && <div className='d-flex justify-content-center align-items-center mg-t-20'>
                <button type='submit' onClick={hide} className='btn-default btn-bg-cs-1 mg-l-50'>
                保存
                </button>
            </div>}
        </form>
    </div>)
})

export default ContractDetailScreen;