import moment from 'moment';
import { useStore } from '../../../core/utils/hook';
import { useEffect, useState } from 'react';
import { Table } from '../../../components';
import { FORMAT_DATE_TIME, MSG } from '../../../core/configs/constants';

const ExportMemberlstHistoryModal = () => {

    // state
    const [historiesData, setHistoriesData] = useState(null);

    // store
    const {
        modalStore: { hide },
        userStore: { getExportMemberHistories }
    } = useStore();

    // life cycle
    useEffect(() => {
        getData();
    }, [])

    // function
    const getData = async() => {
        const res = await getExportMemberHistories();
        if (res) {
            setHistoriesData(res)
        }
    }

    // column
    const ExportMemberlstHistoryColumns = [
        {
            Header: 'No.',
            accessor: 'no',
            disableSortBy: true,
            Cell: ({ row: { original, index } }) => {
                return <div>{ index + 1}</div>
            },
            width: '10%'
        },
        {
            Header: 'ファイル名',
            accessor: 'file_name',
            disableSortBy: true,
            Cell: ({ row: { original } }) => {
                return <div>{original?.fileName}</div>;
            },
            width: '50%'
        },
        {
            Header: '出力日時',
            accessor: 'create_at',
            disableSortBy: false,
            Cell: ({ row: { original } }) => {
                return <div>{moment(original?.createdAt).format(FORMAT_DATE_TIME)}</div>;
            },
            width: '25%'
        },
        {
            Header: 'アクション',
            accessor: 'action',
            disableSortBy: true,
            Cell: ({ row: { original } }) => {
                return (
                    <>
                        {
                            original?.fileName ? (
                                <a href={original?.url} className='btn-icon btn-icon-orange'>
                                    <i className="fa-solid fa-download"></i>
                                </a>
                            ) : (
                                <div className='text-danger fs-error'>会員一覧のエクスポートに失敗しました。</div>
                            )
                        }
                    </>
                );
            },
            width: '15%'
        }
    ];
    
    return (
        <div className='export-history-modal pd-20 overflow-scroll d-flex flex-column justify-content-between' style={{maxHeight: '600px'}} >
            <div className='pd-b-30'>
                <div className="accordion-item mt-4 width-800">
                    {
                        historiesData?.length > 0 ? (
                            <Table
                                disableSelectionPageSize={true}
                                disablePaging={true}
                                enableServerSidePaging={false}
                                columns={ExportMemberlstHistoryColumns}
                                data={historiesData || []}
                                onFetch={() => getData()}
                                rowKey="id"
                            />
                        ) : ( <div className='text-center'>{MSG['inform.no_data']}</div> )
                    }
                </div>
            </div>

            <div className="text-center mg-t-20">
                <button 
                    type={'button'} 
                    className={'btn-default btn-default btn-bg-cs-medium-gray-opacity width-150'}
                    onClick={()=> hide()}>
                    <span className='fw-bold text-color-cs-5'>閉じる</span>
                </button>
            </div>
        </div>
    )
}

export default ExportMemberlstHistoryModal;