import { flow, makeObservable, observable } from 'mobx';
import { UserApi } from '../apis';
import BaseStore from './BaseStore';
import AccountApi from '../apis/AccountApi';

class UserStore extends BaseStore {

    adminList = [];
    memberList = [];

    userList = [];
    userOptions = [];
    user = {};

    listCardBuyOfUser = {}

    adminPaging = this.defaultPaging;
    memberPaging = this.defaultPaging;

    constructor(rootStore) {
        super();
        makeObservable(this, {
            adminList: observable,
            adminPaging: observable,
            memberList: observable,
            memberPaging: observable,
            userList: observable,
            userOptions: observable,
            user: observable,
            listCardBuyOfUser: observable,
            getAllUser: flow.bound,
            getUserOptions: flow.bound,
            getUser: flow.bound,
            createUser: flow.bound,
            updateUser: flow.bound,
            updateStatusUser: flow.bound,
            deleteUser: flow.bound,
            listCardbuy: flow.bound,
            exportUser: flow.bound,
            getExportMemberHistories: flow.bound
        })
        this.rootStore = rootStore;
        this.api = new UserApi();
        this.accountApi = new AccountApi();
    }

    *getAllUser(searchParams, cb) {
        try {
            const { size, page, sortDir, sortKey } = this.paging;
            const payload = { size, page, sortDir, sortKey, ...searchParams };
            const res = yield this.rootStore.apiStore.call(this.api, this.api.getAllUser, payload);
            if (res?.ok) {
                if (cb) {
                    cb(res);
                } else {
                    this.userList = res?.data?.elements || [];
                    this.setAttrObservable('paging', res?.data?.paginate, true, false);
                }
            }
        } catch (error) {
            console.log(error);
        }
    }

    *getUserOptions(searchParams) {
        try {
            const res = yield this.rootStore.apiStore.call(this.api, this.api.getAllUser, searchParams);
            if (res?.ok) {
                this.userOptions = res?.data?.elements || [];
            }
        } catch (error) {
            console.log(error);
        }
    }

    *getUser(id, payload) {
        try {
            const res = yield this.rootStore.apiStore.call(this.api, this.api.getUser, { id, payload });
            if (res?.ok) {
                this.user = res?.data ?? {};
            }
        } catch (error) {
            console.log(error);
        }
    }

    *createUser(payload) {
        try {
            const res = yield this.rootStore.apiStore.call(this.api, this.api.createUser, payload);
            if (res?.ok) {
                return true;
            }
            return false;
        } catch (error) {
            console.log(error);
        }
    }

    *updateUser(id, payload) {
        try {
            const res = yield this.rootStore.apiStore.call(this.api, this.api.updateUser, { id, payload });
            if (res?.ok) {
                return true;
            }
            return false;
        } catch (error) {
            console.log(error);
        }
    }

    *updateStatusUser(id, payload) {
        try {
            const res = yield this.rootStore.apiStore.call(this.api, this.api.updateStatusUser, { id, payload });
            if (res?.ok) {
                return true;
            }
            return false;
        } catch (error) {
            console.log(error);
        }
    }

    *deleteUser(id, payload) {
        try {
            const res = yield this.rootStore.apiStore.call(this.api, this.api.deleteUser, { id, payload });
            if (res?.ok) {
                return true;
            }
            return false;
        } catch (error) {
            console.log(error);
        }
    }

    *listCardbuy(id, searchParams) {
        try {
            const { size, page } = this.paging;
            const payload = { size, page, ...searchParams };
            const res = yield this.rootStore.apiStore.call(this.api, this.api.listCardbuy, { id, payload });
            if (res?.ok) {
                this.listCardBuyOfUser = res?.data ?? {};
                this.setAttrObservable('paging', res?.data?.cards?.paginate, true, false);
            }
        } catch (error) {
            console.log(error);
        }
    }

    *exportUser(payload) {
        try {
            const res = yield this.rootStore.apiStore.call(this.api, this.api.exportUser, payload);
            if (res?.ok) {
                return true;
            }
            return false;
        } catch (error) {
            console.log(error);
        }
    }
    
    *getExportMemberHistories(payload) {
        try {
            const res = yield this.rootStore.apiStore.call(this.api, this.api.getExportMemberHistories, payload);
            if (res?.ok) {
                return res?.data;
            }
            return false;
        } catch (error) {
            console.log(error);
        }
    }

    clean() {
        super.clean();
        this.adminList = [];
        this.memberList = [];
        this.userList = [];
        this.adminPaging = this.defaultPaging;
        this.memberPaging = this.defaultPaging;
    }
}

export default UserStore;