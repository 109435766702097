import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { ReactNotifications, Table } from '../../components';
import { FORMAT_DATE, FORMAT_DATE_TIME, INQUIRY_STATUS, MSG, TEXT, TOOLTIP } from '../../core/configs/constants';
import { useStore } from '../../core/utils/hook';
import { useForm } from 'react-hook-form';
import moment from 'moment';
import InquiryDetail from './InquiryDetail';

const InquiryManagement = observer((props) => {
    //other
    //store
    const {
        inquiryStore: { inquiryList, getAllInquiry, setAttrObservable, deleteInquiry, clean, paging },
        modalStore: { show, hide, openWarningModal }
    } = useStore()

    //state
    const { register, handleSubmit, getValues } = useForm();

    //life cycle
    useEffect(() => {
        onSearch();

        return () => {
            clean();
        } ;
    }, [])

    //modal
    const onShowInquiryContent = (id) => {
        const dataSearch = getValues();
        show({
            id: 'modal-view-inquiry',
            isOpen: true,
            header: 'お問い合わせ詳細',
            onCancel: () => hide(),
            onRequestClose: () => hide(),
            children: (
                <InquiryDetail id={id} dataSearch={dataSearch} />
            ),
            type: 'large'
        })
    }
    const onShowConfirmDeleteInquiry = (id) => {
        openWarningModal('このお問い合わせを削除しますが、よろしいですか?', () => onSubmitDelete(id))
    }
    //function
    const onSearch = (data) => {
        clean();
        onFetchData(data)
    }
    
    const onFetchData = (tableState) => {
        setAttrObservable('paging', tableState, true, false);
        getAllInquiry({
            ...getValues(),
            ...tableState
        });
    }
    const onSubmitDelete = async (id) => {
        let res = await deleteInquiry(id);
        if (res) {
            ReactNotifications('success', MSG['inform.success.delete']);
            onFetchData();
            hide();
        }
        
    }
    //columns
    const columns = [
        {
            Header: 'No.',
            accessor: 'id',
            disableSortBy: true,
            Cell: ({ row: { original, index }, initialState }) => {
                return <div>{initialState?.initialTableState?.pageSize * initialState?.initialTableState?.pageIndex + index + 1}</div>
            },
            width: '5%'
        },
        {
            Header: '送信者',
            accessor: 'full_name',
            disableSortBy: true,
            Cell: ({ row: { original } }) => {
                return <div>{original?.fullName}</div>
            },
            with: '10%'
        },
        {
            Header: 'メールアドレス',
            accessor: 'email',
            disableSortBy: true,
            Cell: ({ row: { original } }) => {
                return <div>{original?.email}</div>
            },
            with: '10%'
        },
        {
            Header: '電話番号',
            accessor: 'phone_number',
            disableSortBy: true,
            Cell: ({ row: { original } }) => {
                return (
                    <div>
                        {original?.phoneNumber}
                    </div>
                );
            },
            width: '10%'
        },
        {
            Header: '送信日',
            accessor: 'created_at',
            disableSortBy: true,
            Cell: ({ row: { original } }) => {
                return (
                    <div>
                        {original?.createdAt ? moment(new Date(original?.createdAt)).format(FORMAT_DATE_TIME) : ''}
                    </div>
                );
            },
            width: '15%'
        },
        {
            Header: '支払いステータス',
            accessor: 'status',
            disableSortBy: true,
            Cell: ({ row: { original } }) => {
                const findStatus = Object.keys(INQUIRY_STATUS).filter(key => INQUIRY_STATUS[key].key === original?.status)?.[0];
                return (
                    findStatus && <div className={INQUIRY_STATUS[findStatus]?.colorClassName}>
                        {INQUIRY_STATUS[findStatus]?.label}
                    </div>
                );
            },
            width: '10%'
        },
        {
            Header: '初回更新',
            accessor: 'firstUpdateDate',
            disableSortBy: true,
            Cell: ({ row: { original } }) => {
                return (
                    <div>
                        {original?.firstUpdateDate ? moment(new Date(original?.firstUpdateDate)).format(FORMAT_DATE_TIME) : ''}
                    </div>
                );
            },
            width: '15%'
        },
        {
            Header: '初回更新者',
            accessor: 'firstUpdateBy',
            disableSortBy: true,
            Cell: ({ row: { original } }) => {
                return <div>{original?.firstUpdateBy ? original.firstUpdateBy : ''}</div>
            },
            with: '10%'
        },
        {
            Header: 'アクション',
            accessor: '',
            disableSortBy: true,
            Cell: ({row: {original}}) => {
                return (
                    <div className='d-flex align-items-center flex-gap-10 justify-content-center flex-wrap'>
                        <button type='button'
                            data-bs-toggle='tooltip'
                            title={TOOLTIP.VIEW_INQUIRY}
                            className='btn-icon btn-icon-orange'
                            
                            onClick={() => onShowInquiryContent(original?.id)}
                        ><i className='fa-solid fa-pen-to-square'></i></button>
                        <button type='button'
                            data-bs-toggle='tooltip'
                            title={TOOLTIP.DELETE_INQUIRY}
                            className='btn-icon btn-icon-red'
                            onClick={() => onShowConfirmDeleteInquiry(original?.id)}
                        ><i className="fa-solid fa-trash-can"></i></button>
                    </div>
                )
            },
            width: '10%'
        }
    ];

    return <div className='inquiry-screen'>
        <div className='container-title'>お問い合わせ管理</div>
        <div className='container-search'>
            <form onSubmit={handleSubmit(onSearch)}>
                <div className='row'>
                    <div className='d-flex col-6 align-items-center'>
                        <label className='col-3'>送信者</label>
                        <input {...register('fullName')} className='col-8' />
                    </div>
                    <div className='d-flex col-6 align-items-center justify-content-end'>
                        <label className='col-3'>メールアドレス</label>
                        <input {...register('email')} className='col-8' />
                    </div>
                </div>
                <div className='row mg-t-10'>
                    <div className='d-flex col-6 align-items-center'>
                        <label className='col-3'>ステータス</label>
                        <select 
                            {...register('status')} 
                            className='col-8'>
                            <option value={''}>すべて</option>
                            {Object.keys(INQUIRY_STATUS).map((key) => {
                                const status = INQUIRY_STATUS[key];
                                return (
                                    <option key={status.key} value={status.key}>
                                        {status.label}
                                    </option>
                                );
                            })}
                        </select>
                    </div>
                </div>
                <div className='text-center mg-t-35'>
                    <button type='submit' className='btn-default btn-search-back'>
                        <i className='fa-solid fa-magnifying-glass'></i>
                        <span className='mg-l-10'>{TEXT.SEARCH}</span>
                    </button>
                </div>
            </form>
        </div>
        <div className='container-content'>
            <Table
                columns={columns}
                data={inquiryList}
                disablepaging={false}
                enableServerSidePaging={true}
                initialTableState={paging}
                onFetch={onFetchData}
                className='lst-inquiry-table'
            />
        </div>
    </div>
})

export default InquiryManagement;