/* eslint-disable max-len */
import { Bar } from 'react-chartjs-2';
import { CHART_LABELS_DEFAULT, DASHBOARD_TYPE } from '../../../../core/configs/constants';
import { useNavigate } from 'react-router-dom';
import { useStore } from '../../../../core/utils/hook';
import { useEffect } from 'react';
import { observer } from 'mobx-react';


const ChartPaidMember = observer(({year}) => {

    //other
    const navigate = useNavigate();
    //store
    const { dashboardStore: { monthlyFeeAccountList, getDashboardMonthlyFeeOfAccount } } = useStore();
    //state
    //life cycle
    useEffect(() => {
        getDashboardData(year);

        return 
    }, [year])
    //function
    const getDashboardData = async (year) => {
        await getDashboardMonthlyFeeOfAccount({type: DASHBOARD_TYPE.OVERVIEW, year})
    }
    const handleBarClick = (event, elements) => {
        if (elements.length === 0) {
            // No bars were clicked
            return;
        }    
        const firstElement = elements[0];
        // const datasetIndex = firstElement.datasetIndex;
        const index = firstElement.index;
        // const chosenData = data.datasets[datasetIndex].data[index];
        navigate(`/dashboard/paid-member/${index+1}-${year}`)
    };

    const handleBarHover = (event, chartElement) => {
        event.native.target.style.cursor = chartElement[0] ? 'pointer' : 'default';
    }

    //option for chart
    const data = {
        labels: CHART_LABELS_DEFAULT,
        datasets: [
            {
                label: '会費',
                data: monthlyFeeAccountList && monthlyFeeAccountList.membership && monthlyFeeAccountList.membership.length ? monthlyFeeAccountList.membership.map((monthlyFee) => monthlyFee.value) : [],
                borderColor: '#d0a627',
                backgroundColor: '#d0a627'
            },
            {
                label: 'Holder追加',
                data: monthlyFeeAccountList && monthlyFeeAccountList.holder && monthlyFeeAccountList.holder.length ? monthlyFeeAccountList.holder.map((monthlyFee) => monthlyFee.value) : [],
                borderColor: '#429e4b',
                backgroundColor: '#429e4b'
            }
        ]
    }
    const options = {
        onClick: handleBarClick,
        onHover: handleBarHover,
        responsive: true,
        maintainAspectRatio: true,
        plugins: {
            legend: {
                position: 'bottom',
            },
            title: {
                display: false,                
            },
            tooltip: {
                callbacks: {
                    label: (context) => {
                        let label = '';
                        if (context.parsed.y) {
                            label = context.dataset.label + ': ' + context.parsed.y + ' 名'
                        }
                        return label;
                       
                    }
                }
            },
        },
    };

    return <div className='d-flex justify-content-center align-items-center w-full height-500'>
        <Bar options={options} data={data}/>  
    </div> 
})

export default ChartPaidMember;