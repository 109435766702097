import { observer } from 'mobx-react';
import ChartContainer from './components/ChartContainer';
import { DASHBOARD_CHART_TITTLE, SIDEBAR_TITLE, SYSTEM_PATH } from '../../core/configs/constants';
import NewUsersByMonthStatistical from './components/Chart/NewUsersByMonthStatistical';
import CardByMonthStatistical from './components/Chart/CardByMonthStatistical';
import ChartPaidMember from './components/Chart/ChartPaidMember';
import StastifyRevenue from './components/Chart/StastifyRevenue';

const DashBoardScreen = observer(props => {
    return (
        <div className='dashboard-screen'>
            <div className='container-title'>{SIDEBAR_TITLE.DASHBOARD}</div>
            <div className='chart-new-user'>
                <ChartContainer title={DASHBOARD_CHART_TITTLE.NEW_USER} Children = {NewUsersByMonthStatistical}
                    path = {SYSTEM_PATH.DASHBOARD_DETAILS_NEW_USER} />
            </div>
            <div className='chart-card-statistical mg-t-20'>
                <ChartContainer title={DASHBOARD_CHART_TITTLE.CARD} Children = {CardByMonthStatistical}
                    path = {SYSTEM_PATH.DASHBOARD_DETAILS_CARD} />
            </div>
            <div className='chart-paid-members mg-t-20'>
                <ChartContainer title={DASHBOARD_CHART_TITTLE.PAID_MEMBER} Children = {ChartPaidMember}
                    path = {SYSTEM_PATH.DASHBOARD_DETAILS_PAID_MEMBER} />
            </div>
            <div className='chart-revenue mg-t-20'>
                <ChartContainer title={DASHBOARD_CHART_TITTLE.REVENUE} Children={StastifyRevenue}
                    enableSwitchTable={true} />
            </div>
        </div>
    )
})

export default DashBoardScreen;
