import { observer } from 'mobx-react';
import { ACCOUNT_STATUS, TEXT, TOOLTIP, ROLE, MSG, FORMAT_DATE, FORMAT_DATE_TIME } from '../../core/configs/constants';
import { useStore } from '../../core/utils/hook';
import { useForm } from 'react-hook-form';
import { Table, ReactNotifications } from '../../components';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';

const AccountListScreen = observer(() => {
    //other
    const navigate = useNavigate();
    const { id } = useParams();
    //store
    const { accountStore: { clean,user,  paging, getAllAccount, accountList, setAttrObservable, deleteAccount },
        modalStore: { hide, show, openWarningModal }, authStore: {userInfo} } = useStore()
    //state
    const { register, getValues, watch } = useForm({
        defaultValues: {
            status: '',
        }
    });
    const watchStatus = watch('status');
    //life cycle
    useEffect(() => {
        onSearch();

        return () => {
            clean();
        }
    }, [watchStatus])
    
    //modal
    const onShowConfirmDeleteAccount = (id) => {
        openWarningModal(MSG['modal.confirm_delete_account'], () => onSubmitDelete(id));
    }
    //function
    
    const onSearch = (data) => {
        clean();
        onFetchData(data);
    }
    const onFetchData = (tableData) => {
        setAttrObservable('paging', tableData, true, false);
        getAllAccount({
            ...getValues(),
            ...tableData,
            userId: id,
        }, (res) => {
            setAttrObservable('accountList', res?.data?.accounts?.elements);
            setAttrObservable('user', res?.data?.user);
            setAttrObservable('paging', res?.data?.accounts?.paginate, true, false);
        })
    }
    const onSubmitDelete = async (id) => {
        let res = await deleteAccount(id);
        if (res) {
            ReactNotifications('success', MSG['inform.success.delete']);
            onSearch();
            hide();
        }
    }
    //columns
    const columns = [
        {
            Header: 'アカウントID',
            accessor: 'code',
            disableSortBy: true,
            Cell: ({ row: { original } }) => {
                return (
                    <div>
                        {original?.code}
                    </div>
                );
            },
            width: '10%'
        },
        {
            Header: 'ユーザーネーム',
            accessor: 'user_name',
            disableSortBy: true,
            Cell: ({ row: { original } }) => {
                return (
                    <div>
                        {original?.userName}
                    </div>
                );
            },
            width: '10%'
        },
        {
            Header: '保有中のHolder数',
            accessor: 'number_holder',
            disableSortBy: true,
            Cell: ({ row: { original } }) => {
                return (
                    <div>
                        {original?.numberHolders}
                    </div>
                );
            },
            width: '10%'
        },
        {
            Header: '保有中のカード数',
            accessor: 'number_holder_card',
            disableSortBy: true,
            Cell: ({ row: { original } }) => {
                return (
                    <div>
                        {original?.numberPurchasedCard}
                    </div>
                );
            },
            width: '10%'
        },
        {
            Header: '作成日',
            accessor: 'created_at',
            disableSortBy: true,
            Cell: ({ row: { original } }) => {
                return (
                    <div>
                        {original?.createdAt ? moment(new Date(original.createdAt)).format(FORMAT_DATE_TIME) : ''}
                    </div>
                );
            },
            width: '10%'
        },
        {
            Header: 'ステータス',
            accessor: 'status',
            disableSortBy: true,
            Cell: ({ row: { original } }) => {
                const statusKey = Object.keys(ACCOUNT_STATUS).filter(e => ACCOUNT_STATUS[e].key === original?.status)?.[0] ?? null;

                return (
                    <div style={{'color': ACCOUNT_STATUS[statusKey]?.color}}>
                        { statusKey && ACCOUNT_STATUS[statusKey]?.label }
                    </div>
                );
            },
            width: '10%'
        },
        {
            Header: 'アクション',
            accessor: '',
            disableSortBy: true,
            Cell: ({ row: { original } }) => {
                return (
                    <div className='d-flex align-items-center flex-gap-10 justify-content-center flex-wrap'>
                        <button
                            data-bs-toggle="tooltip"
                            title={TOOLTIP.EDIT_ACCOUNT}
                            type='button'
                            className='btn-icon btn-icon-orange' onClick={() => {navigate(`/user/account/${original?.id}`)}}>
                            <i className='fa-solid fa-pen-to-square'></i>
                        </button>
                        {original?.status !== ACCOUNT_STATUS.SUBSCRIBED.key && userInfo?.role !== ROLE.STAFF.key ? <button
                            data-bs-toggle="tooltip"
                            title={TOOLTIP.DELETE_ACCOUNT}
                            type='button' className='btn-icon btn-icon-red'
                            onClick={() => onShowConfirmDeleteAccount(original?.id)}>
                            <i className='fa-solid fa-trash-can'></i>
                        </button> : <button className='btn-icon invisible'></button>}
                    </div>
                );
            },
            width: '10%'
        }
    ]
    return <div className='account-list-screen pd-16'>
        <div className='container-title'>#{user?.code || 'USERCODE'} アカウント一覧</div>
        <div className='user-infor container-content'>
            <div className='row'>
                <div className='col-6'>
                    <div className='info-item row'>
                        <span className='col-3 font-bold'>ユーザーID</span>
                        <span className='col-8'>{user?.code}</span>
                    </div>
                    <div className='info-item mg-t-15 row'>
                        <span className='col-3 font-bold'>メールアドレス</span>
                        <span className='col-8'>{user?.email}</span>
                        
                    </div>
                </div>
                <div className='col-6 '>
                    <div className='info-item row'>
                        <span className='col-3 font-bold'>名前-本名</span>
                        <span className='col-8'>{user?.fullName}</span>
                    </div>
                </div>
            </div>
        </div>
        <div className='user-list container-content mg-t-10'>
            <h6 className='mg-0 font-bold'>アカウント一覧</h6>
            <hr className='mg-b-10 mg-t-5' />
            <div className='user-list-content d-flex flex-column'>
                <div className='mg-b-15'>
                    <form className='float-end'>
                        <div className='d-flex align-items-center'>
                            <label className=''>ステータス</label>
                            <select {...register('status')} className='mg-l-10'>
                                <option value={''}>すべて</option>
                                {
                                    Object.keys(ACCOUNT_STATUS).map(e => 
                                        <option key={ACCOUNT_STATUS[e].key} value={ACCOUNT_STATUS[e].key}>{ACCOUNT_STATUS[e].label}</option>
                                    )
                                }
                            </select>
                        </div>
                    </form>
                </div>
                <Table columns={columns}
                    data={accountList}
                    disableSelectionPageSize={true}
                    disablepaging={false}
                    enableServerSidePaging={true}
                    initialTableState={paging}
                    onFetch={onFetchData}
                    className='lst-account-table' />
            </div>
        </div>
    </div>
})

export default AccountListScreen;