import { Link, useNavigate, useParams } from 'react-router-dom';
import { DETAIL_PAYMENT_HEADER, FORMAT_DATE_TIME, PURCHASE_STATUS, SYSTEM_PATH, TRANSACTION_FORM_MAPPER } from '../../core/configs/constants';
import { useStore } from '../../core/utils/hook';
import { useEffect, useMemo } from 'react';
import moment from 'moment';
import classNames from 'classnames';
import { observer } from 'mobx-react';
import { FormatNumber } from '../../components';

const BuyHolderDetailScreen = observer(() => {
    // other
    const navigate = useNavigate();
    const { id } = useParams();

    // store
    const { paymentStore: { transactionDetail, getDetailsTransaction }} = useStore();

    // state
    // eslint-disable-next-line max-len
    const findStatus = useMemo(() => Object.keys(PURCHASE_STATUS).filter(key => PURCHASE_STATUS[key].key === transactionDetail?.status)?.[0], [transactionDetail]);

    // life cycle
    useEffect(() => {
        onFetchData();
    }, [id])

    // function
    const onFetchData = async () => {
        if (id) {
            await getDetailsTransaction(id);
        }
    }

    return (
        <div>
            <div className='container-title'>{DETAIL_PAYMENT_HEADER}</div>
            <div className='container-content pd-0'>
                <div className='btn-bg-cs-1 pd-10 text-color-cs-white font-bold fs-heading-small rounded-top'>ユーザー情報</div>
                <div className='pd-30 text-color-cs-5'>
                    <div className='row'>
                        <div className='row'>
                            <div className='row col-6 align-items-center'>
                                <span className='col-3 font-label fw-semibold'>ユーザーID</span>
                                <span className='col-9'>
                                    <Link
                                        to={`/user/${transactionDetail?.account?.user?.id}`} 
                                        className='text-color-cs-4 cursor-pointer'
                                    ><u>{transactionDetail?.account?.user?.code}</u></Link>
                                </span>
                            </div>
                            <div className='row col-6 align-items-center justify-content-end'>
                                <span className='col-3-5 fw-semibold'>名前-本名</span>
                                <span className='col-6'>{transactionDetail?.account?.user?.fullName}</span>
                            </div>
                        </div>
                        <div className='row mg-t-16'>
                            <div className='row col-6 align-items-center'>
                                <span className='col-3 fw-semibold'>メールアドレス</span>
                                <span className='col-9'>{transactionDetail?.account?.user?.email}</span>
                            </div>
                            <div className='row col-6 align-items-center justify-content-end'>
                                <span className='col-3-5 fw-semibold'>電話番号</span>
                                <span className='col-6'>{transactionDetail?.account?.user?.phoneNumber}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='container-content pd-0 mg-t-30'>
                <div className='btn-bg-cs-1 pd-10 text-color-cs-white font-bold fs-heading-small rounded-top'>取引情報</div>
                <div className='pd-30 text-color-cs-5'>
                    <div className='row'>
                        <div className='row'>
                            <div className='row col-6 align-items-center'>
                                <span className='col-3 font-label fw-semibold'>取引ID</span>
                                <span className='col-9'>
                                    <span className='font-bold fs-label'>{transactionDetail?.code}</span>
                                </span>
                            </div>
                        </div>
                        <div className='row mg-t-16'>
                            <div className='row col-6 align-items-center'>
                                <span className='col-3 fw-semibold'>作成日</span>
                                <span className='col-9'>{moment(transactionDetail?.createdAt).format(FORMAT_DATE_TIME)}</span>
                            </div>
                            <div className='row col-6 align-items-center justify-content-end'>
                                <span className='col-3-5 fw-semibold'>分類</span>
                                <span className='col-6'>
                                    {transactionDetail?.transactionForm ? 
                                        TRANSACTION_FORM_MAPPER[transactionDetail?.transactionForm].text : ''}</span>
                            </div>
                        </div>
                        <div className='row mg-t-16'>
                            <div className='row col-6 align-items-center'>
                                <span className='col-3 fw-semibold'>支払日</span>
                                <span className='col-9'>
                                    {transactionDetail?.status ===  PURCHASE_STATUS.SUCCESS.key? (
                                        <div>{moment(transactionDetail?.transactionDate).format(FORMAT_DATE_TIME)}</div>
                                    ): (
                                        <div></div>
                                    )}
                                </span>
                            </div>
                            <div className='row col-6 align-items-center justify-content-end'>
                                <span className='col-3-5 fw-semibold'>Holder総枠数</span>
                                <span className='col-6'>{transactionDetail?.numberHolder}</span>
                            </div>
                        </div>
                        <div className='row mg-t-16'>
                            <div className='row col-6 align-items-center'>
                                <span className='col-3 fw-semibold'>支払いステータス</span>
                                {
                                    findStatus && <span className={classNames('col-9 font-bold', PURCHASE_STATUS[findStatus]?.colorClassName ?? '')}>
                                        {PURCHASE_STATUS[findStatus]?.label}
                                    </span>
                                }
                            </div>
                            <div className='row col-6 align-items-center justify-content-end'>
                                <span className='col-3-5 fw-semibold'>支払総額</span>
                                <FormatNumber className='col-6 text-color-cs-red font-bold' value={transactionDetail?.totalPayment} suffix=' 円' />
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className='text-color-cs-5 font-bold fs-label mg-t-30 border-bottom-think-color-cs-1'>Holder一覧</div>
                        {
                            transactionDetail?.holdersOfAccounts?.length > 0 &&
                            transactionDetail?.holdersOfAccounts.map((transaction, index) => (
                                <PurchaseHolderItem 
                                    key={index} 
                                    data={transaction} 
                                    holderFee={transactionDetail.holderFee} 
                                    transactionDetail={transactionDetail}
                                    index={index} />
                            ))
                        }
                    </div>
                    <div className='d-flex justify-content-center align-items-center mg-t-80'>
                        <button 
                            type='button' 
                            className='btn-default btn-cancel'
                            onClick={() => navigate(SYSTEM_PATH.HOLDER_PAYMENT_MANAGEMENT)}>
                            戻る
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
})

const PurchaseHolderItem = ({ data, holderFee, index, transactionDetail }) => {
    return (
        <div className={classNames('purchase-holder-item row pd-b-20 g-0', 
            index != transactionDetail?.holdersOfAccounts?.length-1 ? 'border-bottom-thin-color-cs-6' : '' )}>
            <div className='row mg-tb-16'>
                <div className='row col-6 align-items-center'>
                    <span className='col-3 fw-semibold pd-0'>アカウント</span>
                    <span className='col-9 pd-0'>{data?.account?.userName || ''}</span>
                </div>
                <div className='row col-6 align-items-center justify-content-end'>
                    <span className='col-3-5 fw-semibold'>Holder数</span>
                    <span className='col-6'>{data?.holders?.length || 0}</span>
                </div>
            </div>
            <div className='d-flex flex-wrap gap-2'>
                {
                    data?.holders?.length > 0 &&
                    data?.holders?.map((holder, index) => (
                        <PurchaseItem key={index} data={holder} index={index} holderFee={holderFee} />
                    ))
                }
            </div>
        </div>
    )
}

const PurchaseItem = ({ data, index, holderFee }) => {
    return (
        <div className='purchase-item bg-cs-lighter-container d-flex align-items-center gap-4 pd-20 border-radius-10 border' style={{width: '32%'}}>
            <div className='width-70 height-95'>
                <img src='/images/PurchaseHolderItemImg.png' alt="gold card" />
            </div>
            <div className='d-flex flex-column gap-2 w-75'>
                <div>{`Holder ${index + 1}`}</div>
                <div className='row'>
                    <div className='col-6'>利用期間</div>
                    <div className='col-6'>{`${data?.months}か月`}</div>
                </div>
                <div className='row'>
                    <div className='col-6'>金額</div>
                    <FormatNumber
                        className='col-6' 
                        statusDisplay='text' 
                        value={data.months*holderFee} 
                        suffix=' 円' />
                </div>
            </div>
        </div>
    )
}


export default BuyHolderDetailScreen;