import { flow, makeObservable, observable } from 'mobx';
import BaseStore from './BaseStore';
import AccountApi from '../apis/AccountApi';

class AccountStore extends BaseStore {

    accountList = []
    user = {};
    accountOptions = [];
    account = {};
    listCardOfMember = {}


    constructor(rootStore) {
        super();
        makeObservable(this, {
            accountList: observable,
            user: observable,
            accountOptions: observable,
            account: observable,
            listCardOfMember: observable,
            getAllAccount: flow.bound,
            getAccount: flow.bound,
            addAccount: flow.bound,
            updateAccount: flow.bound,
            deleteAccount: flow.bound,
            getlistCardOfMember: flow.bound
        })
        this.rootStore = rootStore;
        this.api = new AccountApi();
    }

    *getAllAccount(searchParams, cb) {
        try {
            const { size, page, sortDir, sortKey } = this.paging;
            const payload = { size, page, sortDir, sortKey, ...searchParams };
            const res = yield this.rootStore.apiStore.call(this.api, this.api.getAllAccount, payload);
            if (res?.ok) {
                if (cb) {
                    cb(res);
                } else {
                    this.accountList = res?.data?.accounts?.elements || res?.data?.elements || [];
                    this.user = res?.data?.user || {};
                    this.setAttrObservable('paging', res?.data?.accounts?.paginate || res?.data?.paginate || [], true, false);
                }
            }
        } catch (error) {
            console.log(error);
        }
    }

    *getAccount(id, payload) {
        try {
            const res = yield this.rootStore.apiStore.call(this.api, this.api.getAccount, { id, payload });
            if (res?.ok) {
                this.account = res?.data ?? {};
            }
        } catch (error) {
            console.log(error);
        }
    }


    *updateAccount(id, payload) {
        try {
            const res = yield this.rootStore.apiStore.call(this.api, this.api.updateAccount, { id, payload });
            if (res?.ok) {
                return true;
            }
            return false;
        } catch (error) {
            console.log(error);
        }
    }


    *deleteAccount(id, payload) {
        try {
            const res = yield this.rootStore.apiStore.call(this.api, this.api.deleteAccount, { id, payload });
            if (res?.ok) {
                return true;
            }
            return false;
        } catch (error) {
            console.log(error);
        }
    }

    *addAccount(payload) {
        try {
            const res = yield this.rootStore.apiStore.call(this.api, this.api.addAccount, payload);
            if (res?.ok) {
                return true;
            }
            return false;
        } catch (error) {
            console.log(error);
        }
    }

    *getlistCardOfMember(id, payload) {
        try {
            const res = yield this.rootStore.apiStore.call(this.api, this.api.cardListOfMember, { id, payload });
            if (res?.ok) {
                this.listCardOfMember = res?.data ?? {};
            }
        } catch (error) {
            console.log(error);
        }
    }

    clean() {
        super.clean();
        this.accountList = [];
        this.account = {};
        this.user = {};
    }
}

export default AccountStore;