import ApiService from './ApiService';

class GeneralSettingApi extends ApiService {
    constructor() {
        super('general-setting');
    }
    getGeneralSetting(type) {
        return this.get(type);
    }

    setGeneralSetting({type, payload}) {
        return this.put(type, payload);
    }

    getOtherSettings() {
        return this.get('other_setting');
    }

    setOtherSettings(payload) {
        return this.put('other_setting', payload);
    }
}

export default GeneralSettingApi;