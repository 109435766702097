import ApiService from './ApiService';

class PaymentApi extends ApiService {
    constructor() {
        super('transaction');
    }
    getAllTransaction(payload) {
        return this.get('', payload);
    }

    getDetailsTransaction(id) {
        return this.get(id);
    }

    confirmChangePaymentStatus(id) {
        return this.put(`accept/${id}`);
    }

    updateAIRDropStatus({id, payload}) {
        return this.put(`airdrop-status/${id}`, payload);
    }

    cancelPurchase(id) {
        return this.put(`cancel/${id}`);
    }

    exportTransactionCsv(payload) {
        return this.post('export-csv', payload, null, 'blob');
    }
}

export default PaymentApi;