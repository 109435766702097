import { observer } from 'mobx-react';
import { useForm } from 'react-hook-form';
import { MSG, TEXT } from '../../core/configs/constants';
import { useStore } from '../../core/utils/hook';
import yup from '../../core/utils/yupValidate';
import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';
import classNames from 'classnames';
import { passwordRegex } from '../../core/utils/common';
import { ModalFooter } from '../../components';

const ChangePasswordScreen = observer(props => {

    // store
    const { authStore: { changePassword }, modalStore: { show, hide } } = useStore();

    // state
    const validateSchema = yup.object().shape({
        currentPassword: yup
            .string()
            .required(MSG['error.required']),
        password: yup
            .string()
            .required(MSG['error.required'])
            .matches(passwordRegex, MSG['error.password_format']),
        passwordConfirm: yup
            .string()
            .required(MSG['error.required'])
            .oneOf([yup.ref('password')], MSG['error.confirm_password_not_match']),
    })
    const { register, handleSubmit, formState: { errors, isSubmitting }, trigger, getValues, reset
    } = useForm({ resolver: yupResolver(validateSchema), mode: 'onChange' });
    const [ showCurrentPassword, setShowCurrentPassword ] = useState(false);
    const [ showPassword, setShowPassword ] = useState(false);
    const [ showPasswordConfirm, setShowPasswordConfirm ] = useState(false);

    // function
    const onChangePassword = async (data) => {
        const { currentPassword, password } = data;
        const res = await changePassword({ currentPassword, newPassword: password });
        if(res) {
            reset();
            show({
                id: 'modal-alert',
                isOpen: true,
                notButtonX: true,
                header: null,
                onCancel: () => { hide() },
                children: (
                    <div className='text-center'>
                        <div className='font-bold max-width-960 max-heigh-240'>
                            <i className='fa-regular fa-circle-check fs-cs-70 text-color-cs-green mg-t-20 mg-b-20'></i>
                            <div style={{ 'whiteSpace': 'pre-line' }}>パスワード変更が完了しました.</div>
                            <ModalFooter key={'modal-footer'}
                                cancelButtonText={TEXT.CLOSE}
                                onCancel={() => {
                                    hide();
                                }}
                                disableSaveButton={true}
                            />
                        </div>
                    </div>
                ),
                type: 'medium'
            })
        }
    }

    return(
        <div className='change-password-screen'>
            <div className='container-title'>パスワード変更</div>
            <div className='container-content'>
                <div className='d-flex justify-content-center mg-t-20'>
                    <form className='col-8' onSubmit={handleSubmit(onChangePassword)}>
                        <div className='row'>
                            <label htmlFor='currentPassword' className='field-required col-3-5 fw-bold'>
                            現在のパスワード
                            </label>
                            <div className='col-7-5'>
                                <input {...register('currentPassword')} id='currentPassword' type={!showCurrentPassword ? 'password' : 'text'} 
                                    tabIndex={1}
                                    className='col-12' autoComplete='off'
                                    onCopy={(e) =>  e.preventDefault()}  
                                    onPaste={(e) => e.preventDefault()}  
                                    onCut={(e) =>   e.preventDefault()}
                                />
                                <button type='button' className='btn-icon-input' onClick={() => setShowCurrentPassword(!showCurrentPassword)}>
                                    <i className={classNames(showCurrentPassword ? 'fas fa-eye' : 'fas fa-eye-slash')}/>
                                </button>
                                {
                                    errors?.currentPassword &&
                                <div className='text-danger mg-t-5 fs-error'>{errors.currentPassword?.message}</div>
                                }
                            </div>
                        </div>
                        <div className='row mg-t-20'>
                            <label htmlFor='password' className='field-required col-3-5 fw-bold'>新しいパスワード</label>
                            <div className='col-7-5'>
                                <input {...register('password', {
                                    onChange: () => {
                                        getValues('passwordConfirm') && trigger('passwordConfirm')
                                    }
                                })} id='password' type={!showPassword ? 'password' : 'text'} 
                                tabIndex={2}
                                className='col-12' autoComplete='off'
                                onCopy={(e) =>  e.preventDefault()}  
                                onPaste={(e) => e.preventDefault()}  
                                onCut={(e) =>   e.preventDefault()}
                                />
                                <button type='button' className='btn-icon-input' onClick={() => setShowPassword(!showPassword)}>
                                    <i className={classNames(showPassword ? 'fas fa-eye' : 'fas fa-eye-slash')}/>
                                </button>
                                {
                                    errors?.password && errors?.password?.message.split('\n').map((line, index) => (
                                        <div className='text-danger mg-t-5 fs-error' key={index}>{line}</div>
                                    ))
                                }
                            </div>

                        </div>
                        <div className='row mg-t-20'>
                            <label htmlFor='passwordConfirm' className='field-required col-3-5 fw-bold'>
                            新しいパスワードを再入力
                            </label>
                            <div className='col-7-5'>
                                <input {...register('passwordConfirm', {
                                    onChange: () => {
                                        getValues('password') && trigger('password')
                                    }
                                })} id='passwordConfirm' 
                                tabIndex={3}
                                type={!showPasswordConfirm ? 'password' : 'text'} 
                                className='col-12' autoComplete='off'
                                onCopy={(e) =>  e.preventDefault()}  
                                onPaste={(e) => e.preventDefault()}  
                                onCut={(e) =>   e.preventDefault()}
                                />
                                <button type='button' className='btn-icon-input' onClick={() => setShowPasswordConfirm(!showPasswordConfirm)}>
                                    <i className={classNames(showPasswordConfirm ? 'fas fa-eye' : 'fas fa-eye-slash')}/>
                                </button>
                                {
                                    errors?.passwordConfirm && errors?.passwordConfirm?.message.split('\n').map((line, index) => (
                                        <div className='text-danger mg-t-5 fs-error' key={index}>{line}</div>
                                    ))
                                }
                            </div>

                        </div>
                        <div className='mg-t-20 text-center'>
                            <button type='submit' className='btn btn-bg-cs-1 width-200 mg-t-20' disabled={isSubmitting}>
                            パスワード変更
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
})

export default ChangePasswordScreen;