import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { FormatNumber, ReactNotifications, Table } from '../../../../components';
import { DASHBOARD_CHART_TITTLE, DASHBOARD_DETAILS_TITTLE, DASHBOARD_TYPE } from '../../../../core/configs/constants';
import { useStore } from '../../../../core/utils/hook';
import {Link, useNavigate, useParams } from 'react-router-dom';

const ListPremiumMember = observer(() => {
    //other
    const navigate = useNavigate();
    const { time } = useParams();
    // store
    const { dashboardStore: { monthlyFeeAccountDetail, getDashboardMonthlyFeeOfAccount, monthlyFeePaging , setAttrObservable } } = useStore();

    // state
    const { register, handleSubmit, getValues } = useForm();

    // lifecycle
    useEffect(() => {
        getDashboardDetailData();

        return () => { };
    }, [])

    // modal
    // function
    
    //function
    const getDashboardDetailData = (tableData) => {
        const month = time.split('-')[0];
        const year = time.split('-')[1];
        setAttrObservable('monthlyFeePaging', tableData, true, false);
        getDashboardMonthlyFeeOfAccount({
            ...tableData,
            year,
            month,
            type: DASHBOARD_TYPE.DETAIL,
        })
    }


    

    // columns
    const columns = [
        {
            Header: 'No.',
            accessor: 'id',
            disableSortBy: true,
            Cell: ({ row: { original, index }, initialState }) => {
                return <div>{initialState?.initialTableState?.pageSize * initialState?.initialTableState?.pageIndex + index + 1}</div>
            },
            width: '5%'
        },
        {
            Header: 'ユーザーID',
            accessor: 'user_code',
            disableSortBy: true,
            Cell: ({ row: { original } }) => {
                return (
                    <div>
                        <Link to={'/user/' + original?.user?.id} >{original?.user?.code}</Link>
                    </div>
                );
            },
            width: '15%'
        },
        {
            Header: 'アカウントID',
            accessor: 'account_code',
            disableSortBy: true,
            Cell: ({ row: { original } }) => {
                return (
                    <div>
                        <Link to={'/user/account/' + original?.id} >{original?.code}</Link>
                    </div>
                );
            },
            width: '15%'
        },
        {
            Header: 'ユーザーネーム',
            accessor: 'user_name',
            disableSortBy: true,
            Cell: ({ row: { original } }) => {
                return (
                    <div>
                        {original?.userName}
                    </div>
                );
            },
            width: '10%'
        },
        {
            Header: '名前-本名',
            accessor: 'full_name',
            disableSortBy: true,
            Cell: ({ row: { original } }) => {
                return (
                    <div>
                        {original?.user?.fullName}
                    </div>
                );
            },
            width: '10%'
        },
        {
            Header: 'メールアドレス',
            accessor: 'email',
            disableSortBy: true,
            Cell: ({ row: { original } }) => {
                return (
                    <div>
                        {original?.user?.email}
                    </div>
                );
            },
            width: '15%'
        },
        {
            Header: '会費',
            accessor: 'membership',
            disableSortBy: true,
            Cell: ({ row: { original } }) => {
                return (
                    <div>
                        <FormatNumber statusDisplay='text' value={original?.totalMembershipFee} suffix=' 円'/>
                    </div>
                );
            },
            width: '15%'
        },
        {
            Header: 'Holder追加',
            accessor: 'holder_fee',
            disableSortBy: true,
            Cell: ({ row: { original } }) => {
                return (
                    <div>
                        <FormatNumber statusDisplay='text' value={original?.totalHolderFee} suffix=' 円'/>
                    </div>
                );
            },
            width: '15%'
        },
    ]
    
    return (
        <div className='user-screen'>
            <div className='d-flex justify-content-between align-items-center'>
                <div className='container-title'>{DASHBOARD_DETAILS_TITTLE.PAID_MEMBER}</div>
                <button className='btn-default btn-search-back' onClick={() => navigate('/')}>
                    <i className="fa-solid fa-angles-left"></i>
                    <span className='mg-l-10'>戻る</span>
                </button>
            </div>
            <div className='container-content'>
                <Table
                    columns={columns}
                    data={monthlyFeeAccountDetail || []}
                    disablePaging={false}
                    enableServerSidePaging={true}
                    initialTableState={monthlyFeePaging}
                    onFetch={getDashboardDetailData}
                    className='lst-monthly-fee-table'
                />
            </div>
        </div>
    );
});

export default ListPremiumMember;