import { observer } from 'mobx-react';
import { useForm } from 'react-hook-form';
import { useEffect } from 'react';
import { useStore } from '../../../core/utils/hook';
import { DELETE_FLAG, STATUS_CARD, STATUS_COMMON, SYSTEM_PATH, TEXT } from '../../../core/configs/constants';
import { FormatNumber, Table } from '../../../components';
import { Link } from 'react-router-dom';

import '../style.scss';

const CardsOfGroup = observer(({ cardIdOfGroup, cardGroup }) => {

    // store
    const {  
        cardStore: { getAllCard, cardList, paging, setAttrObservable, clean },
        cardTypeStore: { cardTypeList },
        modalStore: { hide }
    } = useStore();

    // state
    const { register, handleSubmit, getValues, reset } = useForm();

    // lifecycle
    useEffect(() => {
        clean();
        onSearch();
        return () => {
            clean();
        }
    }, [])

    // function
    const onSearch = (data) => {
        clean();
        onFetchData(data);
    }

    const onFetchData = (tableData) => {
        setAttrObservable('paging', tableData, true, false);
        getAllCard({
            ...getValues(),
            ...tableData,
            cardIdOfGroup,
            cardGroup
        }, false)
    }

    // columns
    const columns = [
        {
            Header: 'No.',
            accessor: 'id',
            disableSortBy: true,
            Cell: ({ row: { original, index }, initialState }) => {
                return <div>{initialState?.initialTableState?.pageSize * initialState?.initialTableState?.pageIndex + index + 1}</div>
            },
            width: '5%'
        },
        {
            Header: 'カードID',
            accessor: 'code',
            disableSortBy: true,
            Cell: ({ row: { original } }) => {
                return (
                    <div>
                        <Link to={`${SYSTEM_PATH.CARD_MANAGEMENT}/${original?.id}`} target="_blank">{original?.code}</Link>
                    </div>
                );

            },
            width: '11.5%'
        },
        {
            Header: 'ユーザーID',
            accessor: 'userCode',
            disableSortBy: true,
            Cell: ({ row: { original } }) => {
                return (
                    <div>
                        {original?.account?.user?.deleteFlag === DELETE_FLAG?.NOT_DELETED ?
                            <Link to={`${SYSTEM_PATH.USER_MANAGEMENT}/${original?.account?.user?.id}`} target="_blank">
                                {(original?.status === STATUS_CARD.OWNING.key ||
                                    original?.status === STATUS_CARD.SENT_MONEY.key ||
                                    original?.status === STATUS_CARD.WAIT_FOR_DEPOSIT.key) ? original?.account?.user?.code : <></>}
                            </Link> : <div >
                                {(original?.status === STATUS_CARD.OWNING.key ||
                                    original?.status === STATUS_CARD.SENT_MONEY.key ||
                                    original?.status === STATUS_CARD.WAIT_FOR_DEPOSIT.key) ? original?.account?.user?.code : <></>}
                            </div>}
                    </div>

                );
            },
            width: '10.5%'
        },
        {
            Header: 'アカウントID',
            accessor: 'accountCode',
            disableSortBy: true,
            Cell: ({ row: { original } }) => {
                return (
                    <div>
                        {original?.account?.deleteFlag === DELETE_FLAG?.NOT_DELETED ?
                            <Link to={`/user/account/${original?.account?.id}`} target="_blank">
                                {(original?.status === STATUS_CARD.OWNING.key ||
                                    original?.status === STATUS_CARD.SENT_MONEY.key ||
                                    original?.status === STATUS_CARD.WAIT_FOR_DEPOSIT.key) ? original?.account?.code : <></>}
                            </Link> : <div >
                                {(original?.status === STATUS_CARD.OWNING.key ||
                                    original?.status === STATUS_CARD.SENT_MONEY.key ||
                                    original?.status === STATUS_CARD.WAIT_FOR_DEPOSIT.key) ? original?.account?.code : <></>}
                            </div>}
                    </div>

                );
            },
            width: '10.5%'
        },
        {
            Header: '基準レート (USDT)',
            accessor: 'base_Rate',
            disableSortBy: true,
            Cell: ({ row: { original } }) => {
                return (
                    <div>
                        {original?.baseRate}
                    </div>
                );
            },
            width: '9.5%'
        },
        {
            Header: 'カード価格 (円)',
            accessor: 'card_price',
            disableSortBy: false,
            Cell: ({ row: { original } }) => {
                return (
                    <div>
                        {(original?.cardPrice || original?.cardPrice === 0) ? <FormatNumber statusDisplay='text' value={original?.cardPrice} /> : ''}
                    </div>
                );
            },
            width: '9.5%'
        },
        {
            Header: 'Airdrop (SML)',
            accessor: 'airdrop',
            disableSortBy: true,
            Cell: ({ row: { original } }) => {
                return (
                    <div>
                        {(original?.airdrop || original?.airdrop === 0) ? <FormatNumber statusDisplay='text' value={original?.airdrop} /> : ''}
                    </div>
                );
            },
            width: '9.5%'
        },
        {
            Header: '騰落率',
            accessor: 'variationRate',
            disableSortBy: true,
            Cell: ({ row: { original } }) => {
                const formatVariationRate = original?.variationRate ? original?.variationRate?.toFixed(2) : 0.00;
                return (
                    <div>
                        {original?.variationRate > 0 ? `+${formatVariationRate}` : formatVariationRate}%
                    </div>
                );
            },
            width: '9.5%'
        },
        {
            Header: 'カード種類',
            accessor: 'cardType',
            disableSortBy: true,
            Cell: ({ row: { original } }) => {
                return (
                    <div>
                        {original?.cardType}
                    </div>
                );
            },
            width: '9.5%'
        },
        {
            Header: 'カードステータス',
            accessor: 'status',
            disableSortBy: true,
            Cell: ({ row: { original } }) => {
                const roleKey = Object.keys(STATUS_CARD).filter(e => STATUS_CARD[e].key === original?.status)?.[0] ?? null;
                return (
                    <div style={{ 'color': STATUS_CARD[roleKey]?.color }} >
                        {roleKey && STATUS_CARD[roleKey]?.label}
                    </div>
                );
            },
            width: '9.5%'
        }
    ]

    return (
        <div className='cards-of-group max-height-modal pd-tb-20 pd-lr-16'>
            <div>
                <form onSubmit={handleSubmit(onSearch)}>
                    <div className='row'>
                        <div className='d-flex col-6 align-items-center'>
                            <label className='col-3'>カードID</label>
                            <input {...register('code')} className='col-8' />
                        </div>
                        <div className='d-flex col-6 align-items-center justify-content-end'>
                            <label className='col-3'>カードステータス</label>
                            <select {...register('status')} className='col-8'>
                                <option value={''} >すべて</option>
                                {
                                    [STATUS_CARD.NO_OWNER, STATUS_CARD.NOT_PUBLIC, STATUS_CARD.OWNING].map(status => (
                                        <option key={status.key} value={status.key}>{status.label}</option>
                                    ))
                                }
                            </select>
                        </div>
                    </div>
                    <div className='row mg-t-20'>
                        <div className='d-flex col-6 align-items-center'>
                            <label className='col-3'>カード種類</label>
                            <select {...register('cardTypeId')} className='col-8'>
                                <option value={''}>すべて</option>
                                {
                                    cardTypeList.map(e =>
                                        <option key={e?.id} value={e?.id} >{e?.name}</option>
                                    )
                                }
                            </select>
                        </div>
                    </div>
                    <div className='text-center mg-t-20'>
                        <button type={'submit'} className={'btn btn-bg-cs-1 btn-default btn-search-back'}>
                            <i className='fa-solid fa-magnifying-glass'></i>
                            <span className='mg-l-10'>{TEXT.SEARCH}</span>
                        </button>
                    </div>
                </form>
            </div>
            <hr/>
            <div>
                <Table
                    columns={columns}
                    data={cardList || []}
                    disablePaging={false}
                    enableServerSidePaging={true}
                    initialTableState={paging}
                    onFetch={onFetchData}
                    className='lst-cards-of-group-table'
                />
            </div>
            <div className='mg-t-30 text-center'>
                <button type='button' onClick={hide} className='btn-default btn-bg-cs-1 width-150'>閉じる</button>
            </div>
        </div>
    )
})

export default CardsOfGroup;