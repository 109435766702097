import { CKEditor } from '@ckeditor/ckeditor5-react';
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import './style.scss';

const fontSizeOptions = [10, 11, 12, 13, 14, 16, 18, 20, 22, 24, 26, 28, 36];

const EditorCustom = (props) => {

    const {
        // attribute
        id,
        isReadOnly,
        data,
        shouldNotGroupWhenFull = true,
        toolbarCustomItems = [],
        // plugin
        useTittlePlugin = false,
        // function
        onReady,
        onChange,
        onBlur,
        onFocus,
        elementToElements = null
    } = props;

    return(
        <div className='CK-custom'>
            <CKEditor
                id={id}
                editor={ Editor }
                width='100%'
                config={{
                    fontSize: {
                        options: fontSizeOptions
                    },
                    toolbar: {
                        items: toolbarCustomItems?.length > 0 ? toolbarCustomItems : Editor.defaultConfig.toolbar.items,
                        shouldNotGroupWhenFull
                    },
                    removePlugins: [!useTittlePlugin && 'Title']
                }}
                disabled={isReadOnly}
                data={data}
                onReady={(editor) =>  {
                    if(isReadOnly) {
                        editor.ui.view.toolbar.element.style.display = 'none';
                    }
                    if(elementToElements?.length > 0) {
                        for (const elementToElement of elementToElements) {
                            editor.conversion.elementToElement(elementToElement);
                        }
                    }
                    onReady && onReady(editor);
                }}
                onChange={onChange}
                onBlur={onBlur}
                onFocus={onFocus}
            />
        </div>
    )
}

export default EditorCustom;