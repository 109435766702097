import { forwardRef } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

const ReCaptchaV2 = forwardRef((props, ref) => {
    // other
    const { 
        className, 
        onChange
    } = props;

    const siteKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY;
    
    return (
        <ReCAPTCHA  
            className={className} 
            sitekey={siteKey} 
            ref={ref} 
            onChange={onChange}
        />
    )
})

export default ReCaptchaV2;