import { Link, useLocation } from 'react-router-dom';
import { ROLE, SIDEBAR_TITLE, SYSTEM_PATH } from '../../core/configs/constants';
import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import { observer } from 'mobx-react';
import { useStore } from '../../core/utils/hook';

const SideBar = observer((props) => {

    // other
    const location = useLocation();

    // store
    const { authStore: { userInfo } } = useStore();

    return (
        <Sidebar width='265px' collapsedWidth='65px' defaultCollapsed={localStorage.getItem('collapsedMenu') === 'true' ? true : false}
        >
            <Menu>
                <MenuItem className='ps-menu-logo'>
                    <img src='/images/logo.png' alt='logo-app' className='logo-app' />
                    <div className='text-app'>RAISE管理画面</div>
                </MenuItem>
            </Menu>
            {
                userInfo?.email &&
                <Menu className='ps-menu-root-content' closeOnClick={true}>
                    {
                        userInfo?.role !== ROLE.STAFF.key &&
                        <>
                            <MenuItem icon={<i className='fa-solid fa-chart-line'></i>} component={<Link to={SYSTEM_PATH.DASHBOARD} />}
                                active={location.pathname.includes(SYSTEM_PATH.DASHBOARD) || location.pathname ==SYSTEM_PATH.HOME }>
                                {SIDEBAR_TITLE.DASHBOARD}
                            </MenuItem>
                        </>
                    }
                    <MenuItem icon={<i className='fa-solid fa-user'></i>} component={<Link to={SYSTEM_PATH.USER_MANAGEMENT} />}
                        active={location.pathname.includes(SYSTEM_PATH.USER_MANAGEMENT)}>
                        {SIDEBAR_TITLE.USER_MANAGEMENT}
                    </MenuItem>
                    <MenuItem icon={<i className='fa-solid fa-layer-group'></i>} component={<Link to={SYSTEM_PATH.CARD_TYPE_MANAGEMENT} />}
                        active={location.pathname.includes(SYSTEM_PATH.CARD_TYPE_MANAGEMENT)}>
                        {SIDEBAR_TITLE.CARD_TYPE_MANAGEMENT}
                    </MenuItem>
                    <MenuItem icon={<i className='fa-solid fa-tablet fa-rotate-180'></i>} component={<Link to={SYSTEM_PATH.CARD_MANAGEMENT} />}
                        active={location.pathname.includes(SYSTEM_PATH.CARD_MANAGEMENT) && !location.pathname.includes(SYSTEM_PATH.CARD_MANAGEMENT)}>
                        {SIDEBAR_TITLE.CARD_MANAGEMENT}
                    </MenuItem>
                    <SubMenu label={SIDEBAR_TITLE.PAYMENT_MANAGEMENT.LABEL}
                        icon={<i className='fa-solid fa-money-check-dollar'></i>} >
                        <MenuItem component={<Link to={SYSTEM_PATH.CARD_PAYMENT_MANAGEMENT} />}
                            active={location.pathname.includes(SYSTEM_PATH.CARD_PAYMENT_MANAGEMENT)}
                        >{SIDEBAR_TITLE.PAYMENT_MANAGEMENT.CARD}</MenuItem>
                        <MenuItem component={<Link to={SYSTEM_PATH.HOLDER_PAYMENT_MANAGEMENT} />}
                            active={location.pathname.includes(SYSTEM_PATH.HOLDER_PAYMENT_MANAGEMENT)}
                        >{SIDEBAR_TITLE.PAYMENT_MANAGEMENT.HOLDER}</MenuItem>
                        <MenuItem component={<Link to={SYSTEM_PATH.MEMBERSHIP_PAYMENT_MANAGEMENT} />}
                            active={location.pathname.includes(SYSTEM_PATH.MEMBERSHIP_PAYMENT_MANAGEMENT)}
                        >{SIDEBAR_TITLE.PAYMENT_MANAGEMENT.MEMBERSHIP}</MenuItem>
                    </SubMenu>
                    {
                        userInfo?.role !== ROLE.STAFF.key &&
                            <MenuItem icon={<i className='fa-solid fa-file-signature'></i>}
                                component={<Link to={SYSTEM_PATH.SUBSCRIPTION_CONTRACT_MANAGEMENT} />}
                                active={location.pathname.includes(SYSTEM_PATH.SUBSCRIPTION_CONTRACT_MANAGEMENT)}>
                                {SIDEBAR_TITLE.SUBSCRIPTION_CONTRACT_MANAGEMENT}
                            </MenuItem>
                    }       
                    <MenuItem icon={<i className='fa-solid fa-envelope'></i>} component={<Link to={SYSTEM_PATH.INQUIRY_MANAGEMENT} />}
                        active={location.pathname === SYSTEM_PATH.INQUIRY_MANAGEMENT}>
                        {SIDEBAR_TITLE.INQUIRY_MANAGEMENT}
                    </MenuItem>
                    {
                        userInfo?.role !== ROLE.STAFF.key &&
                        <>
                            <MenuItem 
                                icon={<i className='fa-solid fa-envelope-circle-check'></i>} 
                                component={<Link to={SYSTEM_PATH.TEMPLATE_EMAIL} />}
                                active={location.pathname === SYSTEM_PATH.TEMPLATE_EMAIL}>
                                {SIDEBAR_TITLE.TEMPLATE_EMAIL}
                            </MenuItem>
                            <SubMenu label={SIDEBAR_TITLE.GENERAL_SETTING.LABEL}
                                icon={<i className='fa-solid fa-gear'></i>} >
                                <MenuItem component={<Link to={SYSTEM_PATH.GENERAL_SETTING_TERM} />}
                                    active={location.pathname.includes(SYSTEM_PATH.GENERAL_SETTING_TERM)}
                                >{SIDEBAR_TITLE.GENERAL_SETTING.TERMS}</MenuItem>
                                <MenuItem component={<Link to={SYSTEM_PATH.GENERAL_SETTING_ACT} />}
                                    active={location.pathname.includes(SYSTEM_PATH.GENERAL_SETTING_ACT)}
                                >{SIDEBAR_TITLE.GENERAL_SETTING.ACT_OF_SPECIFIED_COMMERCIAL_TRANSACTION}</MenuItem>
                                <MenuItem component={<Link to={SYSTEM_PATH.GENERAL_SETTING_PRIVACY} />}
                                    active={location.pathname.includes(SYSTEM_PATH.GENERAL_SETTING_PRIVACY)}
                                >{SIDEBAR_TITLE.GENERAL_SETTING.PRIVACY_POLICY}</MenuItem>
                                <MenuItem component={<Link to={SYSTEM_PATH.GENERAL_SETTING_ABOUT} />}
                                    active={location.pathname.includes(SYSTEM_PATH.GENERAL_SETTING_ABOUT)}
                                >{SIDEBAR_TITLE.GENERAL_SETTING.ABOUT_US}</MenuItem>
                            </SubMenu>
                            <MenuItem icon={<i className="fa-solid fa-sliders"></i>} component={<Link to={SYSTEM_PATH.OTHER_SETTING} />}
                                active={location.pathname === SYSTEM_PATH.OTHER_SETTING}>
                                {SIDEBAR_TITLE.OTHER_SETTING}
                            </MenuItem>
                        </>
                    }
                </Menu>
            }
        </Sidebar>
    )
})

export default SideBar;