import ApiService from './ApiService';

class SubscriptionContractApi extends ApiService {
    constructor() {
        super('contract');
    }

    getAllContract(payload) {
        return this.get('', payload);
    }

    getContract({ id, payload }) {
        return this.get(id, payload);
    }

    updateContract({ id, payload }) {
        return this.put(id, payload);
    }
}

export default SubscriptionContractApi;