import { flow, makeObservable, observable, toJS } from 'mobx';
import { CardApi } from '../apis';
import BaseStore from './BaseStore';
import { STATUS_CARD } from '../core/configs/constants';

class CardStore extends BaseStore {


    cardList = [];
    disabledCardList = [];
    cardListCanSelect = [];
    card = {};
    keepSearchParams = {};

    adminPaging = this.defaultPaging;
    memberPaging = this.defaultPaging;

    constructor(rootStore) {
        super();
        makeObservable(this, {
            adminPaging: observable,
            memberPaging: observable,
            cardList: observable,
            disabledCardList: observable,
            cardListCanSelect: observable,
            card: observable,
            keepSearchParams: observable,
            getAllCard: flow.bound,
            getCard: flow.bound,
            createCard: flow.bound,
            updateCard: flow.bound,
            deleteCard: flow.bound,
            updateCardStatus: flow.bound,
            assignCard: flow.bound
        })
        this.rootStore = rootStore;
        this.api = new CardApi();
    }

    *getAllCard(searchParams, isKeepSearchParams = true) {
        try {
            const { size, page, sortDir, sortKey } = this.paging;
            const payload = { size, page, sortDir, sortKey, ...searchParams };
            const res = yield this.rootStore.apiStore.call(this.api, this.api.getAllCard, payload);
            if (res?.ok) {
                this.cardList = res?.data?.elements || [];
                this.cardListCanSelect = res?.data?.elements?.filter(e => e.status === STATUS_CARD.NO_OWNER.key);
                this.disabledCardList = res?.data?.elements?.map(e => e.status !== STATUS_CARD.NO_OWNER.key);
                if(isKeepSearchParams) {
                    this.keepSearchParams = payload;
                }
                this.setAttrObservable('paging', res?.data?.paginate, true, false);
            }
        } catch (error) {
            console.log(error);
        }
    }

    *getCard(id, payload) {
        try {
            const res = yield this.rootStore.apiStore.call(this.api, this.api.getCard, { id, payload });
            if (res?.ok) {
                this.card = res?.data ?? {};
            }
        } catch (error) {
            console.log(error);
        }
    }

    *createCard(payload) {
        try {
            const res = yield this.rootStore.apiStore.call(this.api, this.api.createCard, payload);
            if (res?.ok) {
                return true;
            }
            return false;
        } catch (error) {
            console.log(error);
        }
    }

    *updateCard(id, payload) {
        try {
            const res = yield this.rootStore.apiStore.call(this.api, this.api.updateCard, { id, payload });
            if (res?.ok) {
                return true;
            }
            return false;
        } catch (error) {
            console.log(error);
        }
    }

    *deleteCard(id, payload) {
        try {
            const res = yield this.rootStore.apiStore.call(this.api, this.api.deleteCard, { id, payload });
            if (res?.ok) {
                return true;
            }
            return false;
        } catch (error) {
            console.log(error);
        }
    }
    *updateCardStatus(id, payload) {
        try {
            const res = yield this.rootStore.apiStore.call(this.api, this.api.updateCardStatus, { id, payload });
            if (res?.ok) {
                return true;
            }
            return false;
        } catch (error) {
            console.log(error);
        }
    }
    *assignCard(payload) {
        try {
            const res = yield this.rootStore.apiStore.call(this.api, this.api.assignCard, payload);
            if (res?.ok) {
                return true;
            }
            return false;
        } catch (error) {
            console.log(error);
        }
    }



    clean() {
        super.clean();
        this.cardList = [];
    }
}

export default CardStore;