/* eslint-disable no-undef */
import { Suspense } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { SYSTEM_PATH, TRANSACTION_CONFIRM_SCREEN } from './core/configs/constants';
import Root from './routes/Root';
import { ReactNotifications } from 'react-notifications-component';
import LoginScreen from './screens/Login/LoginScreen';
import { Authentication, ClearAuthentication, Loading, ReactModal } from './components';
import Modal from 'react-modal';
import ForgetPasswordScreen from './screens/Password/ForgetPasswordScreen';
import SetPasswordScreen from './screens/Password/SetPasswordScreen';

require('jquery');
const bootstrap = require('bootstrap');
window.bootstrap = bootstrap;
import 'bootstrap-select/dist/css/bootstrap-select.min.css';
require('bootstrap-select');

import 'animate.css';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-tabs/style/react-tabs.css';
import './App.scss';
import ConfirmEmailScreen from './screens/ConfirmEmail/ConfirmEmailScreen';
import TransactionConfirmScreen from './screens/TransactionConfirm/TransactionConfirmScreen';
import LineInquiryDetail from './screens/Inquiry/LineInquiryDetail';


// Make sure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement('#root');

const App = () => {
    return (
        <div className='app'>
            <Suspense fallback=''>
                <ReactNotifications isMobile={true} breakpoint={500} />
                <BrowserRouter>
                    <Routes>
                        <Route>
                            {/* public route - with out authenticate */}
                            <Route path={SYSTEM_PATH.LOGIN} element={<LoginScreen />} />

                            {/* line inquiry notify */}
                            <Route path={SYSTEM_PATH.LINE_INQUIRY_DETAIL}
                                element={<LineInquiryDetail />} />
                                
                            {/* transaction confirm */}
                            <Route path={SYSTEM_PATH.TRANSACTION_CONFIRM_ACCEPT}
                                element={<TransactionConfirmScreen type={TRANSACTION_CONFIRM_SCREEN.ACCEPT}/>} />

                            <Route path={SYSTEM_PATH.TRANSACTION_CONFIRM_AIRDROP}
                                element={<TransactionConfirmScreen type={TRANSACTION_CONFIRM_SCREEN.AIRDROP}/>} />

                            <Route element={<ClearAuthentication />}>
                                <Route path={SYSTEM_PATH.FORGET_PASSWORD} element={<ForgetPasswordScreen />} />
                                <Route path={SYSTEM_PATH.SET_PASSWORD} element={<SetPasswordScreen />} />
                                <Route path={SYSTEM_PATH.CONFIRM_EMAIL} element={<ConfirmEmailScreen />} />
                            </Route>

                            {/* private route - with authenticate and base layout*/}
                            <Route element={<Authentication />}>
                                <Route path='/*' element={<Root />} />
                            </Route>
                        </Route>
                    </Routes>
                    <ReactModal />
                </BrowserRouter>
                <Loading />
            </Suspense>
        </div>
    );
}

export default App;
