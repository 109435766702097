import classnames from 'classnames';
import { observer } from 'mobx-react';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useStore } from '../../core/utils/hook';
import SideBar from './SideBar';
import { useProSidebar } from 'react-pro-sidebar';
import { AVATAR_DEFAULT_URL, ROLE, SYSTEM_PATH } from '../../core/configs/constants';
import { useEffect, useState } from 'react';

import './style.scss';
import { toJS } from 'mobx';
import NotFoundScreen from '../../screens/404/NotFoundScreen';


const ContainerBody = observer(() => {

    // other
    const navigate = useNavigate();
    const location = useLocation();

    // store
    const { authStore: { userInfo, getInfo, logout }, cardStore: { setAttrObservable } } = useStore();

    // state
    const [isPermission, setIsPermission] = useState(true);

    // function
    const { collapsed, collapseSidebar } = useProSidebar();

    // lifecycle
    useEffect(() => {
        getInfo();
    }, [])

    useEffect(() => {
        checkPermission();
        if (!location.pathname.includes(SYSTEM_PATH.CARD_MANAGEMENT) || location.pathname.includes(SYSTEM_PATH.CARD_ADD)) {
            setAttrObservable('keepSearchParams', {});
        }
    }, [location.pathname, userInfo])

    // function
    const checkPermission = () => {
        let isPermission = true;
        if (userInfo?.role) {
            if (userInfo.role === ROLE.STAFF.key) {
                isPermission = false;
                
                if((location.pathname.includes('/user') && location.pathname !== '/user/add') || 
                    (location.pathname.includes('/card') && location.pathname !== '/card/add') || 
                    location.pathname.includes('/buy-card') ||
                    location.pathname === '/holder-payment' ||
                    location.pathname === '/membership-payment' ||
                    location.pathname === '/inquiry') {
                    isPermission = true;
                }
            }
        }
        setIsPermission(isPermission);
    }

    const onToggleCollapse = () => {
        localStorage.setItem('collapsedMenu', !collapsed);
        collapseSidebar();
    }

    // function
    const onLogout = async () => {
        const res = await logout();
        if (res) {
            navigate(0);
        }
    }

    return (
        <div className='wrapper d-flex h-100 w-100'>
            <div className='wrapper-side-bar'>
                <SideBar isShowSideBar={collapsed} />
            </div>
            <div className={classnames('wrapper-container', !collapsed && 'nav-show')}>
                <div className='wrapper-header'>
                    <div role={'button'} onClick={onToggleCollapse}>
                        {
                            !collapsed ?
                                <i className='fa-solid fa-outdent fs-heading-normal'></i>
                                :
                                <i className='fa-solid fa-indent fs-heading-normal'></i>
                        }
                    </div>
                    <div className='d-flex align-items-center'>
                        <div className='drop-down mg-l-30'>
                            <div className='user-info d-flex align-items-center dropdown-toggle'
                                type='button' id='dropdownMenuInfo' data-bs-toggle='dropdown' aria-expanded='false'>
                                <img src={userInfo?.avatar || AVATAR_DEFAULT_URL} alt='avatar' />
                                <span className='pd-l-5 fw-bold'>{userInfo?.fullName ?? ''}</span>
                            </div>
                            <ul className='dropdown-menu dropdownMenuInfo' aria-labelledby='dropdownMenuInfo'>
                                <li>
                                    <Link className='dropdown-item' to={SYSTEM_PATH.CHANGE_PASSWORD}>
                                        <i className='fa-solid fa-key'></i> パスワード変更
                                    </Link>
                                </li>
                                <li>
                                    <Link className='dropdown-item' onClick={onLogout}>
                                        <i className='fa-solid fa-right-from-bracket'></i> ログアウト
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className='wrapper-content'>
                    {
                        isPermission ?
                            <Outlet />
                            :
                            <NotFoundScreen />
                    }
                </div>
            </div>
        </div>
    )
})

export default ContainerBody;