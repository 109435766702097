import ApiService from './ApiService';

class AccountApi extends ApiService {
    constructor() {
        super('account');
    }

    getAllAccount(payload) {
        return this.get('', payload);
    }

    getAccount({ id, payload }) {
        return this.get(id, payload);
    }

    addAccount(payload) {
        return this.post('', payload);
    }

    updateAccount({ id, payload }) {
        return this.put(id, payload);
    }

    deleteAccount({ id, payload }) {
        return this.delete(id, payload);
    }

    cardListOfMember({ id, payload }) {
        return this.get(`card/${id}`, payload);
    }
}

export default AccountApi;
