/* eslint-disable max-len */
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import 'react-tabs/style/react-tabs.css';
import { useStore } from '../../../core/utils/hook';
import { FormatNumber, FullPagination } from '../../../components';
import moment from 'moment';
import { AIRDROP_STATUS, MSG } from '../../../core/configs/constants';
import classNames from 'classnames';
const CardsPurchased = observer((props) => {
    // props
    const { listCardBuyOfUser, id } = props

    //other
    const navigate = useNavigate();

    // store
    const {
        userStore: { listCardbuy, paging }
    } = useStore();

    //state





    //function
    const onFetchCardList = async (payload) => {
        await listCardbuy(id, {
            ...payload
        })
    }

    const onPageChange = (page) => {
        onFetchCardList({ page });
    }


    return (
        <div>

            <div className='d-flex flex-wrap flex-gap-5 w-full card-purchase-screen'>
                {listCardBuyOfUser?.length > 0 ? listCardBuyOfUser.map((item) => (
                    <div key={item.id} className='d-flex  justify-content-center col-6-40 mg-b-12 bg-light p-4 '>
                        <div className='col-4'>
                            <div className=' d-block m-auto'>
                                <img className='width-180 height-154' src={item?.image}></img>
                            </div>
                        </div>
                        <div className='col-8'>

                            <div className='flex py-2 border-bottom border-dark'>
                                <div className='d-flex align-items-center mb-2  '>
                                    <span className='width-120 fw-bold'>管理番号</span>
                                    <span >{item?.code}</span>
                                </div>

                                <div className='d-flex align-items-center mb-2 '>
                                    <span className='width-120 fw-bold'>発行日時</span>
                                    <span >{item?.publicAt}</span>
                                </div>
                                <div className='d-flex align-items-center mb-2 '>
                                    <span className='width-120 fw-bold'>現在レート</span>
                                    <span >{item?.baseRate} USDT</span>
                                </div>
                                <div className='d-flex align-items-center mb-2 '>
                                    <span className='width-120 fw-bold'>カード価格</span>
                                    <div className=' text-color-cs-red fw-bold'>
                                        <FormatNumber statusDisplay='text' value={item?.cardPrice} suffix=' 円' />
                                    </div>
                                </div>
                                <div className='d-flex align-items-center mb-2 '>
                                    <span className='width-120 fw-bold'>Airdrop</span>
                                    {item?.airdrop || item?.airdrop === 0 ?
                                        <div >
                                            <FormatNumber statusDisplay='text' value={item?.airdrop} suffix=' SML' />
                                        </div> : ''}

                                </div>

                            </div>
                            <div className='mg-t-16' >
                                <div className='d-flex align-items-center  mb-2'>
                                    <span className='width-120 fw-bold'>取引ID</span>
                                    <span className='fw-bold'>{item?.transaction?.code}</span>
                                </div>
                                <div className='d-flex align-items-center  mb-2'>
                                    <span className='width-120 fw-bold'>作成日</span>
                                    <span >{item?.transaction?.createdAt
                                        ? moment(item?.transaction?.createdAt).format('YYYY/MM/DD HH:mm:ss') : ''}</span>
                                </div>
                                <div className='d-flex align-items-center  mb-2'>
                                    <span className='width-120 fw-bold'>支払日</span>
                                    <span >{item?.transaction?.transactionDate ?
                                        moment(item?.transaction?.transactionDate).format('YYYY/MM/DD HH:mm:ss') : ''}</span>
                                </div>
                                <div className='d-flex align-items-center  mb-2'>
                                    <span className='width-120 fw-bold'>購入時レート</span>
                                    <div className=''>
                                        <FormatNumber statusDisplay='text' decimalScale={6} value={item?.currentRate} suffix=' USDT' />
                                    </div>
                                    {/* <span >{(+item?.currentRate)?.toFixed(6)}</span> */}
                                </div>
                                <div className='d-flex align-items-center  mb-2'>
                                    <span className='width-120 fw-bold'>Drop先アドレス</span>
                                    <span >{item?.transaction?.dropAddress}</span>
                                </div>
                                <div className='d-flex align-items-center  mb-2'>
                                    <span className='width-120 fw-bold'>ステータス</span>
                                    <div className={classNames('width-80 height-30 d-flex align-items-center justify-content-center border text-white',
                                        item?.transaction?.airdropStatus === AIRDROP_STATUS.WAITING_PAYMENT.key ? 'bg-primary' : 'bg-success')}
                                    >{item?.transaction?.airdropStatus === AIRDROP_STATUS.WAITING_PAYMENT.key ? AIRDROP_STATUS.WAITING_PAYMENT.label : AIRDROP_STATUS.COMPLETED_PAYMENT.label}</div>
                                </div>
                            </div>
                        </div>

                    </div>
                )) : <div className='text-center mx-auto fs-default'>{MSG['inform.no_data']}</div>}
            </div>
            <div className='d-flex align-items-center justify-content-center '>
                <FullPagination siblingCount={0}
                    totalRecords={paging?.totalRecord}
                    currentPage={paging?.page}
                    pageSize={paging?.size}
                    onPageChange={onPageChange}
                    previousLabel="前"
                    nextLabel="次" />
            </div>
        </div>


    )
})

export default CardsPurchased;