import { Store } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css'

const ReactNotifications = (type, message) => {
    switch (type) {
    case 'info':
        Store.addNotification({
            // title: 'Info!', // The 'info' notification doesn't need a title
            message: message,
            type: 'info',
            insert: 'top',
            container: 'top-right',
            animationIn: ['animated', 'fadeIn'],
            animationOut: ['animated', 'fadeOut'],
            dismiss: { duration: 0, showIcon: true }
        });
        break;
    case 'success':
        Store.addNotification({
            title: '成功!',
            message: message,
            type: 'success',
            insert: 'top',
            container: 'top-right',
            animationIn: ['animated', 'fadeIn'],
            animationOut: ['animated', 'fadeOut'],
            dismiss: { duration: 1500, showIcon: true }
        });
        break;
    case 'warning':
        Store.addNotification({
            title: '警告!',
            message: message,
            type: 'warning',
            insert: 'top',
            container: 'top-right',
            animationIn: ['animated', 'fadeIn'],
            animationOut: ['animated', 'fadeOut'],
            dismiss: { duration: 1500, showIcon: true }
        });
        break;
    case 'error':
        Store.addNotification({
            title: 'エラー!',
            message: message,
            type: 'danger',
            insert: 'top',
            container: 'top-right',
            animationIn: ['animated', 'fadeIn'],
            animationOut: ['animated', 'fadeOut'],
            dismiss: { duration: 1500, showIcon: true }
        });
        break;
    default:
        Store.addNotification({
            title: 'Warning!',
            message: message,
            type: 'warning',
            insert: 'top',
            container: 'top-right',
            animationIn: ['animated', 'fadeIn'],
            animationOut: ['animated', 'fadeOut'],
            dismiss: { duration: 1500, showIcon: true }
        });
    }
};

export default ReactNotifications;
