import { action, flow, makeObservable, observable } from 'mobx';
import BaseStore from './BaseStore';
import { DashboardApi } from '../apis';
import { DASHBOARD_CHART_TITTLE, DASHBOARD_TYPE } from '../core/configs/constants';

class DashboardStore extends BaseStore {
    registedUserList = [];
    cardList = {};
    monthlyFeeAccountList = {};
    revenueList = {};
    registedUsersDetail = [];
    cardsDetail = [];
    monthlyFeeAccountDetail = [];
    revenueDetail = [];
    userPaging = this.defaultPaging;
    cardPaging = this.defaultPaging;
    monthlyFeePaging = this.defaultPaging;
    
    constructor(rootStore) {
        super();
        makeObservable(this, {
            registedUserList: observable,
            cardList: observable,
            monthlyFeeAccountList: observable,
            revenueList: observable,
            registedUsersDetail: observable,
            cardsDetail: observable,
            monthlyFeeAccountDetail: observable,
            revenueDetail: observable,
            userPaging: observable,
            cardPaging: observable,
            monthlyFeePaging: observable,
            getDashboardNewUser: flow.bound,
            getDashboardCard: flow.bound,
            getDashboardMonthlyFeeOfAccount: flow.bound,
            getDashboardRevenue: flow.bound,
            getFileExport: flow.bound,
        })
        this.rootStore = rootStore;
        this.api = new DashboardApi;
    }

    *getDashboardNewUser(searchParams) {
        try {
            const { type } = searchParams;
            const { size, page, sortDir, sortKey } = this.userPaging;
            const payload = { size, page, sortDir, sortKey, ...searchParams };
            const res = yield this.rootStore.apiStore.call(this.api, this.api.getDashboardNewUser, payload);
            if (res?.ok) {
                if( type === DASHBOARD_TYPE.OVERVIEW) {
                    this.registedUserList = res?.data || [];
                    return true;
                } else if ( type === DASHBOARD_TYPE.DETAIL) {
                    this.registedUsersDetail = res?.data?.elements || [];
                    this.setAttrObservable('userPaging', res?.data?.paginate, true, false);
                }
                return true;
            }
        } catch (error) {
            console.log(error);
        }
    }

    *getDashboardCard(searchParams) {
        try {
            const { type } = searchParams;
            const { size, page, sortDir, sortKey } = this.userPaging;
            const payload = { size, page, sortDir, sortKey, ...searchParams };
            const res = yield this.rootStore.apiStore.call(this.api, this.api.getDashboardCard, payload);
            if (res?.ok) {
                if( type === DASHBOARD_TYPE.OVERVIEW ) {
                    this.cardList = res?.data || {};
                } else if ( type === DASHBOARD_TYPE.DETAIL ) {
                    this.cardsDetail = res?.data?.elements || [];
                    this.setAttrObservable('cardPaging', res?.data?.paginate, true, false);
                }
                return true;
            }
        } catch (error) {
            console.log(error);
        }
    }

    *getDashboardMonthlyFeeOfAccount(searchParams) {
        try {
            const { type } = searchParams;
            const { size, page, sortDir, sortKey } = this.monthlyFeePaging;
            const payload = { size, page, sortDir, sortKey, ...searchParams };
            const res = yield this.rootStore.apiStore.call(this.api, this.api.getDashboardMonthlyFeeOfAccount, payload);
            if (res?.ok) {
                if( type === DASHBOARD_TYPE.OVERVIEW) {
                    this.monthlyFeeAccountList = res?.data || {};
                    return true;
                } else if ( type === DASHBOARD_TYPE.DETAIL) {
                    this.monthlyFeeAccountDetail = res?.data?.elements || [];
                    this.setAttrObservable('monthlyFeePaging', res?.data?.paginate, true, false);
                }
                return res;
            }
        } catch (error) {
            console.log(error);
        }
    }

    *getDashboardRevenue(searchParams) {
        try {
            const { type } = searchParams;
            const payload = { ...searchParams };
            const res = yield this.rootStore.apiStore.call(this.api, this.api.getDashboardRevenue, payload);
            if (res?.ok) {
                if( type === DASHBOARD_TYPE.OVERVIEW) {
                    this.revenueList = res?.data || {};
                    return true;
                } else if (type === DASHBOARD_TYPE.DETAIL) {
                    this.revenueDetail = res?.data || [];
                }
                return res;
            }
        } catch (error) {
            console.log(error);
        }
    }

    *getFileExport(params) {
        try {
            const { title, year, type } = params;
            const payload = { year, type };
            let res;
            switch (title) {
            case DASHBOARD_CHART_TITTLE.NEW_USER:
                res = yield this.rootStore.apiStore.call(this.api, this.api.getDashboardNewUser, payload);
                break;
            case DASHBOARD_CHART_TITTLE.CARD:
                res = yield this.rootStore.apiStore.call(this.api, this.api.getDashboardCard, payload);
                break;
            case DASHBOARD_CHART_TITTLE.PAID_MEMBER:
                res = yield this.rootStore.apiStore.call(this.api, this.api.getDashboardMonthlyFeeOfAccount, payload);
                break;
            case DASHBOARD_CHART_TITTLE.REVENUE:
                res = yield this.rootStore.apiStore.call(this.api, this.api.getDashboardRevenue, payload);
                break;
            default:
                res = null;
                break;
            }
            if (res?.ok) {
                return res;
            }
        } catch (error) {
            console.log(error);
        }
    }
}

export default DashboardStore;