import { DASHBOARD_TYPE } from '../core/configs/constants';
import ApiService from './ApiService';

class DashboardApi extends ApiService {
    constructor() {
        super('dashboard');
    }
    getDashboardNewUser(payload) {
        const { type } = payload;
        return this.get('new-user', payload, null, ( type === DASHBOARD_TYPE.EXPORT ? 'blob' : 'json'));
    }

    getDashboardCard(payload) {
        const { type } = payload;
        return this.get('card', payload, null, ( type === DASHBOARD_TYPE.EXPORT ? 'blob' : 'json'));
    }

    getDashboardMonthlyFeeOfAccount(payload) {
        const { type } = payload;
        return this.get('monthly-fee-of-account', payload, null, ( type === DASHBOARD_TYPE.EXPORT ? 'blob' : 'json'));
    }

    getDashboardRevenue(payload) {
        const { type } = payload;
        return this.get('transaction', payload, null, ( type === DASHBOARD_TYPE.EXPORT ? 'blob' : 'json'));
    }
}

export default DashboardApi;