import { yupResolver } from '@hookform/resolvers/yup';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { MSG, SYSTEM_PATH } from '../../core/configs/constants';
import { useStore } from '../../core/utils/hook';
import yup from '../../core/utils/yupValidate';
import classNames from 'classnames';
import NotFoundScreen from '../404/NotFoundScreen';
import { passwordRegex } from '../../core/utils/common';
import { ModalFooter } from '../../components';

const SetPasswordScreen = observer(() => {

    // other
    const navigate = useNavigate();
    const [ searchParams ] = useSearchParams();
    const uuid = searchParams.get('uuid');

    // store
    const { authStore: { setPassword, verifyUuid }, modalStore: { hide, show } } = useStore();

    /// state
    const validateLoginSchema = yup.object().shape({
        password: yup
            .string()
            .required(MSG['error.required'])
            .matches(passwordRegex, MSG['error.password_format']),
        passwordConfirm: yup
            .string()
            .required(MSG['error.required'])
            .oneOf([yup.ref('password')], MSG['error.confirm_password_not_match']),
    })
    const { register, handleSubmit, formState: { errors, isSubmitting }, getValues, trigger 
    } = useForm({resolver: yupResolver(validateLoginSchema), mode: 'onChange'});

    const [ existedUuid, setExistedUuid ] = useState(true);
    const [ showPassword, setShowPassword ] = useState(false);
    const [ showPasswordConfirm, setShowPasswordConfirm ] = useState(false);

    // lifecycle
    useEffect(() => {
        uuid && onVerifyUuid();
    }, [])

    // function
    const onVerifyUuid = async () => {
        const res = await verifyUuid({uuid: uuid ?? ''});
        setExistedUuid(res);
    }

    const onSetPassword = async (data) => {
        const { password } = data;
        const res = await setPassword({ uuid, password });
        if(res) {
            show({
                id: 'modal-alert',
                isOpen: true,
                notButtonX: true,
                header: null,
                onCancel: () => { hide() },
                children: (
                    <div className='text-center pd-10'>
                        <div className='font-bold max-width-960 max-heigh-240'>
                            <i className='fa-regular fa-circle-check fs-cs-70 text-color-cs-green mg-t-20 mg-b-20'></i>
                            <div style={{ 'whiteSpace': 'pre-line' }}>パスワード再設定が完了しました.</div>
                            <ModalFooter key={'modal-footer'}
                                saveButtonText='ログイン'
                                onConfirm={() => {
                                    hide();
                                    navigate(SYSTEM_PATH.LOGIN);
                                }}
                                saveButtonClass='btn-bg-cs-1'
                            />
                        </div>
                    </div>
                ),
                type: 'medium'
            })
        }
    }

    const clickLoginButton = () => {
        navigate(SYSTEM_PATH.LOGIN);
    }

    if(!existedUuid || !uuid) {
        return (
            <div className='text-center mg-t-50'>
                <NotFoundScreen/>
            </div>
        )
        
    }

    return(
        <div className='set-password-screen vw-100 vh-100 d-flex justify-content-center align-items-center'>
            <div className='container-form width-500 border shadow-box p-3 pt-4 bg-body radius-div'>
                <div className='text-center fs-cs-40 fw-bolder text-color-cs-1'>
                    RAISE管理画面
                </div>
                <h1 className='text-center mg-t-20 fw-bold fs-cs-35'>パスワード再設定</h1>
                <form onSubmit={handleSubmit(onSetPassword)} className='mg-lr-15 mg-t-30 mg-b-30'>
                    <div>
                        <label htmlFor='password' className='field-required col-12 fw-bold'>新しいパスワード</label>
                        <input {...register('password', {
                            onChange: () => {
                                getValues('passwordConfirm') && trigger('passwordConfirm')
                            }
                        })} id='password' 
                        tabIndex={1}
                        type={!showPassword ? 'password' : 'text'} 
                        className='mg-t-10 col-12'/>
                        <button type='button' className='btn-icon-input' onClick={() => setShowPassword(!showPassword)}>
                            <i className={classNames(showPassword ? 'fas fa-eye' : 'fas fa-eye-slash')}/>
                        </button>
                        {
                            errors?.password && errors?.password?.message.split('\n').map((line, index) => (
                                <div className='text-danger mg-t-5 fs-error' key={index}>{line}</div>
                            ))
                        }
                    </div>
                    <div className='mg-t-30'>
                        <label htmlFor='password' className='field-required col-12 fw-bold'>新しいパスワードを再入力</label>
                        <input {...register('passwordConfirm', {
                            onChange: () => {
                                getValues('password') && trigger('password')
                            }
                        })} id='passwordConfirm' 
                        tabIndex={2}
                        type={!showPasswordConfirm ? 'password' : 'text'} 
                        className='mg-t-10 col-12' autoComplete='off'/>
                        <button type='button' className='btn-icon-input' onClick={() => setShowPasswordConfirm(!showPasswordConfirm)}>
                            <i className={classNames(showPasswordConfirm ? 'fas fa-eye' : 'fas fa-eye-slash')}/>
                        </button>
                        {
                            errors?.passwordConfirm && errors?.passwordConfirm?.message.split('\n').map((line, index) => (
                                <div className='text-danger mg-t-5 fs-error' key={index}>{line}</div>
                            ))
                        }
                    </div>
                    <div className='text-center'>
                        <button type='submit' className='btn btn-bg-cs-1 width-200 mg-t-30' 
                            disabled={isSubmitting}>パスワード保存</button>
                    </div>
                </form>
            </div>
        </div>
    )
})

export default SetPasswordScreen;