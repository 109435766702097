import { yupResolver } from '@hookform/resolvers/yup';
import { observer } from 'mobx-react';
import { useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { MSG, ROLE, SYSTEM_PATH } from '../../core/configs/constants';
import { useStore } from '../../core/utils/hook';
import yup from '../../core/utils/yupValidate';
import classNames from 'classnames';
import ReCaptchaV2 from '../../components/reCaptchaV2/ReCaptchaV2';

const LoginScreen = observer(() => {

    // other
    const navigate = useNavigate();
    const recaptcha = useRef();

    // store
    const { 
        authStore: { token, login }
    } = useStore();

    /// state
    const [ showPassword, setShowPassword ] = useState(false);
    const [ failedLogin, setFailedLogin ] = useState(localStorage.getItem('failedLogin') || 0);
    const [ isClickedCaptcha, setIsClickedCaptcha ] = useState(false);

    const validateLoginSchema = yup.object().shape({
        email: yup
            .string()
            .email(MSG['error.email_format'])
            .required(MSG['error.required']),
        password: yup
            .string()
            .required(MSG['error.required'])
    })
    const { register, handleSubmit, formState: { errors, isSubmitting } } = useForm({resolver: yupResolver(validateLoginSchema), mode: 'onChange'});

    // function
    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    }
    
    const onLogin = async (data) => {
        const captchaValue = recaptcha?.current?.getValue();
        const payload = { ...data, captchaValue };
        const res = await login(payload);
        if(res) {
            setFailedLogin(0);
            localStorage.setItem('failedLogin', 0);
            if(res?.role !== ROLE.STAFF.key) {
                navigate(SYSTEM_PATH.DASHBOARD);
            } else {
                navigate(SYSTEM_PATH.USER_MANAGEMENT);
            }
        } else {
            setFailedLogin(Number(failedLogin) + 1);
            localStorage.setItem('failedLogin', Number(failedLogin) + 1 );
            if (failedLogin >= 5) {
                window.grecaptcha.reset();
                setIsClickedCaptcha(false)
            }
        }
    }

    const onReCaptchaChange = (captcha) => {
        if (captcha) {
            setIsClickedCaptcha(true);
        }
    }

    if(token) {
        return <Navigate to={SYSTEM_PATH.DASHBOARD}/>;
    }

    return(
        <div className='login-screen vw-100 vh-100 d-flex justify-content-center align-items-center'>
            <div className='container-form width-500 border shadow-box p-3 pt-4 bg-body radius-div'>
                <div className='text-center fs-cs-40 fw-bolder text-color-cs-1'>
                    RAISE管理画面
                </div>
                <form onSubmit={handleSubmit(onLogin)} className='mg-lr-15 mg-t-20 mg-b-30'>
                    <div>
                        <label htmlFor='email' className='field-required col-12 fw-bold'>メールアドレス</label>
                        <input {...register('email')} id='email' type={'text'} className='mg-t-10 col-12'/>
                        <div className='btn-icon-input'>
                            <i className='fa-regular fa-envelope'></i>
                        </div>
                        {
                            errors?.email &&
                            <div className='text-danger mg-t-5 fs-error'>{errors.email?.message}</div>
                        }
                    </div>
                    <div className='mg-t-30'>
                        <label htmlFor='password' className='field-required col-12 fw-bold'>パスワード</label>
                        <input {...register('password')} id='password' type={!showPassword ? 'password' : 'text'} 
                            className='mg-t-10 col-12' autoComplete='off'/>
                        <button type='button' className='btn-icon-input' onClick={toggleShowPassword}>
                            <i className={classNames(showPassword ? 'fas fa-eye' : 'fas fa-eye-slash')}/>
                        </button>
                        {
                            errors?.password &&
                            <div className='text-danger mg-t-5 fs-error'>{errors.password?.message}</div>
                        }
                    </div>
                    <div className='text-end mg-t-15'>
                        <Link to={SYSTEM_PATH.FORGET_PASSWORD} className='hover-underline'>パスワードを忘れた方はこちら</Link>
                    </div>
                    {
                        failedLogin >= 5 && (
                            <ReCaptchaV2
                                className={'mg-t-30 d-flex justify-content-center'}
                                ref={recaptcha}
                                onChange={onReCaptchaChange}
                            />
                        )
                    }
                    <div className='text-center'>
                        <button type='submit' className='btn btn-bg-cs-1 width-200 mg-t-30' 
                            disabled={isSubmitting || (failedLogin >= 5 && !isClickedCaptcha)}>ログイン</button>
                    </div>
                </form>
            </div>
        </div>
    )
})

export default LoginScreen;