/* eslint-disable no-useless-escape */
import * as yup from 'yup';

yup.addMethod(yup.string, 'noSpecialCharacter', function(message, mapper = a => a) {
    return this.test('noSpecialCharacter', message, function(str) {
        const format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
        if(format.test(str)){
            return false;
        } else {
            return true;
        }
    });
});

yup.addMethod(yup.string, 'isValidUrl', function(message, mapper = a => a) {
    return this.test('isValidUrl', message, function(str) {
        if(!str) return true;
        try {
            new URL(str);
        } catch (error) {
            return false;
        }
        return true;
    })
})

yup.addMethod(yup.number, 'checkEqualValue', function(compareValue, message) {
    return this.test('checkEqualValue', message, function(num) {
        if(num !== compareValue) return false;
        return true;
    })
})

yup.addMethod(yup.string, 'checkInputOnlyContainDigitsOrEmpty', function (message) {
    return this.test('checkInputOnlyContainDigitsOrEmpty', message, function(str) {
        const format = /^[0-9]*$/;
        return format.test(str) || str === null;
    });
})

yup.addMethod(yup.mixed, 'checkFileImage', function (message) {
    return this.test('checkFileImage', message, function (value) {
        if (!value) {
            return true;
        }
        let extFile = '';
        if (value instanceof File) {
            value = value.name;
        }

        if (value instanceof FileList && value.length > 0) {
            value = value[0].name;
        }

        if(value instanceof String || typeof value === 'string') {
            extFile = value.split('.').pop().toLowerCase();
        }

        const allowsExtensions = ['jpg','png','jpeg'];
        return allowsExtensions.includes(extFile);
    })
})

yup.addMethod(yup.mixed, 'checkFileImagesOfArray', function (message) {
    return this.test('checkFileImagesOfArray', message, function (files) {
        if (!files || !(files instanceof Array)) {
            return true;
        }
        let extFiles = [];
        files.forEach(file => {
            if (file instanceof File) {
                file = file.name;
            }

            if(file instanceof String || typeof file === 'string') {
                extFiles.push(file.split('.').pop().toLowerCase());
            }
        })

        const allowsExtensions = ['jpg','png','jpeg'];
        return extFiles.every(extFile => allowsExtensions.includes(extFile));
    })
})

export default yup;