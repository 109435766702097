import { observer } from 'mobx-react';
import { useStore } from '../../core/utils/hook';
import { ROLE, SCREEN_MODE, STATUS_CARD, TAB_TITLE } from '../../core/configs/constants';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import CardsPurchased from './components/CardsPurchased';
import SettingUserScreen from './SettingUserScreen';
import { toJS } from 'mobx';


const ViewUserAndCardpurchase = observer((props) => {


    // others
    const navigate = useNavigate();
    const { id } = useParams();
    const { mode } = props;


    // store
    const {

        userStore: { listCardbuy, listCardBuyOfUser, getUser, user, updateUser, setAttrObservable, paging }
    } = useStore();


    //lifecycle
    useEffect(() => {
        const getData = async () => {
            if (id && mode === SCREEN_MODE.EDIT) {
                await getUser(id);
                await listCardbuy(id)
            }
        }
        getData();

        return () => {
            setAttrObservable('user', {})
        }
    }, [])

    //function

    const handleTabChange = async (index) => {
        if (index === 0) {
            await getUser(id);
        }

        if (index === 1) {
            return await listCardbuy(id);
        }
    }

    return (
        <div className='view-card-screen'>
            <div className='container-title'>ユーザーの詳細</div>

            <div className='container-content'>
                {user && user?.role === ROLE.MEMBER.key ? <Tabs onSelect={handleTabChange} defaultIndex={0} className='tabs-container'>
                    <TabList className='row mg-lr-0 list-unstyled tab-list'>
                        <Tab className='col-6 tab'>{TAB_TITLE.EDIT_USER}</Tab>
                        <Tab className='col-6 tab'>{TAB_TITLE.CARD_PURCHASE} ({paging?.totalRecord || 0})</Tab>
                    </TabList>
                    <TabPanel>
                        <SettingUserScreen id={id} mode={mode} />
                    </TabPanel>
                    <TabPanel>
                        <CardsPurchased id={id} listCardBuyOfUser={listCardBuyOfUser?.cards?.elements || []} />
                    </TabPanel>
                </Tabs> : <SettingUserScreen id={id} mode={mode} />}

            </div>
        </div>
    )
})

export default ViewUserAndCardpurchase;