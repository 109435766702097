import moment from 'moment';

export const getStringAfterLastSlash = (str = '', char = '.', removeQuery = false) => {
    if (!str) return '';
    const n = str.lastIndexOf(char);
    let converted = str.substring(n + 1);
    if (removeQuery && converted.includes('?')) {
        converted = converted.split('?')[0] || ''
    }
    return converted;
};

export const customToolBarEditor = ['bold', 'italic', 'strikethrough', 'underline', '|',
    'fontfamily', 'fontsize', 'fontColor', 'fontBackgroundColor', '|',
    'alignment', 'outdent', 'indent', '|', 'bulletedList', 'numberedList', '|',
    'link', '|', 'undo', 'redo'];

export const customFullToolBarEditor = [
    'heading',
    '|',
    'bold', 'italic', 'underline', 'superscript', 'subscript',
    '|',
    'fontFamily', 'fontSize', 'fontBackgroundColor', 'fontColor', 'highlight',
    '|',
    'alignment', 'indent', 'outdent',
    '|',
    'numberedList', 'bulletedList', 'todoList', 'insertTable',
    '|',
    'blockQuote', 'code', 'codeBlock', 'specialCharacters', 'horizontalLine',
    '|',
    'link', 'imageUpload', 'imageInsert', 'mediaEmbed',
    '|',
    'findAndReplace',
    '|',
    'undo',
    'redo'
]

export const customEditEmailToolBarEditor = [
    'bold', 'italic', 'underline',
    '|',
    'fontSize', 'fontBackgroundColor', 'fontColor', 'highlight', 'alignment',
    '|',
    'numberedList', 'bulletedList', 'todoList', 'link', 
    '|',
    'findAndReplace',
    '|',
    'undo',
    'redo'
]

export const isNumeric = (value) => {
    return /^\d+$/.test(value);
}

export const countCharacterOfNumber = (num) => {
    if (!num || !isNumeric(num)) return 0;

    return String(num).length;
}

// regex text
export const furiganaRegex = /^(([ァ-ン]|ー)|[0-9])*$/;
export const passwordRegex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@#$%^&+=!]).{8,}$/;

// handle html encoding
export const handleStringHtmlEncode = (str) => {
    str = String(str).replace(/&nbsp;/g, '').replace(/&lt;%= /g, '<%= ').replace(/ %&gt;/g, ' %>')
        .replace(/&lt;%_ /g, '<%_ ').replace(/&lt;%- /g, '<%- ').replace(/&lt;%# /g, '<%# ').replace(/&lt;%% /g, '<%% ')
        .replace(/&lt;% /g, '<% ').replace(/ -%&gt;/g, ' -%>').replace(/ _%&gt;/g, ' _%>')

    return str;
}

export const calculateNextDateAfterMonth = (startDate, months = 1) => {
    return moment(startDate).add(months, 'M');
}

