/* eslint-disable max-len */
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { ReactNotifications, Table } from '../../components';
import { FORMAT_DATE, FORMAT_DATE_TIME, MSG, TEXT, TOOLTIP, CONTRACT_STATUS, REDUND_STATUS } from '../../core/configs/constants';
import { useStore } from '../../core/utils/hook';
import { useForm } from 'react-hook-form';
import moment from 'moment';
import { Link} from 'react-router-dom';

const SubscriptionContract = observer((props) => {
    //others
    //store
    const {
        subscriptionContractStore: { getAllContract, contractList, clean, deleteContract, setAttrObservable, paging },
        modalStore: {hide, show, openWarningModal, hideAll}
    } = useStore()
    //state
    const {register, getValues, handleSubmit } = useForm();
    //lifecycle
    useEffect(() => {
        onSearch();

        return () => {
            clean()
            hideAll()
        }
    }, [])

    //modal
    //function

    const onSearch = (data) => {
        clean();
        onFetchData(data);
    }
    const onFetchData = (tableState) => {
        setAttrObservable('paging', tableState, true, false);
        getAllContract({
            ...getValues(),
            ...tableState
        })
    }
    //columns
    const columns = [
        {
            Header: 'No.',
            accessor: 'id',
            disableSortBy: true,
            Cell: ({ row: { original, index }, initialState }) => {
                return <div>{initialState?.initialTableState?.pageSize * initialState?.initialTableState?.pageIndex + index + 1}</div>
            },
            width: '5%'
        },
        {
            Header: '契約ID',
            accessor: 'contract_code',
            disableSortBy: true,
            Cell: ({ row: { original } }) => {
                return <div>{original?.code}</div>
            },
            with: '10%'
        },
        {
            Header: 'ユーザーID',
            accessor: 'user_code',
            disableSortBy: true,
            Cell: ({ row: { original } }) => {
                return <div><Link to={`/user/${original?.account?.user?.id}`}>{original?.account?.user?.code}</Link></div>
            },
            with: '15%'
        },
        {
            Header: 'アカウントID',
            accessor: 'account_code',
            disableSortBy: true,
            Cell: ({ row: { original } }) => {
                return <div>
                    <Link to={`/user/account/${original?.account?.id}`}>{original?.account?.code}</Link></div>
            },
            with: '15%'
        },
        {
            Header: 'ユーザーネーム',
            accessor: 'user_name',
            disableSortBy: true,
            Cell: ({ row: { original } }) => {
                return <div>{original?.account?.userName}</div>
            },
            with: '10%'
        },
        {
            Header: '名前-本名',
            accessor: 'full_name',
            disableSortBy: true,
            Cell: ({ row: { original } }) => {
                return <div>{original?.account?.user?.fullName}</div>
            },
            with: '10%'
        },
        {
            Header: '作成日',
            accessor: 'created_at',
            disableSortBy: true,
            Cell: ({ row: { original } }) => {
                return (
                    <div>
                        {original?.createdAt ? moment(new Date(original?.createdAt)).format(FORMAT_DATE_TIME) : ''}
                    </div>
                );
            },
            width: '10%'
        },
        {
            Header: '最新更新日',
            accessor: 'updated_at',
            disableSortBy: true,
            Cell: ({ row: { original } }) => {
                return (
                    <div>
                        {original?.updatedAt ? moment(new Date(original?.updatedAt)).format(FORMAT_DATE_TIME) : ''}
                    </div>
                );
            },
            width: '10%'
        },
        {
            Header: '契約ステータス',
            accessor: 'contract_status',
            disableSortBy: true,
            Cell: ({ row: { original } }) => {
                const statusKey = Object.keys(CONTRACT_STATUS).filter(e => CONTRACT_STATUS[e].key === original?.contractStatus)?.[0] ?? null;

                return (
                    <div style={{'color': CONTRACT_STATUS[statusKey]?.color}}>
                        { statusKey && CONTRACT_STATUS[statusKey]?.label }
                    </div>
                );
            },
            width: '10%'
        },
        {
            Header: '返金ステータス',
            accessor: 'refund_status',
            disableSortBy: true,
            Cell: ({ row: { original } }) => {
                const statusKey = Object.keys(REDUND_STATUS).filter(e => REDUND_STATUS[e].key === original?.refundStatus)?.[0] ?? 'NONE';

                return (
                    <div style={{'color': REDUND_STATUS[statusKey]?.color}}>
                        { statusKey && REDUND_STATUS[statusKey]?.label }
                    </div>
                );
            },
            width: '8%'
        },
        {
            Header: 'アクション',
            accessor: '',
            disableSortBy: true,
            Cell: ({row: {original}}) => {
                return (
                    <div className='d-flex align-items-center flex-gap-10 justify-content-center flex-wrap'>
                        <button type='button'
                            data-bs-toggle='tooltip'
                            title={TOOLTIP.VIEW_DETAILS}
                            className='btn-icon btn-icon-orange'
                        ><a href={'/subscription-contract/' + original?.id} target='_blank'><i className="fa-solid fa-eye text-color-cs-1"></i></a></button>  
                    </div>
                )
            },
            width: '5%'
        }
    ]

    return (<div className='contract-screen'>
        <div className='container-title'>サブスク契約管理</div>
        <div className='container-search'>
            <form onSubmit={handleSubmit(onSearch)}>
                <div className='row'>
                    <div className='d-flex col-6 align-items-center'>
                        <label className='col-3'>契約ID</label>
                        <input {...register('contractCode')} className='col-8' />
                    </div>
                    <div className='d-flex col-6 align-items-center justify-content-end'>
                        <label className='col-3'>ユーザーID</label>
                        <input {...register('userCode')} className='col-8' />
                        
                    </div>
                </div>
                <div className='row mg-t-10'>
                    <div className='d-flex col-6 align-items-center'>
                        <label className='col-3'>アカウントID</label>
                        <input {...register('accountCode')} className='col-8' />
                    </div>
                    <div className='d-flex col-6 align-items-center justify-content-end'>
                        <label className='col-3'>名前-本名</label>
                        <input {...register('fullName')} className='col-8' />
                    </div>
                </div>
                <div className='row mg-t-10'>
                    <div className='d-flex col-6 align-items-center'>
                        <label className='col-3'>契約ステータス</label>
                        <select {...register('contractStatus')} className='col-8'>
                            <option value={''}>すべて</option>
                            {
                                Object.keys(CONTRACT_STATUS).map(e =>
                                    <option key={CONTRACT_STATUS[e].key} value={CONTRACT_STATUS[e].key} >{CONTRACT_STATUS[e].label}</option>)
                            }
                        </select>
                    </div>
                    <div className='d-flex col-6 align-items-center justify-content-end'>
                        <label className='col-3'>返金ステータス</label>
                        <select {...register('refundStatus')} className='col-8'>
                            <option value={''}>すべて</option>
                            {
                                Object.keys(REDUND_STATUS).map(e =>
                                    REDUND_STATUS[e].key !== REDUND_STATUS.NONE.key && <option key={REDUND_STATUS[e].key} value={REDUND_STATUS[e].key} >{REDUND_STATUS[e].label}</option>)
                            }
                        </select>
                    </div>
                </div>
                <div className='text-center mg-t-35'>
                    <button type='submit' className='btn-default btn-search-back'>
                        <i className='fa-solid fa-magnifying-glass'></i>
                        <span className='mg-l-10'>{TEXT.SEARCH}</span>
                    </button>
                </div>
            </form>
        </div>
        <div className='container-content'>
            <Table
                columns={columns}
                data={contractList}
                disablepaging={false}
                enableServerSidePaging={true}
                initialTableState={paging}
                onFetch={onFetchData}
                className='lst-contract-table'
            />
        </div>
    </div>)
})

export default SubscriptionContract;