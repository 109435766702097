import { observer } from 'mobx-react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import yup from '../../core/utils/yupValidate';
import { useStore } from '../../core/utils/hook';
import { MSG, SCREEN_MODE, STATUS_COMMON, TEXT } from '../../core/configs/constants';
import { useEffect } from 'react';
import { ReactNotifications } from '../../components';
import { loadFilePreview, loadURLPreview } from '../../core/utils/browser';

import './style.scss';

const SettingCardTypeScreen = observer((props) => {

    // props
    const { mode, id, onSearch } = props;

    // store
    const {
        cardTypeStore: { createCardType, clean, getCardType, cardType, setAttrObservable, updateCardType },
        modalStore: { hide }
    } = useStore();

    // state
    const validateSettingSchema = yup.object().shape({
        name: yup.string().trim().max(100, MSG['error.string_max_length'](100)).required(MSG['error.required']),
        images: yup.array().min(1, MSG['error.required']).required(MSG['error.required']).checkFileImagesOfArray(MSG['error.image_format']),
        note: yup.string().trim().nullable(),
        status: yup.number().required(MSG['error.required'])
    })

    const { 
        register, 
        handleSubmit, 
        formState: { errors, isSubmitting }, 
        reset,
        setError,
        watch,
        setValue
    } = useForm({ resolver: yupResolver(validateSettingSchema), mode: 'onChange', defaultValues: {
        status: STATUS_COMMON.INACTIVE.key.toString()
    } });

    const watchImages = watch('images');

    // lifecycle
    useEffect(() => {
        return () => {
            setAttrObservable('cardType', null);
        }
    }, [])

    useEffect(() => {
        const getData = async () => {
            await getCardType(id);
        }

        if(mode === SCREEN_MODE.EDIT) {
            getData();
        }
    }, [mode])

    useEffect(() => {
        if(cardType?.id) {
            const { name, images, note, status } = cardType;
            reset({ name, images, note, status: String(status) });
        }
    }, [cardType])

    useEffect(() => {
        if(watchImages?.length > 0) {
            if(watchImages instanceof Array) {
                watchImages.forEach((image, index) => {
                    if(image instanceof File) {
                        loadFilePreview(image, `preview-image-${index}`);
                    } else {
                        loadURLPreview(image, `preview-image-${index}`);
                    }
                })
            }
        }
    }, [watchImages])

    // function
    const onSubmitSetting = async (data) => {
        const { name, images, note, status } = data;
        let res;
        if (mode === SCREEN_MODE.ADD) {
            res = await createCardType({ name, imageFiles: images, note, status });
        } else if (mode === SCREEN_MODE.EDIT) {
            let imageStrs = [], imageFiles = [];
            if(images?.length > 0) {
                for (const image of images) {
                    if(image instanceof File) {
                        imageFiles.push(image);
                    } else {
                        imageStrs.push(image);
                    }
                }
            }
            res = await updateCardType(id, { 
                name, 
                images: imageStrs,
                imageFiles, 
                note, 
                status 
            });
        }

        if (res) {
            ReactNotifications('success', mode === 0 ? MSG['inform.success.create'] : MSG['inform.success.update']);
            if(mode === SCREEN_MODE.ADD) {
                clean();
            }
            onSearch && await onSearch();
            hide();
        }
    }

    const onConvertFileListToArray = (e) => {
        const files = e.target.files;
        setValue('images', [...(watchImages || []), ...(files instanceof FileList ? Array.from(files).map(e => e) : [])], { shouldValidate: true });
        e.target.value = null;
    }

    const onRemoveImage = (index) => {
        if(watchImages?.length > 0) {
            setValue('images', [
                ...watchImages.slice(0, index),
                ...watchImages.slice(index + 1)
            ], { shouldValidate: true })
        }
    }

    return(
        <div className='setting-card-type-screen max-height-modal pd-30'>
            <form onSubmit={handleSubmit(onSubmitSetting)}>
                <div className='row align-items-center'>
                    <label className='field-required col-4'>カード種類の名称</label>
                    <div className='col-8 pd-0'>
                        <input {...register('name')} className='w-100' max={100}/>
                        {
                            errors.name &&
                            <div className='text-danger fs-error mg-t-5 pd-0'>{errors.name?.message}</div>
                        }
                    </div>
                </div>
                <div className='row mg-t-20'>
                    <label className='field-required col-4'>デフォルト画像</label>
                    <div className='col-8 pd-0'>
                        <div className='d-flex flex-gap-20 flex-wrap'>
                            {
                                watchImages?.length > 0 &&
                                Array.from(watchImages).map((_, index) => (
                                    <div className='position-relative' key={index}>
                                        <img id={`preview-image-${index}`} className='width-120 height-155'/>
                                        <i onClick={() => onRemoveImage(index)} role='button'
                                            className='fa fa-light fa-circle-xmark position-absolute' />
                                    </div>
                                ))
                            }
                            <label htmlFor='images' className='input-card-select-file'>
                                <i className='fa-solid fa-plus'/>
                                <span>画像を選択</span>
                            </label>
                            <input 
                                id='images' 
                                type={'file'} 
                                onChange={onConvertFileListToArray}
                                accept='image/png, image/jpeg' 
                                hidden multiple/>
                        </div>
                        {
                            errors.images &&
                            <div className='text-danger fs-error mg-t-5 pd-0'>{errors.images?.message}</div>
                        }
                    </div>
                </div>
                <div className='row align-items-center mg-t-20'>
                    <label className='col-4'>ステータス</label>
                    <div className='row col-8'>
                        <div className='row col-6 align-items-center'>
                            <input {...register('status')} className='w-auto' type={'radio'} id='active' value={STATUS_COMMON.ACTIVE.key}/>
                            <label className='w-auto mg-l-5 pd-0' htmlFor='active'>{STATUS_COMMON.ACTIVE.label}</label>
                        </div>
                        <div className='row col-6 align-items-center'>
                            <input {...register('status')} className='w-auto' type={'radio'} id='inactive' value={STATUS_COMMON.INACTIVE.key}/>
                            <label className='w-auto mg-l-5 pd-0' htmlFor='inactive'>{STATUS_COMMON.INACTIVE.label}</label>
                        </div>
                        {
                            errors.status &&
                            <div className='text-danger fs-error mg-t-5 pd-0'>{errors.status?.message}</div>
                        }
                    </div>
                </div>
                <div className='row mg-t-20'>
                    <label className='col-4'>メモ</label>
                    <div className='col-8 pd-0'>
                        <textarea {...register('note')} rows={4} className='w-100 resize-none'/>
                        {
                            errors.note &&
                            <div className='text-danger fs-error mg-t-5 pd-0'>{errors.note?.message}</div>
                        }
                    </div>
                </div>
                <div className='d-flex justify-content-center align-items-center mg-t-40'>
                    <button type='button' className='btn btn-cs-3 btn-default btn-cancel width-150' onClick={hide}>{TEXT.CANCEL}</button>
                    <button 
                        type='submit' 
                        className='btn-default btn-bg-cs-1 width-150 mg-l-50' 
                        disabled={isSubmitting}>保存</button>
                </div>
            </form>
        </div>
    )
})

export default SettingCardTypeScreen;