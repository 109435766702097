import { useStore } from '../../core/utils/hook';
import { AIRDROP_STATUS, DETAIL_PAYMENT_HEADER, FORMAT_DATE_TIME, MSG, PURCHASE_STATUS, SYSTEM_PATH, TEXT } from '../../core/configs/constants';
import { FormatNumber, ReactNotifications } from '../../components';
import classNames from 'classnames';
import moment from 'moment';
import { useForm } from 'react-hook-form';
import yup from '../../core/utils/yupValidate';
import { yupResolver } from '@hookform/resolvers/yup';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useEffect, useMemo } from 'react';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';

const BuyCardDetailScreen = observer(() => {
    // other
    const navigate = useNavigate();
    const { id } = useParams();

    // store
    const { paymentStore: { transactionDetail, getDetailsTransaction, updateAIRDropStatus }} = useStore();

    // state
    const validateCardDetailPaymentSchema = yup.object().shape({
        airdropStatus: yup
            .number()
    })
    const { 
        register, 
        handleSubmit, 
        formState: { errors, isSubmitting }, 
        getValues, 
        setValue
    } = useForm({ resolver: yupResolver(validateCardDetailPaymentSchema), mode: 'onChange' });

    // eslint-disable-next-line max-len
    const findStatus = useMemo(() => Object.keys(PURCHASE_STATUS).filter(key => PURCHASE_STATUS[key].key === transactionDetail?.status)?.[0], [transactionDetail]);

    // life cycle
    useEffect(() => {
        const onFetchData = async () => {
            if (id) {
                await getDetailsTransaction(id);
            }
        }
        onFetchData();
    }, [id])

    useEffect(() => {
        if(transactionDetail?.id) {
            setValue('airdropStatus', transactionDetail?.airdropStatus);
        }
    }, [transactionDetail])

    // function
    const onClickCopyButton = async (value, text = '') => {
        try {
            await navigator.clipboard.writeText(value);
            text && ReactNotifications('success', text);
        } catch (error) {
            console.log(error);
        }
    }

    const onSubmit = async(data) => {
        const res = await updateAIRDropStatus( id, data );
        if (res) {
            ReactNotifications('success', MSG['inform.success.update']);
            navigate(SYSTEM_PATH.CARD_PAYMENT_MANAGEMENT);
        }
    }
    
    return (
        <div>
            <div className='container-title'>{DETAIL_PAYMENT_HEADER}</div>
            <div className='container-content pd-0'>
                <div className='btn-bg-cs-1 pd-10 text-color-cs-white font-bold fs-heading-small rounded-top'>ユーザー情報</div>
                <div className='pd-30 text-color-cs-5'>
                    <div className='row'>
                        <div className='row'>
                            <div className='row col-6 align-items-center'>
                                <span className='col-3 font-label fw-semibold'>ユーザーID</span>
                                <span className='col-9'>
                                    <Link
                                        to={`/user/${transactionDetail?.account?.user?.id}`} 
                                        className='text-color-cs-4 cursor-pointer'
                                    ><u>{transactionDetail?.account?.user?.code}</u></Link>
                                </span>
                            </div>
                            <div className='row col-6 align-items-center justify-content-end'>
                                <span className='col-3-5 fw-semibold'>名前-本名</span>
                                <span className='col-6'>{transactionDetail?.account?.user?.fullName}</span>
                            </div>
                        </div>
                        <div className='row mg-t-16'>
                            <div className='row col-6 align-items-center'>
                                <span className='col-3 fw-semibold'>メールアドレス</span>
                                <span className='col-9'>{transactionDetail?.account?.user?.email}</span>
                            </div>
                            <div className='row col-6 align-items-center justify-content-end'>
                                <span className='col-3-5 fw-semibold'>電話番号</span>
                                <span className='col-6'>{transactionDetail?.account?.user?.phoneNumber}</span>
                            </div>
                        </div>
                        <div className='row mg-t-16'>
                            <div className='row col-6 align-items-center'>
                                <span className='col-3 fw-semibold'>Drop先アドレス</span>
                                <span className='col-9'>
                                    {transactionDetail?.dropAddress}
                                    {transactionDetail?.dropAddress && (
                                        <span
                                            role='button'
                                            onClick={() => onClickCopyButton(transactionDetail?.dropAddress, MSG['inform.dropAddress.clipboard'])}
                                            className='mg-l-5'>
                                            <i className='fa-regular fa-copy'></i>
                                        </span>
                                    )}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='container-content pd-0 mg-t-30'>
                <div className='btn-bg-cs-1 pd-10 text-color-cs-white font-bold fs-heading-small rounded-top'>取引情報</div>
                <form onSubmit={handleSubmit(onSubmit)} className='pd-30 text-color-cs-5'>
                    <div className='row'>
                        <div className='row'>
                            <div className='row col-6 align-items-center'>
                                <span className='col-3 font-label fw-semibold'>取引ID</span>
                                <span className='col-9'>
                                    <span className='font-bold fs-label'>{transactionDetail?.code}</span>
                                </span>
                            </div>
                        </div>
                        <div className='row mg-t-16'>
                            <div className='row col-6 align-items-center'>
                                <span className='col-3 fw-semibold'>作成日</span>
                                <span className='col-9'>{moment(transactionDetail?.createdAt).format(FORMAT_DATE_TIME)}</span>
                            </div>
                            <div className='row col-6 align-items-center justify-content-end'>
                                <span className='col-3-5 fw-semibold'>カード数</span>
                                <span className='col-6'>{transactionDetail?.cards?.length}</span>
                            </div>
                        </div>
                        <div className='row mg-t-16'>
                            <div className='row col-6 align-items-center'>
                                <span className='col-3 fw-semibold'>総合計</span>
                                <span className='col-9 text-color-cs-red'>
                                    <FormatNumber value={transactionDetail?.totalPayment} suffix=' 円' />
                                </span>
                            </div>
                            <div className='row col-6 align-items-center justify-content-end'>
                                <span className='col-3-5 fw-semibold'>Airdrop総量</span>
                                <span className='col-6'>
                                    <FormatNumber value={transactionDetail?.airdrop} suffix=' SML' />
                                    {
                                        transactionDetail?.airdrop && (
                                            <span
                                                role='button'
                                                onClick={() => onClickCopyButton(transactionDetail?.airdrop, MSG['inform.airdrop.clipboard'])}
                                                className='mg-l-5'>
                                                <i className='fa-regular fa-copy'></i>
                                            </span>
                                        )
                                    }
                                </span>
                            </div>
                        </div>
                        <div className='row mg-t-16'>
                            <div className='row col-6 align-items-center'>
                                <span className='col-3 fw-semibold'>支払日</span>
                                <span className='col-9'>
                                    {transactionDetail?.status ===  PURCHASE_STATUS.SUCCESS.key? (
                                        <div>{moment(transactionDetail?.transactionDate).format(FORMAT_DATE_TIME)}</div>
                                    ): (
                                        <div></div>
                                    )}
                                </span>
                            </div>
                            {
                                transactionDetail?.status === PURCHASE_STATUS.SUCCESS.key && (
                                    <div className='row col-6 align-items-center justify-content-end'>
                                        <span className='col-3-5 fw-semibold'>Airdropステータス</span>
                                        <select 
                                            className='col-6' 
                                            {...register('airdropStatus')}>
                                            <option 
                                                key={AIRDROP_STATUS.WAITING_PAYMENT.key} 
                                                value={AIRDROP_STATUS.WAITING_PAYMENT.key}>{AIRDROP_STATUS.WAITING_PAYMENT.label}</option>
                                            <option 
                                                key={AIRDROP_STATUS.COMPLETED_PAYMENT.key} 
                                                value={AIRDROP_STATUS.COMPLETED_PAYMENT.key}>{AIRDROP_STATUS.COMPLETED_PAYMENT.label}</option>
                                        </select>
                                    </div>
                                )
                            }
                        </div>
                        <div className='row mg-t-16'>
                            <div className='row col-6 align-items-center'>
                                <span className='col-3 fw-semibold'>支払いステータス</span>
                                {
                                    findStatus && <span className={classNames('col-9 font-bold', PURCHASE_STATUS[findStatus]?.colorClassName ?? '')}>
                                        {PURCHASE_STATUS[findStatus]?.label}
                                    </span>
                                }
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className='text-color-cs-5 font-bold fs-label border-bottom-think-color-cs-1 mg-t-30'>購入したカードの一覧</div>
                        {
                            transactionDetail?.cards?.map((item, index) => (
                                <div 
                                    key={index} 
                                    className={classNames('mg-t-30 pd-b-20', 
                                        index !== transactionDetail?.cards?.length-1 ? 'border-bottom-thin-color-cs-6' : '')}>
                                    <div className='row '>
                                        <div className='col-2'>
                                            <img 
                                                className='width-130'
                                                src={item?.image ? 
                                                    item?.image : 
                                                    item?.imageBlock} />
                                        </div>
                                        <div className='col-10 d-flex align-items-center'>
                                            <div className='row align-items-center'>
                                                <div className='row'>
                                                    <div className='row col-6 align-items-center'>
                                                        <span className='col-5 font-label fw-semibold'>カードID</span>
                                                        <span className='col-7'>
                                                            {
                                                                item?.deleteFlag === 0 ? (
                                                                    <span 
                                                                        role='button'
                                                                        className='text-color-cs-4 cursor-pointer'
                                                                    ><u><Link to={`/card/${item?.id}`}>{item?.code}</Link></u></span>
                                                                ) : (
                                                                    <span>{item?.code}</span>
                                                                )
                                                            }
                                                        </span>
                                                    </div>
                                                    <div className='row col-6 align-items-center justify-content-end'>
                                                        <span className='col-5 fw-semibold'>発行日時</span>
                                                        <span className='col-7'>{item?.publicAt}</span>
                                                    </div>
                                                </div>
                                                <div className='row mg-t-16'>
                                                    <div className='row col-6 align-items-center'>
                                                        <span className='col-5 fw-semibold'>基準レート</span>
                                                        <span className='col-7'>
                                                            <FormatNumber value={item?.baseRate} suffix=' USDT'/>
                                                        </span>
                                                    </div>
                                                    <div className='row col-6 align-items-center justify-content-end'>
                                                        <span className='col-5 fw-semibold'>カード価格</span>
                                                        <span className='col-7'>
                                                            <FormatNumber value={item?.cardPrice} suffix=' 円' />
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className='row mg-t-16'>
                                                    <div className='row col-6 align-items-center'>
                                                        <span className='col-5 fw-semibold'>ユーザー購入時レート</span>
                                                        <span className='col-7'>
                                                            <FormatNumber value={item?.currentRate} decimalScale={6} suffix=' USDT'/>
                                                        </span>
                                                    </div>
                                                    <div className='row col-6 align-items-center  justify-content-end'>
                                                        <span className='col-5 fw-semibold'>ユーザー購入時価格</span>
                                                        <span className='col-7'>
                                                            {
                                                                item?.baseRate === 0 ? (
                                                                    '0%'
                                                                ) : (
                                                                    ((item?.currentRate/item?.baseRate)-1)*100 > 0 ? (
                                                                        <FormatNumber 
                                                                            value={((item?.currentRate/item?.baseRate)-1)*100} 
                                                                            prefix='+' 
                                                                            suffix='%' 
                                                                            decimalScale={2} /> 
                                                                    ) : (
                                                                        <FormatNumber 
                                                                            value={((item?.currentRate/item?.baseRate)-1)*100} 
                                                                            prefix='-' 
                                                                            suffix='%' 
                                                                            decimalScale={2} />
                                                                    )
                                                                )
                                                            }
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className='row mg-t-16'>
                                                    <div className='row col-6 align-items-center'>
                                                        <span className='col-5 fw-semibold'>Airdrop</span>
                                                        <span className='col-7'>
                                                            <FormatNumber value={item?.airdrop} suffix=' SML' />    
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                    <div className='d-flex justify-content-center align-items-center mg-t-80'>
                        <button type='button' className='btn-default btn-cancel'
                            onClick={() => navigate(SYSTEM_PATH.CARD_PAYMENT_MANAGEMENT)}>
                            {TEXT.CANCEL}
                        </button>
                        {
                            transactionDetail?.status === PURCHASE_STATUS.SUCCESS.key && (
                                <button type='submit' className='btn-default btn-bg-cs-1 mg-l-50' disabled={isSubmitting}>
                                    {TEXT.UPDATE}
                                </button>
                            )
                        }
                    </div>
                </form>
            </div>
        </div>
    )
})

export default BuyCardDetailScreen;