/* eslint-disable max-len */
import { MSG, USER_STATUS, SYSTEM_PATH, ROLE, TOOLTIP } from '../../../core/configs/constants';


export const createAdminColumn = ({onShowConfirmChangeStatus, onShowConfirmDeleteUser, onEditUser,  userInfo}) => {

    const column =  [
        {
            Header: 'ユーザーID',
            accessor: 'code',
            disableSortBy: true,
            Cell: ({ row: { original } }) => {
                return (
                    <div>
                        {original?.code}
                    </div>
                );
            },
            width: '17.5%'
        },
        {
            Header: 'ユーザーネーム',
            accessor: 'user_name',
            disableSortBy: true,
            Cell: ({ row: { original } }) => {
                return (
                    <div>
                        {original?.userName}
                    </div>
                );
            },
            width: '15%'
        },
        {
            Header: 'メールアドレス',
            accessor: 'email',
            disableSortBy: true,
            Cell: ({ row: { original } }) => {
                return (
                    <div>
                        {original?.email}
                    </div>
                );
            },
            width: '17.5%'
        },
        {
            Header: '電話番号',
            accessor: 'phone_number',
            disableSortBy: true,
            Cell: ({ row: { original } }) => {
                return (
                    <div>
                        {original?.phoneNumber}
                    </div>
                );
            },
            width: '10%'
        },
        {
            Header: '役割',
            accessor: 'role',
            disableSortBy: true,
            Cell: ({ row: { original } }) => {
                const roleKey = Object.keys(ROLE).filter(e => ROLE[e].key === original?.role)?.[0] ?? null;

                return (
                    <div>
                        { roleKey && ROLE[roleKey]?.label }
                    </div>
                );
            },
            width: '10%'
        },
        {
            Header: 'ステータス',
            accessor: 'status',
            disableSortBy: true,
            Cell: ({ row: { original } }) => {
                const statusKey = Object.keys(USER_STATUS).filter(e => USER_STATUS[e].key === original?.status)?.[0] ?? null;

                return (
                    <div style={{'color': USER_STATUS[statusKey]?.color}}>
                        { statusKey && USER_STATUS[statusKey]?.label }
                    </div>
                );
            },
            width: '15%'
        },
        {
            Header: 'アクション',
            accessor: '',
            disableSortBy: true,
            Cell: ({ row: { original } }) => {
                return (
                    <div className='d-flex align-items-center justify-content-center flex-gap-10'>
                        {(original?.status !== USER_STATUS.WAITING_CONFIRM.key && original?.role !== ROLE.SUPPER_ADMIN.key && userInfo?.role !== ROLE.STAFF.key ) ?
                            <button type="button"
                                ata-bs-toggle="tooltip"
                                title={`${original.status === USER_STATUS.INACTIVE.key ? TOOLTIP.ACTIVE : TOOLTIP.INACTIVE}`}
                                className={`btn-icon ${original.status === USER_STATUS.INACTIVE.key ? 'btn-icon-green' : 'btn-icon-red'}`}
                                onClick={() => onShowConfirmChangeStatus(original?.id, 
                                    original.status === USER_STATUS.INACTIVE.key ? USER_STATUS.ACTIVE.key : USER_STATUS.INACTIVE.key)}>
                                {original.status === USER_STATUS.INACTIVE.key ?
                                    <i className="fa-solid fa-check"></i> :
                                    <i className='fa fa-ban'></i>}
                            </button> : <button className='btn-icon invisible'></button>
                        }
                        <button
                            data-bs-toggle="tooltip"
                            title={TOOLTIP.EDIT_USER}
                            type='button'
                            className='btn-icon btn-icon-orange' onClick={() => onEditUser(original?.id)}>
                            <i className='fa-solid fa-pen-to-square'></i>
                        </button>
                        {original?.role == ROLE.SUPPER_ADMIN.key || userInfo?.role == ROLE.STAFF.key || original?.id == userInfo.id ? <button className='btn-icon invisible'></button>
                            : <button
                                data-bs-toggle="tooltip"
                                title={TOOLTIP.DELETE_USER}
                                type='button' className='btn-icon btn-icon-red' onClick={() => onShowConfirmDeleteUser(original?.id)}>
                                <i className='fa-solid fa-trash-can'></i>
                            </button>}
                        
                    </div>
                );
            },
            width: '12.5%'
        }
    ]

    return column;
}

export const createMemberColumn = ({onEditUser, onShowAddAccount, onShowConfirmDeleteUser , userInfo }) => {
    
    const column =  [
        {
            Header: 'ユーザーID',
            accessor: 'code',
            disableSortBy: true,
            Cell: ({ row: { original } }) => {
                return (
                    <div>
                        {original?.code}
                    </div>
                );
            },
            width: '13.5%'
        },
        {
            Header: '名前-本名',
            accessor: 'full_name',
            disableSortBy: true,
            Cell: ({ row: { original } }) => {
                return (
                    <div>
                        {original?.fullName}
                    </div>
                );
            },
            width: '12.5%'
        },
        {
            Header: '名前-フリガナ',
            accessor: 'full_name_furi',
            disableSortBy: true,
            Cell: ({ row: { original } }) => {
                return (
                    <div>
                        {original?.fullNameFuri}
                    </div>
                );
            },
            width: '12.5%'
        },
        {
            Header: 'メールアドレス',
            accessor: 'email',
            disableSortBy: true,
            Cell: ({ row: { original } }) => {
                return (
                    <div>
                        {original?.email}
                    </div>
                );
            },
            width: '16.5%'
        },
        {
            Header: '電話番号',
            accessor: 'phone_number',
            disableSortBy: true,
            Cell: ({ row: { original } }) => {
                return (
                    <div>
                        {original?.phoneNumber}
                    </div>
                );
            },
            width: '10%'
        },
        {
            Header: 'アカウント数',
            accessor: 'number_account',
            disableSortBy: true,
            Cell: ({ row: { original } }) => {
                return (
                    <>
                        {original?.numberAccounts ?
                            <a className='underline-link' href={`/user/account-list/${original.id}`} target='_blank'>{original?.numberAccounts}</a> : <div>0</div>}
                    </>     
                );
            },
            width: '10%'
        },
        {
            Header: 'ステータス',
            accessor: 'status',
            disableSortBy: true,
            Cell: ({ row: { original } }) => {
                const statusKey = Object.keys(USER_STATUS).filter(e => USER_STATUS[e].key === original?.status)?.[0] ?? null;

                return (
                    <div style={{'color': USER_STATUS[statusKey]?.color}}>
                        { statusKey && USER_STATUS[statusKey]?.label }
                    </div>
                );
            },
            width: '10%'
        },
        {
            Header: 'アクション',
            accessor: '',
            disableSortBy: true,
            Cell: ({ row: { original } }) => {
                return (
                    <div className='d-flex align-items-center flex-gap-10 justify-content-center flex-wrap'>
                        {original?.addAccount && userInfo?.role !== ROLE.STAFF.key ? <button
                            data-bs-toggle="tooltip"
                            title={TOOLTIP.ADD_ACCOUNT}
                            type='button'
                            className='btn-icon btn-icon-blue' onClick={() => onShowAddAccount(original?.id)}>
                            <i className='fa-solid fa-user-plus'></i>
                        </button> : <button className='btn-icon invisible'></button> }
                        <button
                            data-bs-toggle="tooltip"
                            title={TOOLTIP.EDIT_USER}
                            type='button'
                            className='btn-icon btn-icon-orange' onClick={() => onEditUser(original?.id)}>
                            <i className='fa-solid fa-pen-to-square'></i>
                        </button>
                        {userInfo?.role !== ROLE.STAFF.key ? <button
                            data-bs-toggle="tooltip"
                            title={TOOLTIP.DELETE_USER}
                            type='button' className='btn-icon btn-icon-red'
                            onClick={() => onShowConfirmDeleteUser(original?.id)}>
                            <i className='fa-solid fa-trash-can'></i>
                        </button> : <button className='btn-icon invisible'></button>}
                    </div>
                );
            },
            width: '12.5%'
        }
    ]
    return column;
}

