import { observer } from 'mobx-react';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { MSG, SYSTEM_PATH } from '../../core/configs/constants';
import { useStore } from '../../core/utils/hook';
import yup from '../../core/utils/yupValidate';
import { yupResolver } from '@hookform/resolvers/yup';
import { ModalFooter } from '../../components';

const ForgetPasswordScreen = observer(props => {

    // other
    const navigate = useNavigate();

    // store
    const { authStore: { forgetPassword }, modalStore: { show, hide } } = useStore();

    // state
    const validateSchema = yup.object().shape({
        email: yup
            .string()
            .required(MSG['error.required'])
            .email(MSG['error.email_format'])
    })
    const { register, handleSubmit, formState: { errors, isSubmitting } } = useForm({ resolver: yupResolver(validateSchema), mode: 'onChange' });

    // function
    const onForgetPassword = async (data) => {
        const res = await forgetPassword(data);
        if(res) {
            show({
                id: 'modal-alert',
                isOpen: true,
                notButtonX: true,
                header: null,
                onCancel: () => { hide() },
                children: (
                    <div className='text-center pd-10'>
                        <div className='font-bold max-width-960 max-heigh-240'>
                            <i className='fa-regular fa-circle-check fs-cs-70 text-color-cs-green mg-t-20 mg-b-20'></i>
                            <div style={{ 'whiteSpace': 'pre-line' }}>{MSG['inform.success.forget_password']}</div>
                            <ModalFooter key={'modal-footer'}
                                saveButtonText='ログイン'
                                onConfirm={() => {
                                    hide();
                                    navigate(SYSTEM_PATH.LOGIN);
                                }}
                                saveButtonClass='btn-bg-cs-1'
                            />
                        </div>
                    </div>
                ),
                type: 'medium'
            })
        }
    }

    return(
        <div className='forget-password-screen'>
            <div className='forgot-password-screen w-100 d-flex justify-content-center pd-t-50'>
                <div className='max-width-700 col-7'>
                    <div className='text-center fs-cs-40 fw-bolder text-color-cs-1'>
                    RAISE管理画面
                    </div>
                    <h1 className='text-center mg-t-30 fw-bold fs-cs-35'>パスワード再発行</h1>
                    <form onSubmit={handleSubmit(onForgetPassword)} className='mg-t-30 mx-auto'>
                        <label>登録されているメールアドレスを入力してください。パスワード再設定のメールを送信します</label>
                        <input
                            {...register('email')} id='email' type={'text'} className='form-control mg-t-5'
                        />
                        {
                            errors?.email &&
                            <div className='text-danger mg-t-5 fs-error'>{errors.email?.message}</div>
                        }
                        <div className='mg-t-25 text-center'>
                            <button disabled={isSubmitting} type='submit' className="btn btn-bg-cs-1 width-200">
                                送信
                            </button>
                        </div>
                    </form>
                    <div className='text-center mg-t-20 hover-underline'>
                        <Link to={SYSTEM_PATH.LOGIN}>ログインに戻り</Link>
                    </div>
                </div>
            </div>
        </div>
    )
})

export default ForgetPasswordScreen;