import { observer } from 'mobx-react';
import { useStore } from '../../core/utils/hook';
import { useForm } from 'react-hook-form';
import { useEffect } from 'react';
import { ReactNotifications, Table } from '../../components';
import moment from 'moment';
import { FORMAT_DATE_TIME, MSG, SCREEN_MODE, STATUS_COMMON, TEXT, TOOLTIP } from '../../core/configs/constants';
import classNames from 'classnames';
import SettingCardTypeScreen from './SettingCardTypeScreen';

const CardTypeListScreen = observer(() => {

    // store
    const {
        cardTypeStore: { cardTypeList, paging, getAllCardType, clean, updateStatusCardType, deleteCardType, setAttrObservable },
        modalStore: { show, hide, openWarningModal, openAlert }
    } = useStore();

    // state
    const { register, handleSubmit, getValues, reset, setValue } = useForm({});

    // lifecycle
    useEffect(() => {
        onSearch();
        return () => {
            clean();
        }
    }, [])

    // modal
    const onShowSettingCardType = (mode, id) => {
        show({
            id: 'modal-setting-card-type',
            isOpen: true,
            header: mode === SCREEN_MODE.ADD ? 'カード種類を追加' : 'カード種類を編集',
            onCancel: () => hide(),
            children: (
                <SettingCardTypeScreen mode={mode} id={id} onSearch={onSearch}/>
            ),
            type: 'big max-width-1100 vw-100'
        })
    }

    const onShowConfirmChangeStatus = (id, status) => {
        const msg = status === STATUS_COMMON.ACTIVE.key ? MSG['modal.confirm_active_card_type'] : MSG['modal.confirm_inactive_card_type'];
        openWarningModal(msg, () => onSubmitUpdate(1, id, { status }));
    }

    const onShowConfirmDelete = (id) => {
        openWarningModal(MSG['modal.confirm_delete_card_type'], () => onSubmitUpdate(2, id));
    }

    // function
    const onSearch = (data) => {
        clean();
        onFetchData(data);
    }

    const onFetchData = (tableData) => {
        setAttrObservable('paging', tableData, true, false);
        const payload = {
            ...getValues(),
            ...tableData
        };
        getAllCardType(payload);
    }

    // mode = 1: change status, mode = 2: delete
    const onSubmitUpdate = async (mode, id, payload = null) => {
        let res;
        if(mode === 1) {
            res = await updateStatusCardType(id, payload);
        } else if (mode === 2) {
            res = await deleteCardType(id);
        }
        if(res) {
            ReactNotifications('success', mode === 1 ? MSG['inform.success.update'] : MSG['inform.success.delete']);
            onSearch();
            hide();
        } 
    }

    // table columns
    const columns = [
        {
            Header: 'No.',
            accessor: 'id',
            disableSortBy: true,
            Cell: ({ row: { original, index }, initialState }) => {
                return <div>{initialState?.initialTableState?.pageSize * initialState?.initialTableState?.pageIndex + index + 1}</div>
            },
            width: '5%'
        },
        {
            Header: 'カード種類の名称',
            accessor: 'name',
            disableSortBy: true,
            Cell: ({ row: { original } }) => {
                return (
                    <div>
                        {original?.name}
                    </div>
                );
            },
            width: '25%'
        },
        {
            Header: '作成日',
            accessor: 'created_at',
            disableSortBy: true,

            Cell: ({ row: { original } }) => {
                return (
                    <div>
                        {original?.createdAt ? moment(new Date(original.createdAt)).format(FORMAT_DATE_TIME) : null}
                    </div>
                );
            },
            width: '20%'
        },
        {
            Header: 'ステータス',
            accessor: 'status',
            disableSortBy: true,
            Cell: ({ row: { original } }) => {
                return (
                    <div>
                        { 
                            original?.status === 0 ? 
                                <span className='text-danger'>無効</span> : 
                                <span className='text-success'>有効</span>
                        }
                    </div>
                );
            },
            width: '10%'
        },
        {
            Header: 'アクション',
            disableSortBy: true,
            Cell: ({ row: { original } }) => {
                const isActive = original?.status === STATUS_COMMON.ACTIVE.key;
                return (
                    <div>
                        <button
                            data-bs-toggle="tooltip"
                            title={isActive ? TOOLTIP.COMMON.INACTIVE : TOOLTIP.COMMON.ACTIVE}
                            type='button' className={classNames('btn-icon mg-r-10', isActive ? 'btn-icon-red' : 'btn-icon-green')} 
                            onClick={() => onShowConfirmChangeStatus(original?.id, isActive ? STATUS_COMMON.INACTIVE.key : STATUS_COMMON.ACTIVE.key)}>
                            {
                                isActive ?
                                    <i className="fa-solid fa-ban"></i> :
                                    <i className='fa-solid fa-check'></i>
                            }
                        </button>
                        <button
                            data-bs-toggle="tooltip"
                            title={TOOLTIP.EDIT}
                            type='button'
                            className='btn-icon btn-icon-orange mg-r-10' onClick={() => onShowSettingCardType(SCREEN_MODE.EDIT, original?.id)}>
                            <i className='fa-solid fa-pen-to-square'></i>
                        </button>
                        <button
                            data-bs-toggle="tooltip"
                            title={TOOLTIP.DELETE}
                            type='button' 
                            className={classNames('btn-icon btn-icon-red mg-r-10')} 
                            onClick={() => onShowConfirmDelete(original?.id)}>
                            <i className='fa-solid fa-trash-can'></i>
                        </button>
                    </div>
                );
            },
            width: '10%'
        }
    ]

    return(
        <div className='card-type-screen'>
            <div className='container-title'>カード種類の管理</div>
            <div className='container-search'>
                <form onSubmit={handleSubmit(onSearch)}>
                    <div className='row'>
                        <div className='row col-6 align-items-center'>
                            <label className='col-3'>カード種類の名称</label>
                            <input {...register('name')} className='col-8'/>
                        </div>
                        <div className='row col-6 justify-content-end align-items-center'>
                            <label className='col-3'>ステータス</label>
                            <select {...register('status')} className='col-8'>
                                <option value={''}>すべて</option>
                                <option value={STATUS_COMMON.ACTIVE.key}>{STATUS_COMMON.ACTIVE.label}</option>
                                <option value={STATUS_COMMON.INACTIVE.key}>{STATUS_COMMON.INACTIVE.label}</option>
                            </select>
                        </div>
                    </div>
                    <div className='text-center mg-t-20'>
                        <button type={'submit'} className={'btn btn-bg-cs-1 btn-default  btn-search-back'}>
                            <i className='fa-solid fa-magnifying-glass'></i>
                            <span className='mg-l-10'>{TEXT.SEARCH}</span>
                        </button>
                    </div>
                </form>
            </div>
            <div className='container-content'>
                <div className='float-end'>
                    <button type={'button'} className={'btn btn-bg-cs-1 text-white pd-lr-30'}
                        onClick={() => onShowSettingCardType(SCREEN_MODE.ADD)}>
                    カード種類を追加
                    </button>
                </div>
                <Table
                    columns={columns}
                    data={cardTypeList || []}
                    disablePaging={false}
                    enableServerSidePaging={true}
                    initialTableState={paging}
                    onFetch={onFetchData}
                    className='lst-card-type-table'
                />
            </div>
        </div>
    )
})

export default CardTypeListScreen;