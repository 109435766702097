import { observer } from 'mobx-react';
import { useForm } from 'react-hook-form';
import {  TEXT, MSG} from '../../core/configs/constants';
import { useStore } from '../../core/utils/hook';
import { yupResolver } from '@hookform/resolvers/yup';
import yup from '../../core/utils/yupValidate';
import { ReactNotifications } from '../../components';


const AddAccountScreen = observer(props => {
    //other
    const { id, onSearch } = props;
    //store
    const { userStore: { getAllUser },
        accountStore: {addAccount},
        modalStore: { hide } } = useStore();
    //state
    const validateSchema = yup.object().shape({
        userName: yup.string().trim().max(12, MSG['error.length_12']).required(MSG['error.required']),
    })
    const {register, handleSubmit, formState: {errors, isSubmitting} } = useForm({
        resolver: yupResolver(validateSchema), mode: 'onChange',});
    //life cycle
    //function
    const onSubmitAdd = async (data) => {
        let res;
        res = await addAccount({userId: id, ...data})
        if (res) {
            ReactNotifications('success', MSG['inform.success.create']);
            await onSearch();
            hide();

        }
    }


    return <div className='add-account-screen pd-16'>
        <form onSubmit={handleSubmit(onSubmitAdd)}>
            <div className='d-flex flex-column'>
                <label htmlFor='userName'>新しいアカウントのユーザーネームを入力してください。</label>
                <input type='text' {...register('userName')} className='mg-t-10'></input>
                {
                    errors.userName &&
                    <div className='text-danger fs-error mg-t-5 pd-0'>{errors.userName?.message}</div>
                }
            </div>
            <div className='d-flex justify-content-center align-items-center mg-t-40'>
                <button type='button' className='btn-default btn-cancel width-150' onClick={hide}>{TEXT.CANCEL}</button>
                <button type='submit' className='btn-default btn-bg-cs-1 width-150 mg-l-50'>
                    保存
                </button>
            </div>
        </form>
    </div>
})

export default AddAccountScreen;