/* eslint-disable max-len */
import { Line, Bar, Chart } from 'react-chartjs-2';
import { useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { FormatNumber, Table } from '../../../../components';
import { CHART_LABELS_DEFAULT, DASHBOARD_TYPE, PAYMENT_TYPE } from '../../../../core/configs/constants';
import { useStore } from '../../../../core/utils/hook';
import { observer } from 'mobx-react';



const ChartLineRevenue = observer(({year}) => {
    //store
    const { dashboardStore: {revenueList,getDashboardRevenue}} = useStore();

    //life cycle
    useEffect(() => {
        getDashboardData(year);
    }, [year])

    //option for chart
    const data = {
        labels : CHART_LABELS_DEFAULT,
        datasets: [
            {
                label: 'カード購入料',
                data: revenueList && revenueList[PAYMENT_TYPE.CARD] && revenueList[PAYMENT_TYPE.CARD].length ? revenueList[PAYMENT_TYPE.CARD].map((item) => item.value) : null ,
                borderColor: '#4472c4',
                backgroundColor: '#4472c4'
            },
            {
                label: 'Holder追加料',
                data: revenueList && revenueList[PAYMENT_TYPE.HOLDER] && revenueList[PAYMENT_TYPE.HOLDER].length ? revenueList[PAYMENT_TYPE.HOLDER].map((item) => item.value) : null,
                borderColor: '#ed7d31',
                backgroundColor: '#ed7d31'
            },
            {
                label: '会費',
                data: revenueList && revenueList[PAYMENT_TYPE.MEMBERSHIP] && revenueList[PAYMENT_TYPE.MEMBERSHIP].length ? revenueList[PAYMENT_TYPE.MEMBERSHIP].map((item) => item.value) : null,
                borderColor: '#a5a5a5',
                backgroundColor: '#a5a5a5'
            }
        ]
    }
    const options = {
        responsive: true,
        maintainAspectRatio: true,
        plugins: {
            legend: {
                position: 'bottom',
            },
            title: {
                display: false,
                text: 'Thong ke doanh thu',
                
            },
            tooltip: {
                callbacks: {
                    label: (context) => {
                        let label = '';
                        if (context.parsed.y) {
                            label = context.dataset.label + ': ' + context.parsed.y + ' 円'
                        }
                        return label;
                       
                    }
                }
            },
        },
        
    };

    //function
    const getDashboardData = async (year) => {
        await getDashboardRevenue({type: DASHBOARD_TYPE.OVERVIEW, year})
    }
    
    return <div className="chart-revenue">
        <div>
            <div className='mg-l-10 font-bold fs-cs-16'>総売上高: <span className='text-color-cs-red fs-cs-22'><FormatNumber statusDisplay='text' value={revenueList?.total} suffix=' 円' /></span></div>
        </div>
        <div className="mg-t-30 d-flex justify-content-center align-items-center w-full height-600">
            <Line options={options} data={data} />
        </div>
    </div>
})

const TableRevenue = observer((props) => {
    //other
    const { year } = props;
    //store
    const { dashboardStore: {revenueDetail, getDashboardRevenue, setAttrObservable}} = useStore();
    //state
    //life cycle
    useEffect(() => {
        getDashboardData(year);

        return () => { };
    }, [year])
    //function
    const getDashboardData = async (year) => {
        await getDashboardRevenue({type: DASHBOARD_TYPE.DETAIL, year})
    }
    
    //colums
    const columns = [
        {
            Header: '年月',
            accessor: 'month',
            disableSortBy: true,
            Cell: ({ row: { original } }) => {
                return <div>{ original?.date}</div>
            },
            width: '10%'
        },
        {
            Header: 'カード購入料',
            accessor: 'cardFee',
            disableSortBy: true,
            Cell: ({ row: { original } }) => {
                return (
                    <div>
                        <FormatNumber statusDisplay='text' value={original?.card} decimalScale={0} suffix=' 円' />
                    </div>
                )
            },
            with: '10%'
        },
        {
            Header: 'Holder追加料',
            accessor: 'holderFee',
            disableSortBy: true,
            Cell: ({ row: { original } }) => {
                return (
                    <div>
                        <FormatNumber statusDisplay='text' value={original?.holder} decimalScale={0} suffix=' 円' />
                    </div>
                )
            },
            with: '10%'
        },
        {
            Header: '会費',
            accessor: 'memberFee',
            disableSortBy: true,
            Cell: ({ row: { original } }) => {
                return (
                    <div>
                        <FormatNumber statusDisplay='text' value={original?.membership} decimalScale={0} suffix=' 円'/>
                    </div>
                )
            },
            with: '10%'
        },
        {
            Header: '月別の売上',
            accessor: 'sumOfFee',
            disableSortBy: true,
            Cell: ({ row: { original } }) => {
                return (
                    <div>
                        <FormatNumber statusDisplay='text' value={original?.card + original?.holder + original?.membership} decimalScale={0} suffix=' 円'/>
                    </div>
                )
            },
            with: '15%'
        },
    ]

    return <div className='revenue-detail-table'>
        <div className='mg-b-30'>
            <div className='mg-l-10 font-bold fs-cs-16'>総売上高: <span className='text-color-cs-red fs-cs-22'><FormatNumber statusDisplay='text' value={revenueDetail?.total} suffix=' 円'/></span></div>
        </div>
        <Table
            columns={columns}
            data={revenueDetail.data || []}
            disablepaging={true}
            disableSelectionPageSize={true}
            enableServerSidePaging={false}
            initialTableState={{
                page: 1,
                totalPage: 0,
                totalRecord: 0,
                size: 12,
                sortKey: null,
                sortDir: null
            }}
            hideTableFooter={true}
            className='lst-revenue-table'
        />
    </div>
})
const StastifyRevenue = observer(({ year, isOnTable=false, options, data }) => {
    
    return <div className='revenue-stastify'>
        {isOnTable ? <TableRevenue year={year} />
            : <ChartLineRevenue year={year} />}
    </div>
})

export default StastifyRevenue;
