import { observer } from 'mobx-react';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { DateTimeCalendarCustom, FormatNumber, Table } from '../../components';
import { AIRDROP_STATUS, MEMBERSHIP_TYPE, PAYMENT_TYPE, PURCHASE_STATUS, TEXT } from '../../core/configs/constants';
import { useStore } from '../../core/utils/hook';
import moment from 'moment';
import yup from '../../core/utils/yupValidate';
import { yupResolver } from '@hookform/resolvers/yup';
import { holderCardPaymentColumns, monthlyFeePaymentColumns, paymentCardColumns } from './Columns';
import classNames from 'classnames';
import { toJS } from 'mobx';

const PaymentScreen = observer(({ paymentType }) => {

    // store
    const { 
        userStore: { clean },
        paymentStore: { 
            totalAirdrop,
            transactionList,
            paging,
            getAllTransaction,
            setAttrObservable,
            exportTransactionCsv 
        }
    } = useStore();

    // state
    const validatePaymentSearchSchema = yup.object().shape({
        transactionCode: yup
            .string().trim(),
        minDate: yup.lazy((value) => {
            if (value === null) {
                return yup.mixed();
            }
            return yup.string()
        }),
        maxDate: yup.lazy((value) => {
            if (value === null) {
                return yup.mixed();
            }
            return yup.string()
        }),
        minTransactionDate: yup.lazy((value) => {
            if (value === null) {
                return yup.mixed();
            }
            return yup.string()
        }),
        maxTransactionDate: yup.lazy((value) => {
            if (value === null) {
                return yup.mixed();
            }
            return yup.string()
        }),
        cardCode: yup
            .string().trim(),
        userCode: yup
            .string().trim(),
        transactionStatus: yup
            .string(),
        airdropStatus: yup
            .string(),
        membershipType: yup
            .string()
    })
    const { 
        register, 
        handleSubmit, 
        formState: { errors, isSubmitting }, 
        getValues, 
        setValue,
        reset
    } = useForm({ resolver: yupResolver(validatePaymentSearchSchema), mode: 'onChange' });

    const [selectedRows, setSelectedRows] = useState([]);
    const selectedRowIds = useMemo(() => {
        const obj = {};
        selectedRows.forEach(item => {
            obj[item.id] = true;
        });
        return obj;
    }, [selectedRows])

    const columns = useMemo(() => {
        if(paymentType === PAYMENT_TYPE.CARD) return paymentCardColumns;
        if(paymentType === PAYMENT_TYPE.HOLDER) return holderCardPaymentColumns;
        return monthlyFeePaymentColumns;
    }, [paymentType])

    // lifecycle
    useEffect(() => {
        reset();
        onSearch({ page: 1});
        return () => {
            clean();
        }
    }, [paymentType]);

    // function
    const onFetchTransactionData = (tableState) => {
        setAttrObservable('paging', tableState, true, false);
        getAllTransaction({
            ...getValues(),
            ...tableState,
            type: paymentType
        })
    }

    const onSearch = (data) => {
        setSelectedRows([]);
        onFetchTransactionData(data);
    }

    const onExportCsvTransaction = () => {
        if(transactionList?.length > 0) {
            const transactionIds = selectedRows.length > 0 ? selectedRows.map(e => e.id) : [];
            exportTransactionCsv({
                ...getValues(),
                transactionIds,
                type: paymentType
            })
        }
    }

    const handleSelectTransaction = (selectedRows, rows) => {
        const selectedIds = Object.keys(selectedRows);
        if (selectedIds?.length > 0) {
            setSelectedRows(oldRows => {
                let data = oldRows;
                toJS(transactionList).forEach(row => {
                    const index = data.findIndex(e => e.id === row.id);
                    if (selectedIds.includes(String(row.id))) {
                        if (index < 0) {
                            data = [...data, row];
                        }
                    } else {
                        if (index > -1) {
                            data = [...data.slice(0, index), ...data.slice(index + 1)];
                        }
                    }
                })
                return data;
            })
        } else {
            setSelectedRows([]);
        }
    }

    // part of search
    // #region [search components]
    const SearchByTransactionCode = ({ className }) => (
        <div className={classNames('d-flex col-6 align-items-center', className)}>
            <label className='col-3'>取引ID</label>
            <input {...register('transactionCode')} className='col-8' />
        </div>
    )

    const SearchByDate = ({ className }) => (
        <div className={classNames('d-flex col-6 align-items-center', className)}>
            <label className='col-3'>作成日</label>
            <div className='col-8 d-flex align-items-center justify-content-between p-0'>
                <DateTimeCalendarCustom
                    startDate={getValues('minDate') || null}
                    maxDate={new Date()}
                    onChange={date => {
                        setValue('minDate', date ? moment(date).format('YYYY/MM/DD') : null, { shouldValidate: true });
                    }}
                />
                <div className='d-flex justify-content-center align-items-center px-2'>
                    <i className='fa-solid fa-minus'></i>
                </div>
                <DateTimeCalendarCustom
                    startDate={getValues('maxDate') || null}
                    maxDate={new Date()}
                    onChange={date => {
                        setValue('maxDate', date ? moment(date).format('YYYY/MM/DD') : null, { shouldValidate: true });
                    }}
                />
            </div>
        </div>
    )

    const SeachByCardCode = ({ className }) => (
        <div className={classNames('d-flex col-6 align-items-center', className)}>
            <label className='col-3'>カードID</label>
            <input {...register('cardCode')} className='col-8' />
        </div>
    )

    const SearchByTransactionDate = ({ className }) => (
        <div className={classNames('d-flex col-6 align-items-center', className)}>
            <label className='col-3'>支払日</label>
            <div className='col-8 d-flex align-items-center justify-content-between p-0'>
                <DateTimeCalendarCustom
                    startDate={getValues('minTransactionDate') || null}
                    maxDate={new Date()}
                    onChange={date => {
                        setValue('minTransactionDate', date ? moment(date).format('YYYY/MM/DD') : null, { shouldValidate: true });
                    }}
                />
                <div className='d-flex justify-content-center align-items-center px-2'>
                    <i className='fa-solid fa-minus'></i>
                </div>
                <DateTimeCalendarCustom
                    startDate={getValues('maxTransactionDate') || null}
                    maxDate={new Date()}
                    onChange={date => {
                        setValue('maxTransactionDate', date ? moment(date).format('YYYY/MM/DD') : null, { shouldValidate: true });
                    }}
                />
            </div>
        </div>
    )

    const SearchByUserCode = ({ className }) => (
        <div className={classNames('d-flex col-6 align-items-center', className)} >
            <label className='col-3'>ユーザーID</label>
            <input {...register('userCode')} className='col-8' />
        </div>
    )

    const SearchByTransactionStatus = ({ className }) => (
        <div className={classNames('d-flex col-6 align-items-center', className)} >
            <label className='col-3'>支払いステータス</label>
            <select 
                {...register('transactionStatus')} 
                className='col-8'>
                <option value={''}>すべて</option>
                {Object.keys(PURCHASE_STATUS).map((key) => {
                    const status = PURCHASE_STATUS[key];
                    return (
                        <option key={status.key} value={status.key}>
                            {status.label}
                        </option>
                    );
                })}
            </select>
        </div>
    )

    const SearchByAirdropStatus = ({ className }) => (
        <div className={classNames('d-flex col-6 align-items-center', className)} >
            <label className='col-3'>Airdropステータス</label>
            <select {...register('airdropStatus')} className='col-8'>
                <option value={''}>すべて</option>
                {Object.keys(AIRDROP_STATUS).map((key) => {
                    const status = AIRDROP_STATUS[key];
                    return (
                        <option key={status.key} value={status.key}>
                            {status.label}
                        </option>
                    );
                })}
            </select>
        </div>
    )

    const SearchByMembershipType = ({ className }) => (
        <div className={classNames('d-flex col-6 align-items-center', className)} >
            <label className='col-3'>会員料金プラン</label>
            <select {...register('membershipType')} className='col-8'>
                <option value={''}>すべて</option>
                {Object.keys(MEMBERSHIP_TYPE).map((key) => {
                    const membershipType = MEMBERSHIP_TYPE[key];
                    return (
                        <option key={membershipType.key} value={membershipType.key}>
                            {membershipType.labelPayment}
                        </option>
                    );
                })}
            </select>
        </div>
    )
    // #endregion

    return(
        <div className='payment-screen'>
            <div className='container-title'>{
                paymentType === PAYMENT_TYPE.CARD ? (
                    'カード購入取引一覧'
                ) : (
                    paymentType === PAYMENT_TYPE.HOLDER ? (
                        'Holder追加取引一覧'
                    ) : (
                        '月額料'
                    )
                )
            }</div>
            <div className='container-search'>
                <form onSubmit={handleSubmit(onSearch)}>
                    <div className='row flex-wrap'>
                        <SearchByTransactionCode/>
                        <SearchByDate className={'justify-content-end'}/>
                        {
                            paymentType === PAYMENT_TYPE.CARD ? (
                                <>
                                    <SeachByCardCode className={'mg-t-20'}/>
                                    <SearchByTransactionDate className={'justify-content-end mg-t-20'}/>
                                    <SearchByUserCode className={'mg-t-20'}/>
                                </>
                            ) : (
                                <>
                                    <SearchByUserCode className={'mg-t-20'}/>
                                    <SearchByTransactionDate className={'justify-content-end mg-t-20'}/>
                                </>
                            )
                        }
                        <SearchByTransactionStatus className={classNames('mg-t-20', paymentType === PAYMENT_TYPE.CARD && 'justify-content-end')}/>
                        {
                            paymentType === PAYMENT_TYPE.MEMBERSHIP &&
                            <SearchByMembershipType className={'justify-content-end mg-t-20'}/>
                        }
                        {
                            paymentType === PAYMENT_TYPE.CARD &&
                            <SearchByAirdropStatus className={'mg-t-20'}/>
                        }
                    </div>
                    <div className='text-center mg-t-30'>
                        <button type={'submit'} className={'btn-default btn-search-back'} disabled={isSubmitting}>
                            <i className='fa-solid fa-magnifying-glass'></i>
                            <span className='mg-l-10'>{TEXT.SEARCH}</span>
                        </button>
                    </div>
                </form>
            </div>
            <div className='container-content'>
                <div className='float-end'>
                    {
                        paymentType === PAYMENT_TYPE.CARD &&
                        <span className='font-bold text-color-cs-red mg-r-30'>Airdropが必要な総SML数: {
                            <FormatNumber value={totalAirdrop} />
                        }
                        </span>
                    }
                    <button type={'button'} className={classNames('btn btn-bg-cs-4 text-white pd-lr-30', 
                        !transactionList?.length && 'not-allowed'
                    )}
                    onClick={onExportCsvTransaction}>
                        CSVダウンロード
                    </button>
                </div>
                <Table
                    columns={columns}
                    data={ transactionList || []}
                    disablePaging={false}
                    enableServerSidePaging={true}
                    initialTableState={paging}
                    onFetch={onFetchTransactionData}
                    className='lst-payment-table'
                    enableSelectRow={true}
                    onSelectedChange={handleSelectTransaction}
                    selectedRowIds={selectedRowIds}
                    rowKey={'id'}
                />
            </div>
        </div>
    )
})

export default PaymentScreen;