import { flow, makeObservable } from 'mobx';
import { GeneralSettingApi } from '../apis';

class GeneralSettingStore {
    constructor(rootStore) {
        makeObservable(this, {
            getGeneralSetting: flow.bound,
            setGeneralSetting: flow.bound,
            getOtherSettings: flow.bound,
            setOtherSettings: flow.bound,
        })
        this.rootStore = rootStore;
        this.api = new GeneralSettingApi();
    }

    *getGeneralSetting( type ) {
        try {
            const res = yield this.rootStore.apiStore.call(this.api, this.api.getGeneralSetting, type);
            if(res) {
                return res;
            }
        } catch (error) {
            console.log(error);
        }
    }

    *setGeneralSetting( type, payload ) {
        try {
            const res = yield this.rootStore.apiStore.call(this.api, this.api.setGeneralSetting, {type, payload});
            if(res) {
                return res;
            }
        } catch (error) {
            console.log(error);
        }
    }

    *getOtherSettings() {
        try {
            const res = yield this.rootStore.apiStore.call(this.api, this.api.getOtherSettings);
            if (res) {
                return res;
            }
        } catch (error) {
            console.log(error);
        }
    }

    *setOtherSettings(payload) {
        try {
            const res = yield this.rootStore.apiStore.call(this.api, this.api.setOtherSettings, payload);
            if (res) {
                return res;
            }
        } catch (error) {
            console.log(error);
        }
    }
}

export default GeneralSettingStore;