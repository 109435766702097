import { flow, makeObservable, observable } from 'mobx';
import { CardTypeApi } from '../apis';
import BaseStore from './BaseStore';

class CardStore extends BaseStore {

    cardTypeList = [];
    cardType = null;

    constructor(rootStore) {
        super();
        makeObservable(this, {
            cardTypeList: observable,
            cardType: observable,
            getAllCardType: flow.bound,
            createCardType: flow.bound,
            getCardType: flow.bound,
            updateCardType: flow.bound,
            updateStatusCardType: flow.bound,
            deleteCardType: flow.bound
        })
        this.rootStore = rootStore;
        this.api = new CardTypeApi();
    }

    *getAllCardType(searchParams) {
        try {
            const { size, page, sortDir, sortKey } = this.paging;
            const payload = { size, page, sortDir, sortKey, ...searchParams };
            const res = yield this.rootStore.apiStore.call(this.api, this.api.getAllCardType, payload);
            if (res?.ok) {
                this.cardTypeList = res?.data?.elements || [];
                this.setAttrObservable('paging', res?.data?.paginate, true, false);
            }
        } catch (error) {
            console.log(error);
        }
    }

    *createCardType(payload) {
        try {
            const res = yield this.rootStore.apiStore.call(this.api, this.api.createCardType, payload);
            if(res?.ok) {
                return true;
            }
            return false;
        } catch (error) {
            console.log(error);
        }
    }

    *getCardType(id, payload) {
        try {
            const res = yield this.rootStore.apiStore.call(this.api, this.api.getCardType, { id, payload });
            if(res?.ok) {
                this.cardType = res?.data;
            }
        } catch (error) {
            console.log(error);
        }
    }

    *updateCardType(id, payload) {
        try {
            const res = yield this.rootStore.apiStore.call(this.api, this.api.updateCardType, { id, payload });
            if(res?.ok) {
                return true;
            }
            return false;
        } catch (error) {
            console.log(error);
        }
    }

    *updateStatusCardType(id, payload) {
        try {
            const res = yield this.rootStore.apiStore.call(this.api, this.api.updateStatusCardType, { id, payload });
            if(res?.ok) {
                return true;
            }
            return false;
        } catch (error) {
            console.log(error);
        }
    }

    *deleteCardType(id, payload = {}) {
        try {
            const res = yield this.rootStore.apiStore.call(this.api, this.api.deleteCardType, { id, payload });
            if(res?.ok) {
                return true;
            }
            return false;
        } catch (error) {
            console.log(error);
        }
    }

    clean() {
        super.clean();
        this.cardTypeList = [];
        this.cardType = null;
    }
}

export default CardStore;