import { useEffect } from 'react';
import { FORMAT_DATE_TIME, INQUIRY_STATUS, MSG } from '../../core/configs/constants';
import { useStore } from '../../core/utils/hook';
import moment from 'moment';
import { observer } from 'mobx-react';
import { useForm } from 'react-hook-form';
import { ReactNotifications } from '../../components';

const InquiryDetail = observer(({ id, dataSearch }) => {
    //other
    //store
    const {
        inquiryStore: { getAllInquiry, getInquiry, setAttrObservable, inquiry, updateInquiry },
        modalStore: {hide}
    } = useStore()

    //state
    const { register, handleSubmit, reset } = useForm();

    //lifecycle
    useEffect(() => {
        getInquiryData(id);

        return () => {
            setAttrObservable('inquiry', null);
        }
    }, [])

    useEffect(() => {
        reset(inquiry);
    }, [inquiry])

    //function
    const onSubmit = async({ status, note }) => {
        hide();
        const res = await updateInquiry(id, { status, note });
        if (res) {
            ReactNotifications('success', MSG['inform.success.update']);
            await getAllInquiry(dataSearch);
        }
    }

    const getInquiryData = async (id) => {
        await getInquiry(id)
    }

    return <form onSubmit={handleSubmit(onSubmit)} 
        className='inquiry-detail pd-30 max-width-1000' style={{overflow: 'scroll', maxHeight: 'calc(100vh - 300px)'}}>
        <div className='inquiry-info row'>
            <div className='col-6'>
                <div className='info-item row'>
                    <p className='col-4'>送信日時:</p>
                    <p className='col-8 pd-0'>{inquiry?.createdAt ? moment(new Date(inquiry?.createdAt)).format(FORMAT_DATE_TIME) : ''}</p>
                </div>
                <div className='info-item row'>
                    <p className='col-4'>メールアドレス:</p>
                    <p className='col-8 pd-0'>{inquiry?.email}</p>
                </div>
            </div>
            <div className='col-6'>
                <div className='info-item row'>
                    <p className='col-4'>送信者:</p>
                    <p className='col-8 pd-0'>{inquiry?.fullName}</p>
                </div>
                <div className='info-item row'>
                    <p className='col-4'>電話番号:</p>
                    <p className='col-8 pd-0'>{inquiry?.phoneNumber}</p>
                </div>
            </div>
        </div>
        <div className='row mg-t-10'>
            <div className='col-2'>ステータス</div>
            <select 
                {...register('status')} 
                className='col-9'>
                {Object.keys(INQUIRY_STATUS).map((key) => {
                    const status = INQUIRY_STATUS[key];
                    return (
                        <option key={status.key} value={status.key}>
                            {status.label}
                        </option>
                    );
                })}
            </select>
        </div>
        <div className='row mg-t-10'>
            <div className='col-2'>備考</div>
            <textarea {...register('note')} className='col-9 min-height-150 max-height-300 noScrollTextarea' />
        </div>
        <div className='row mg-t-10 border-bottom-thin-color-cs-light-gray mg-lr-2 mg-t-30'></div>
        <div className='inquiry-content mg-t-10'>
            <h5 className='fw-bold mg-t-30'>お問い合わせ内容</h5>
            <p className='mg-t-15'>{inquiry?.content}</p>
        </div>
        <div className='d-flex justify-content-center align-items-center gap-5 mg-t-35'>
            <button type='button' className='btn-default btn-close-custom'
                onClick={() => {
                    hide();
                }}>
                閉じる
            </button>
            <button type='submit' className='btn-default btn-bg-cs-1'>保存</button>
        </div>
    </form>
})

export default InquiryDetail;