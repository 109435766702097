import axios from 'axios';
import CONFIG from '../core/configs/config';
import { getSavedInfo } from '../core/utils/browser';
import momentTz from 'moment-timezone';

const HTTP_METHODS = [ 'get', 'post', 'put', 'delete', 'patch' ];

class ApiService {

    constructor(url = null, headers = null) {
        this.headers = headers;
        const http = CONFIG.ssl ? 'https' : 'http';
        this.url = `${http}://${CONFIG.api_url}${url != null ? `/${url}` : ''}`;
        HTTP_METHODS.forEach(method => {
            this[method] = (url, body, headers, responseType, otherUrl) => {
                return this.callApi(method, url, body, headers, responseType, otherUrl);
            }
        })
    }

    callApi(method, url = '', body = null, headers = null, responseType = null, otherUrl = null) {
        const token = getSavedInfo('token');
        const headerOptions = Object.assign(
            {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Expose-Headers': 'Content-Disposition',
                'Pragma': 'no-cache',
                Authorization: token,
                'Timezone': momentTz.tz.guess()
            },
            this.headers,
            headers
        )
        let dataForm = body;
        if(headerOptions?.['Content-Type'] === 'multipart/form-data' && method !== 'get' && body) {
            dataForm = this.processBodyData(body);
        }
        return axios({
            url: otherUrl || `${this.url}/${url}`,
            method,
            headers: headerOptions,
            ...(
                method === 'get' ? 
                    {
                        params: body ?? {}
                    } 
                    :
                    {
                        data: dataForm ?? {}
                    }
            ),
            responseType: responseType != null ? responseType : 'json'
        });
    }

    processBodyData(obj) {
        const bodyData = {};
        
        Object.entries(obj).forEach(([key, value]) => {
            bodyData[key] = value === null || value === undefined ? '' : value;
        });
        
        return bodyData;
    }
       
}

export default ApiService;