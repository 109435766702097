import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Table } from '../../../../components';
import { 
    USER_STATUS, 
    DASHBOARD_DETAILS_TITTLE, 
    DASHBOARD_TYPE, 
    FORMAT_DATE_TIME
} from '../../../../core/configs/constants';
import { useStore } from '../../../../core/utils/hook';
import { Link, useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';

const NewUsersByMonthDetailsStatistical = observer(() => {
    //other
    const navigate = useNavigate();
    const { time } = useParams()

    // store
    const {
        dashboardStore: { clean, setAttrObservable, registedUsersDetail, getDashboardNewUser, userPaging },
    } = useStore();

    // state
    const { register, watch, getValues } = useForm();
    const status = watch('status');

    // lifecycle
    useEffect(() => {
        onSearch();

        return () => {
            clean();
        }
    }, [status])

    // function
    const onSearch = (data) => {
        clean();
        onFetchNewUserData(data);
    }

    const onFetchNewUserData = (tableData) => {
        const month = time.split('-')[0];
        const year = time.split('-')[1];
        setAttrObservable('userPaging', tableData, true, false);
        getDashboardNewUser({
            ...getValues(),
            ...tableData,
            year,
            month,
            type: DASHBOARD_TYPE.DETAIL,
        })
    }

    // columns
    const columns = [
        {
            Header: 'No.',
            accessor: 'id',
            disableSortBy: true,
            Cell: ({ row: { original, index }, initialState }) => {
                return <div>{initialState?.initialTableState?.pageSize * initialState?.initialTableState?.pageIndex + index + 1}</div>
            },
            width: '5%'
        },
        {
            Header: 'ユーザーID',
            accessor: 'code',
            disableSortBy: true,
            Cell: ({ row: { original } }) => {
                return (
                    <div>
                        <Link to={`/user/${original?.id}`} >{original?.code}</Link>
                    </div>
                );
            },
            width: '10%'
        },
        {
            Header: 'ユーザーネーム',
            accessor: 'user_name',
            disableSortBy: true,
            Cell: ({ row: { original } }) => {
                return (
                    <div>
                        {original?.userName}
                    </div>
                );
            },
            width: '10%'
        },
        {
            Header: '名前-本名',
            accessor: 'full_name',
            disableSortBy: true,
            Cell: ({ row: { original } }) => {
                return (
                    <div>
                        {original?.fullName}
                    </div>
                );
            },
            width: '10%'
        },
        {
            Header: 'メールアドレス',
            accessor: 'email',
            disableSortBy: true,
            Cell: ({ row: { original } }) => {
                return (
                    <div>
                        {original?.email}
                    </div>
                );
            },
            width: '15%'
        },
        {
            Header: '作成日',
            accessor: 'createdAt',
            disableSortBy: true,
            Cell: ({ row: { original } }) => {

                return (
                    <div>
                        {moment(original?.createdAt).format(FORMAT_DATE_TIME)}
                    </div>
                );
            },
            width: '10%'
        },
        {
            Header: 'ステータス',
            accessor: 'status',
            disableSortBy: true,
            Cell: ({ row: { original } }) => {
                const statusKey = Object.keys(USER_STATUS).filter(e => USER_STATUS[e]?.key === original?.status)?.[0] ?? null;

                return (
                    <div style={{ 'color': USER_STATUS[statusKey]?.color }}>
                        {statusKey && USER_STATUS[statusKey]?.label}
                    </div>
                );
            },
            width: '10%'
        },

    ]

    return (
        <div className='new-user-dashboard-screen'>
            <div className='d-flex justify-content-between align-items-center'>
                <div className='container-title'>{DASHBOARD_DETAILS_TITTLE.NEW_USER}</div>
                <button className='btn-default btn-search-back' onClick={() => navigate('/')}>
                    <i className="fa-solid fa-angles-left"></i>
                    <span className='mg-l-10'>戻る</span>
                </button>
            </div>
            <div className='container-content'>
                <div className='float-end'>
                    <select 
                        {...register('status')} 
                        className='min-width-200'>
                        <option value={''}>すべて</option>
                        <option value={USER_STATUS.ACTIVE.key}>{USER_STATUS.ACTIVE.label}</option>
                        <option value={USER_STATUS.INACTIVE.key}>{USER_STATUS.INACTIVE.label}</option>
                    </select>
                </div>

                <Table
                    columns={columns}
                    data={registedUsersDetail || []}
                    disablePaging={false}
                    enableServerSidePaging={true}
                    initialTableState={userPaging}
                    onFetch={(tableData) => onFetchNewUserData(tableData)}
                    className='lst-admin-table'
                />
            </div>
        </div>
    );
});

export default NewUsersByMonthDetailsStatistical;