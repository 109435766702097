import classNames from 'classnames';
import { 
    AIRDROP_STATUS, 
    PURCHASE_STATUS,  
    MSG, 
    TEXT, 
    TOOLTIP, 
    FORMAT_DATE_TIME, 
    PAYMENT_TYPE, 
    ROLE,
    TRANSACTION_FORM_MAPPER,
    MEMBERSHIP_TYPE
} from '../../core/configs/constants';
import { useStore } from '../../core/utils/hook';
import { observer } from 'mobx-react';
import { Link, useNavigate } from 'react-router-dom';
import { FormatNumber, ModalFooter, ReactNotifications } from '../../components';
import DetailsPayment from './DetailsModal/DetailsPayment';
import moment from 'moment';

export const paymentCardColumns = [
    {
        Header: '取引ID',
        accessor: 'code',
        disableSortBy: true,
        Cell: ({ row: { original } }) => {
            return (
                <div>
                    {original?.code}
                </div>
            );
        },
        width: '15%'
    },
    {
        Header: '作成日',
        accessor: 'createdAt',
        disableSortBy: true,
        Cell: ({ row: { original } }) => {
            return (
                <div>
                    {moment(original?.createdAt).format(FORMAT_DATE_TIME)}
                </div>
            );
        },
        width: '8%'
    },
    {
        Header: '支払日',
        accessor: 'transaction_date',
        disableSortBy: false,
        Cell: ({ row: { original } }) => {
            return (
                <div>
                    {original?.status === PURCHASE_STATUS.SUCCESS.key ? (
                        <div>{moment(original?.transactionDate).format(FORMAT_DATE_TIME)}</div>
                    ): (
                        <div></div>
                    )}
                </div>
            );
        },
        width: '8%'
    },
    {
        Header: 'ユーザーID',
        accessor: 'user_code',
        disableSortBy: false,
        Cell: ({ row: { original } }) => {
            return (
                <div>
                    <Link to={`/user/${original?.account?.user?.id}`}><u>{original?.account?.user?.code}</u></Link>
                </div>
            );
        },
        width: '12%'
    },
    {
        Header: '名前-本名',
        accessor: 'fullName',
        disableSortBy: true,
        Cell: ({ row: { original } }) => {
            return (
                <div>
                    {original?.account?.user?.fullName}
                </div>
            );
        },
        width: '10%'
    },
    {
        Header: 'カード数',
        accessor: 'numbersOfCard',
        disableSortBy: true,
        Cell: ({ row: { original } }) => {
            return (
                <div>
                    {original?.cards?.length}
                </div>
            );
        },
        width: '5%'
    },
    {
        Header: '総合計(円)',
        accessor: 'totalPayment',
        disableSortBy: true,
        Cell: ({ row: { original } }) => {
            return (
                <div>
                    <FormatNumber value={original?.totalPayment} />
                </div>
            );
        },
        width: '6%'
    },
    {
        Header: '支払いステータス',
        accessor: 'status',
        disableSortBy: true,
        Cell: ({ row: { original } }) => {
            const findStatus = Object.keys(PURCHASE_STATUS).filter(key => PURCHASE_STATUS[key].key === original?.status)?.[0];
            return (
                findStatus && <div className={PURCHASE_STATUS[findStatus]?.colorClassName}>
                    {PURCHASE_STATUS[findStatus]?.label}
                </div>
            );
        },
        width: '8%'
    },
    {
        Header: 'Airdrop総量 (SML)',
        accessor: 'Airdrop',
        disableSortBy: true,
        Cell: ({ row: { original } }) => {
            return (
                <div>
                    <FormatNumber value={original?.airdrop} />
                </div>
            );
        },
        width: '8%'
    },
    {
        Header: 'Airdropステータス',
        accessor: 'airdropStatus',
        disableSortBy: true,
        Cell: observer(({ row: { original } }) => {
            // store
            const { modalStore: { hide, show } } = useStore();
         
            return (
                <div>
                    {original?.airdropStatus === AIRDROP_STATUS.COMPLETED_PAYMENT.key ? (
                        <div 
                            className='border-0 btn-cs-3'>
                            {AIRDROP_STATUS.COMPLETED_PAYMENT.label}
                        </div>
                    ): (
                        <div>
                            {original?.airdropStatus === AIRDROP_STATUS.WAITING_PAYMENT.key ? 
                                AIRDROP_STATUS.WAITING_PAYMENT.label : 
                                ''}
                        </div>
                    )}
                </div>
            );
        }),
        width: '8%'
    },
    {
        Header: 'アクション',
        accessor: '',
        disableSortBy: true,
        Cell: observer(({ row: { original } }) => {
            // other
            const navigate = useNavigate();

            // store
            const { 
                modalStore: { hide, show }, 
                paymentStore: { getAllTransaction, confirmChangePaymentStatus, updateAIRDropStatus, cancelPurchase },
                authStore: {userInfo}
            } = useStore();

            // function
            const handleChangeAIRDropStatus = async () => {
                const res = await updateAIRDropStatus( original?.id, { airdropStatus: 4 } );
                if (res) {
                    const type = PAYMENT_TYPE.CARD;
                    await getAllTransaction({type});
                    hide();
                    ReactNotifications('success', MSG['inform.success.update']);
                    return;
                }
            }

            const handleChangePaymentStatus = async () => {
                try {
                    const res = await confirmChangePaymentStatus(original?.id);
                    if (res) {
                        const type = PAYMENT_TYPE.CARD;
                        await getAllTransaction({type});
                        hide();
                        ReactNotifications('success', MSG['inform.success.update']);
                        return;
                    }
                } catch (error) {
                    console.log(error);
                }
            }

            const handleCancelPurchase = async () => {
                try {
                    const res = await cancelPurchase(original?.id);
                    if (res) {
                        const type = PAYMENT_TYPE.CARD;
                        await getAllTransaction({type});
                        hide();
                        ReactNotifications('success', MSG['inform.success.update']);
                        return;
                    }
                } catch (error) {
                    console.log(error);
                }
            }

            // modal
            const onShowConfirmChangeStatus = (message, type) => {
                show({
                    id: 'modal-alert',
                    isOpen: true,
                    header: '確認',
                    onCancel: () => {
                        hide();
                    },
                    children: (
                        <div className='font-bold max-width-960 pd-10'>
                            <div className='mg-t-10'>{message}</div>
                            <ModalFooter key={'modal-footer'}
                                saveButtonText={TEXT.YES}
                                cancelButtonText={TEXT.NO}
                                onConfirm={() => {
                                    if (type === 'paymentStatus') {
                                        handleChangePaymentStatus();
                                    } else if (type === 'airdropStatus') {
                                        handleChangeAIRDropStatus();
                                    } else if (type === 'cancelPurchase') {
                                        handleCancelPurchase();
                                    }
                                }}
                                onCancel={() => {
                                    hide();
                                }}
                                saveButtonClass='btn-bg-cs-1'
                            />
                        </div>
                    ),
                    type: 'small'
                });
            }

            const onShowDetailsCardPayment = () => {
                navigate(`/buy-card/${original.id}`);
            }

            return (
                <div className='d-flex align-items-center justify-content-center flex-gap-10'>
                    {original?.status === PURCHASE_STATUS.WAIT_FOR_PAYMENT.key &&
                        userInfo?.role !== ROLE.STAFF.key ? (
                            <>
                                <button
                                    data-bs-toggle='tooltip'
                                    title={PURCHASE_STATUS.SUCCESS.label}
                                    onClick={() => onShowConfirmChangeStatus(MSG['modal.tittle_alert_changePaymentStatus'], 'paymentStatus')}
                                    className='btn-icon btn-icon-orange'>
                                    <i className='fa-regular fa-square-check'></i>
                                </button>
                                <button
                                    data-bs-toggle='tooltip'
                                    title={PURCHASE_STATUS.CANCELED.label}
                                    onClick={() => onShowConfirmChangeStatus(MSG['modal.tittle_alert_cancelPurchase'], 'cancelPurchase')}
                                    className='btn-icon btn-icon-gray'>
                                    <i className='fa-solid fa-xmark'></i>
                                </button>
                            </>
                        ) : (
                            original?.airdropStatus === AIRDROP_STATUS.WAITING_PAYMENT.key ? (
                                <>
                                    <button
                                        data-bs-toggle='tooltip'
                                        title={AIRDROP_STATUS.COMPLETED_PAYMENT.label}
                                        onClick={() => onShowConfirmChangeStatus(MSG['modal.tittle_alert_changeStatusAIRDrop'], 'airdropStatus')}
                                        className='btn-icon btn-icon-green'>
                                        <i className='fa-solid fa-check'></i>
                                    </button>
                                    <button className='btn-icon invisible'></button>
                                </>
                            ) : ( 
                                <>
                                    <button className='btn-icon invisible'></button>
                                    <button className='btn-icon invisible'></button>
                                </>
                            )
                        )}
                    <button
                        data-bs-toggle='tooltip'
                        title={TOOLTIP.VIEW_DETAILS}
                        type='button' className='btn-icon btn-icon-red'
                        onClick={onShowDetailsCardPayment}
                    >
                        <i className='fa-solid fa-eye'></i>
                    </button>
                </div>
            );
        }),
        width: '10%'
    }
]

export const holderCardPaymentColumns = [
    {
        Header: '取引ID',
        accessor: 'code',
        disableSortBy: true,
        Cell: ({ row: { original } }) => {
            return (
                <div>
                    {original?.code}
                </div>
            );
        },
        width: '13%'
    },
    {
        Header: '作成日',
        accessor: 'createdAt',
        disableSortBy: true,
        Cell: ({ row: { original } }) => {
            return (
                <div>
                    {moment(original?.createdAt).format(FORMAT_DATE_TIME)}
                </div>
            );
        },
        width: '10%'
    },
    {
        Header: '支払日',
        accessor: 'transaction_date',
        disableSortBy: false,
        Cell: ({ row: { original } }) => {
            return (
                <div>
                    {original?.status === PURCHASE_STATUS.SUCCESS.key ? (
                        <div>{moment(original?.transactionDate).format(FORMAT_DATE_TIME)}</div>
                    ): (
                        <div></div>
                    )}
                </div>
            );
        },
        width: '10%'
    },
    {
        Header: 'ユーザーID',
        accessor: 'user_code',
        disableSortBy: false,
        Cell: ({ row: { original } }) => {
            return (
                <div>
                    <Link to={`/user/${original?.account?.user?.id}`}><u>{original?.account?.user?.code}</u></Link>
                </div>
            );
        },
        width: '11%'
    },
    {
        Header: '名前-本名',
        accessor: 'fullName',
        disableSortBy: true,
        Cell: ({ row: { original } }) => {
            return (
                <div>
                    {original?.account?.user?.fullName}
                </div>
            );
        },
        width: '10%'
    },
    {
        Header: '分類',
        accessor: 'transactionForm',
        disableSortBy: true,
        Cell: ({ row: { original } }) => {
            return (
                <div>
                    {original?.transactionForm ? TRANSACTION_FORM_MAPPER[original?.transactionForm].text : ''}
                </div>
            );
        },
        width: '10%'
    },
    {
        Header: 'Holder総枠数',
        accessor: 'numberHolder',
        disableSortBy: true,
        Cell: ({ row: { original } }) => {
            return (
                <div>
                    {original?.numberHolder}
                </div>
            );
        },
        width: '7%'
    },
    {
        Header: '支払総額 (円)',
        accessor: 'totalPayment',
        disableSortBy: true,
        Cell: ({ row: { original } }) => {
            return (
                <div>
                    <FormatNumber value={original?.totalPayment} />
                </div>
            );
        },
        width: '8%'
    },
    {
        Header: '支払いステータス',
        accessor: 'status',
        disableSortBy: true,
        Cell: ({ row: { original } }) => {
            const findStatus = Object.keys(PURCHASE_STATUS).filter(key => PURCHASE_STATUS[key].key === original?.status)?.[0];
            return (
                findStatus && <div className={PURCHASE_STATUS[findStatus]?.colorClassName}>
                    {PURCHASE_STATUS[findStatus]?.label}
                </div>
            );
        },
        width: '10%'
    },
    {
        Header: 'アクション',
        accessor: '',
        disableSortBy: true,
        Cell: observer(({ row: { original } }) => {
            const navigate = useNavigate();

            // store
            const { 
                modalStore: { hide, show },
                paymentStore: { confirmChangePaymentStatus, getDetailsTransaction, getAllTransaction, cancelPurchase },
                authStore: {userInfo}
            } = useStore();

            // function
            const handleChangePaymentStatus = async () => {
                try {
                    const res = await confirmChangePaymentStatus(original?.id);
                    if (res) {
                        const type = PAYMENT_TYPE.HOLDER;
                        await getAllTransaction({type});
                        hide();
                        ReactNotifications('success', MSG['inform.success.update']);
                        return;
                    }
                } catch (error) {
                    console.log(error);
                }
            }

            const handleCancelPurchase = async () => {
                try {
                    const res = await cancelPurchase(original?.id);
                    if (res) {
                        const type = PAYMENT_TYPE.HOLDER;
                        await getAllTransaction({type});
                        hide();
                        ReactNotifications('success', MSG['inform.success.update']);
                        return;
                    }
                } catch (error) {
                    console.log(error);
                }
            }

            // modal
            const onShowConfirmChangeStatus = (message, type) => {
                show({
                    id: 'modal-alert',
                    isOpen: true,
                    header: '確認',
                    onCancel: () => {
                        hide();
                    },
                    children: (
                        <div className='font-bold max-width-960 pd-10'>
                            <div className='mg-t-10'>{message}</div>
                            <ModalFooter key={'modal-footer'}
                                saveButtonText={TEXT.YES}
                                cancelButtonText={TEXT.NO}
                                onConfirm={() => {
                                    if (type === 'paymentStatus') {
                                        handleChangePaymentStatus();
                                    } else if (type === 'cancelPurchase') {
                                        handleCancelPurchase();
                                    }
                                }}
                                onCancel={() => {
                                    hide();
                                }}
                                saveButtonClass='btn-bg-cs-1'
                            />
                        </div>
                    ),
                    type: 'small'
                });
            }

            const onShowDetailsHolderPayment = async () => {
                navigate(`/buy-holder/${original?.id}`);
            }

            return (
                <div className='d-flex align-items-center justify-content-center flex-gap-10'>
                    {
                        original?.status === PURCHASE_STATUS.WAIT_FOR_PAYMENT.key && userInfo?.role !== ROLE.STAFF.key ?
                            (
                                <>
                                    <button
                                        data-bs-toggle='tooltip'
                                        title={PURCHASE_STATUS.WAIT_FOR_PAYMENT.label}
                                        onClick={() => onShowConfirmChangeStatus(MSG['modal.tittle_alert_changePaymentStatus'], 'paymentStatus')}
                                        className='btn-icon btn-icon-orange'>
                                        <i className='fa-regular fa-square-check'></i>
                                    </button>
                                    <button
                                        data-bs-toggle='tooltip'
                                        title={PURCHASE_STATUS.CANCELED.label}
                                        onClick={() => onShowConfirmChangeStatus(MSG['modal.tittle_alert_cancelPurchase'], 'cancelPurchase')}
                                        className='btn-icon btn-icon-gray'>
                                        <i className='fa-solid fa-xmark'></i>
                                    </button>
                                </>
                            ) : (
                                <>
                                    <button className='btn-icon invisible'></button>
                                    <button className='btn-icon invisible'></button>
                                </>
                            )
                    }
                    <button
                        data-bs-toggle='tooltip'
                        title={TOOLTIP.VIEW_DETAILS}
                        type='button' className='btn-icon btn-icon-red' 
                        onClick={() => onShowDetailsHolderPayment()}
                    >
                        <i className='fa-solid fa-eye'></i>
                    </button>
                </div>
            );
        }),
        width: '10%'
    }
]

export const monthlyFeePaymentColumns = [
    {
        Header: '取引ID',
        accessor: 'code',
        disableSortBy: true,
        Cell: ({ row: { original } }) => {
            return (
                <div>
                    {original?.code}
                </div>
            );
        },
        width: '12%'
    },
    {
        Header: '作成日',
        accessor: 'createdAt',
        disableSortBy: true,
        Cell: ({ row: { original } }) => {
            return (
                <div>
                    {moment(original?.createdAt).format(FORMAT_DATE_TIME)}
                </div>
            );
        },
        width: '8%'
    },
    {
        Header: '支払日',
        accessor: 'transaction_date',
        disableSortBy: false,
        Cell: ({ row: { original } }) => {
            return (
                <div>
                    {original?.status ===  PURCHASE_STATUS.SUCCESS.key? (
                        <div>{moment(original?.transactionDate).format(FORMAT_DATE_TIME)}</div>
                    ): (
                        <div></div>
                    )}
                </div>
            );
        },
        width: '8%'
    },
    {
        Header: 'ユーザーID',
        accessor: 'user_code',
        disableSortBy: false,
        Cell: ({ row: { original } }) => {
            return (
                <div>
                    <Link to={`/user/${original?.account?.user?.id}`}><u>{original?.account?.user?.code}</u></Link>
                </div>
            );
        },
        width: '12%'
    },
    {
        Header: 'アカウントID',
        accessor: 'accountId',
        disableSortBy: true,
        Cell: ({ row: { original } }) => {
            return (
                <div>
                    <Link to={`/user/account/${original?.account?.id}`}><u>{original?.account?.code}</u></Link>
                </div>
            );
        },
        width: '12%'
    },
    {
        Header: '名前-本名',
        accessor: 'fullName',
        disableSortBy: true,
        Cell: ({ row: { original } }) => {
            return (
                <div>
                    {original?.account?.user?.fullName}
                </div>
            );
        },
        width: '10%'
    },
    {
        Header: '分類',
        accessor: 'transactionForm',
        disableSortBy: true,
        Cell: ({ row: { original } }) => {
            return (
                <div>
                    {original?.transactionForm ? TRANSACTION_FORM_MAPPER[original?.transactionForm].text : ''}
                </div>
            );
        },
        width: '10%'
    },
    {
        Header: '会員料金プラン',
        accessor: 'membershipType',
        disableSortBy: true,
        Cell: ({ row: { original } }) => {
            return (
                <div>
                    {
                        original?.membershipType === MEMBERSHIP_TYPE.MONTH.key ? 
                            MEMBERSHIP_TYPE.MONTH.labelPayment : MEMBERSHIP_TYPE.YEAR.labelPayment
                    }
                </div>
            );
        },
        width: '8%'
    },
    {
        Header: '金額 (円)',
        accessor: 'totalPayment',
        disableSortBy: true,
        Cell: ({ row: { original } }) => {
            return (
                <div>
                    <FormatNumber value={original?.totalPayment} />
                </div>
            );
        },
        width: '5%'
    },
    {
        Header: '支払いステータス',
        accessor: 'status',
        disableSortBy: true,
        Cell: ({ row: { original } }) => {
            const findStatus = Object.keys(PURCHASE_STATUS).filter(key => PURCHASE_STATUS[key].key === original?.status)?.[0];
            return (
                findStatus && <div className={PURCHASE_STATUS[findStatus]?.colorClassName}>
                    {PURCHASE_STATUS[findStatus]?.label}
                </div>
            );
        },
        width: '8%'
    },
    {
        Header: 'アクション',
        accessor: 'action',
        disableSortBy: true,
        Cell: observer(({ row: { original } }) => {
            // store
            const { 
                modalStore: { hide, show },
                paymentStore: { confirmChangePaymentStatus, getDetailsTransaction, getAllTransaction, cancelPurchase },
                authStore: {userInfo}
            } = useStore();

            // function
            const handleChangePaymentStatus = async () => {
                try {
                    const res = await confirmChangePaymentStatus(original?.id);
                    if (res) {
                        const type = PAYMENT_TYPE.MEMBERSHIP;
                        await getAllTransaction({type});
                        hide();
                        ReactNotifications('success', MSG['inform.success.update']);
                        return;
                    }
                } catch (error) {
                    console.log(error);
                }
            }

            // modal
            const onShowConfirmChangeStatus = (message, type) => {
                show({
                    id: 'modal-alert',
                    isOpen: true,
                    header: '確認',
                    onCancel: () => {
                        hide();
                    },
                    children: (
                        <div className='font-bold max-width-960 pd-10'>
                            <div className='mg-t-10'>{message}</div>
                            <ModalFooter key={'modal-footer'}
                                saveButtonText={TEXT.YES}
                                cancelButtonText={TEXT.NO}
                                onConfirm={() => {
                                    if (type === 'paymentStatus') {
                                        handleChangePaymentStatus();
                                    } else if (type === 'cancelPurchase') {
                                        handleCancelPurchase();
                                    }
                                }}
                                onCancel={() => {
                                    hide();
                                }}
                                saveButtonClass='btn-bg-cs-1'
                            />
                        </div>
                    ),
                    type: 'small'
                });
            }

            const handleCancelPurchase = async () => {
                try {
                    const res = await cancelPurchase(original?.id);
                    if (res) {
                        const type = PAYMENT_TYPE.MEMBERSHIP;
                        await getAllTransaction({type});
                        hide();
                        ReactNotifications('success', MSG['inform.success.update']);
                        return;
                    }
                } catch (error) {
                    console.log(error);
                }
            }

            const onShowDetailsMembershipPayment = async () => {
                try {
                    await getDetailsTransaction(original?.id);
                    await show({
                        id: 'modal-alert',
                        isOpen: true,
                        header: '決済詳細',
                        onCancel: () => {
                            hide();
                        },
                        children: (
                            <div className='font-bold max-width-960 max-heigh-240'>
                                <DetailsPayment type='monthlyFee' />
                                <div className='d-flex justify-content-center align-items-center'>
                                    <button 
                                        onClick={() => {
                                            hide();
                                        }}
                                        className='btn-default btn-close-custom mg-tb-25'
                                    >{TEXT.CLOSE}</button>
                                </div>
                            </div>
                        ),
                        type: 'medium'
                    })
                } catch (error) {
                    console.log(error);
                }
                
            }
            return (
                <div className='d-flex align-items-center justify-content-center flex-gap-10'>
                    {
                        original?.status === PURCHASE_STATUS.WAIT_FOR_PAYMENT.key && userInfo?.role !== ROLE.STAFF.key ?
                            (
                                <>
                                    <button
                                        data-bs-toggle='tooltip'
                                        title={PURCHASE_STATUS.WAIT_FOR_PAYMENT.label}
                                        onClick={() => onShowConfirmChangeStatus(MSG['modal.tittle_alert_changePaymentStatus'], 'paymentStatus')}
                                        className='btn-icon btn-icon-orange'>
                                        <i className='fa-regular fa-square-check'></i>
                                    </button>
                                    <button
                                        data-bs-toggle='tooltip'
                                        title={PURCHASE_STATUS.CANCELED.label}
                                        onClick={() => onShowConfirmChangeStatus(MSG['modal.tittle_alert_cancelPurchase'], 'cancelPurchase')}
                                        className='btn-icon btn-icon-gray'>
                                        <i className='fa-solid fa-xmark'></i>
                                    </button>
                                </>
                            ) : (
                                <>
                                    <button className='btn-icon invisible'></button>
                                    <button className='btn-icon invisible'></button>
                                </>
                            )
                    }
                    <button
                        data-bs-toggle='tooltip'
                        title={TOOLTIP.VIEW_DETAILS}
                        type='button' className='btn-icon btn-icon-red' 
                        onClick={() => onShowDetailsMembershipPayment()}
                    >
                        <i className='fa-solid fa-eye'></i>
                    </button>
                </div>
            );
        }),
        width: '10%'
    }
]