import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import classNames from 'classnames';
import { FORMAT_DATE_TIME, INQUIRY_STATUS, MSG } from '../../core/configs/constants';
import moment from 'moment';
import { InquiryApi } from '../../apis';

const LineInquiryDetail = observer(() => {
    //other
    const inquiryApi = new InquiryApi();
    const urlParams = new URLSearchParams(window.location.search);
    const id = urlParams.get('id');
    let secret = urlParams.get('secret');

    // state
    const [inquiryInfo, setInquiryInfo] = useState(null);
    const [inquiryError, setInquiryError ] = useState(null);

    //lifecycle
    // lifecycle
    useEffect(() => {
        getData();
    }, [])

    const getData = async () => {
        let res;
        try {
            res = await inquiryApi.getInquiryDetail({ id, secret });
            setInquiryInfo(res?.data?.data || null);
        } catch (error) {
            setInquiryError(error?.response?.data || null);
        }
    }

    if(inquiryError) {
        return(
            <div className='login-screen vw-100 vh-100 d-flex justify-content-center align-items-center pd-lr-15'>
                <div className='container-form border shadow-box pd-tb-30 pd-lr-10 bg-body radius-div w-100 max-width-500
                    d-flex justify-content-center align-items-center flex-column'>
                    <i className='fa-solid fa-circle-xmark text-color-cs-red fs-cs-60'></i>
                    <div className='mg-t-30 fw-bolder fs-cs-16'>
                        { inquiryError?.message || MSG['api.response.server_error'] }
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className='d-flex justify-content-center align-items-center pd-lr-16 vw-100'>
            <div className='w-100 max-width-500'>
                <h2 className='fw-bold text-center'>お問い合わせ詳細</h2>
                <div className='d-flex flex-column mg-t-32'>
                    <div className='info-item row'>
                        <p className='fw-bold col-5'>送信日時:</p>
                        <p className='col-7 pd-0'>
                            {inquiryInfo?.createdAt ? moment(new Date(inquiryInfo?.createdAt)).format(FORMAT_DATE_TIME) : ''}
                        </p>
                    </div>
                    <div className='info-item row'>
                        <p className='fw-bold col-5'>送信者:</p>
                        <p className='col-7 pd-0'>{inquiryInfo?.fullName}</p>
                    </div>
                    <div className='info-item row'>
                        <p className='fw-bold col-5'>メールアドレス:</p>
                        <p className='col-7 pd-0'>{inquiryInfo?.email}</p>
                    </div>
                    <div className='info-item row'>
                        <p className='fw-bold col-5'>電話番号:</p>
                        <p className='col-7 pd-0'>{inquiryInfo?.phoneNumber}</p>
                    </div>
                </div>
                <div className='row'>
                    <div className='fw-bold col-5'>ステータス</div>
                    <div className={classNames('col-7 border-radius-8 text-color-cs-white', 
                        Object.values(INQUIRY_STATUS).find(status => status.key === inquiryInfo?.status)?.backgroundColorClassName)}
                    style={{width: 'fit-content'}}>
                        {Object.values(INQUIRY_STATUS).find(status => status.key === inquiryInfo?.status)?.label}
                    </div>
                </div>
                <div className='row mg-t-10 border-bottom-thin-color-cs-light-gray mg-lr-2 mg-t-30'></div>
                <div className='inquiry-content mg-t-10'>
                    <h5 className='fw-bold mg-t-30'>お問い合わせ内容</h5>
                    <p className='mg-t-15'>{inquiryInfo?.content}</p>
                </div>
            </div>
        </div>
    )
})

export default LineInquiryDetail;