import { action, flow, makeObservable, observable } from 'mobx';
import { InquiryApi } from '../apis';
import BaseStore from './BaseStore';



class InquiryStore extends BaseStore {
    inquiryList = [];
    inquiry = {};

    constructor(rootStore) {
        super();
        makeObservable(this, {
            inquiryList: observable,
            inquiry: observable,
            getAllInquiry: flow.bound,
            getInquiry: flow.bound,
            updateInquiry: flow.bound,
            deleteInquiry: flow.bound
        })
        this.rootStore = rootStore;
        this.api = new InquiryApi();
    }

    *getAllInquiry(searchParams) {
        try {
            const { size, page, sortDir, sortKey } = this.paging;
            const payload = { size, page, sortDir, sortKey, ...searchParams };
            const res = yield this.rootStore.apiStore.call(this.api, this.api.getAllInquiry, payload);
            if(res?.ok) {
                this.inquiryList = res?.data?.elements || [];
                this.setAttrObservable('paging', res?.data?.paginate, true, false);
            }
        } catch (error) {
            console.log(error);
        }
    }
    
    *getInquiry(id, payload) {
        try {
            const res = yield this.rootStore.apiStore.call(this.api, this.api.getInquiry, { id, payload });
            if(res?.ok) {
                this.inquiry = res?.data ?? {};
            }
        } catch (error) {
            console.log(error);
        }
    }

    *updateInquiry(id, payload) {
        try {
            const res = yield this.rootStore.apiStore.call(this.api, this.api.updateInquiry, { id, payload });
            if(res?.ok) {
                return true;
            }
        } catch (error) {
            console.log(error);
        }
    }

    *deleteInquiry(id, payload) {
        try {
            const res = yield this.rootStore.apiStore.call(this.api, this.api.deleteInquiry, { id, payload });
            return res?.ok ?? false;
        } catch (error) {
            console.log(error);
        }   
    }
    clean() {
        super.clean();
        this.inquiryList = [];
        this.inquiry = {};
    }
}

export default InquiryStore;
