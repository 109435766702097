import { observer } from 'mobx-react';
import { Outlet } from 'react-router-dom';
import { useStore } from '../../core/utils/hook';
import { useEffect } from 'react';

const ClearAuthentication = observer(() => {

    const { authStore: { clearAuthentication } } = useStore();

    useEffect(() => {
        clearAuthentication();
    }, [])

    return <Outlet/>
})

export default ClearAuthentication;