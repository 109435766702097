import ApiService from './ApiService';

class InquiryApi extends ApiService {
    constructor() {
        super('inquiry');
    }

    getAllInquiry(payload) {
        return this.get('', payload);
    }

    getInquiry({ id, payload }) {
        return this.get(id, payload);
    }

    updateInquiry({ id, payload }) {
        return this.put(id, payload)
    }

    deleteInquiry({ id, payload }) {
        return this.delete(id, payload);
    }

    getInquiryDetail({ id, secret }, payload) {
        return this.get(`line?id=${id}&secret=${secret}`, payload);
    }
}

export default InquiryApi;