/* eslint-disable max-len */
import { observer } from 'mobx-react';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { ReactNotifications, Table } from '../../components';
import { MSG, ROLE, USER_STATUS, SYSTEM_PATH, TEXT, TOOLTIP, SIDEBAR_TITLE } from '../../core/configs/constants';
import { useStore } from '../../core/utils/hook';
import { Link, useNavigate } from 'react-router-dom';
import AddAccountScreen from './AddAccountScreen';
import classNames from 'classnames';
import AccountListScreen from './AccountListScreen';
import { toJS } from 'mobx';
import { createAdminColumn, createMemberColumn } from './components/columns';
import ExportMemberlstHistoryModal from './components/ExportMemberlstHistoryModal';

const UserScreen = observer(props => {

    // other
    const navigate = useNavigate();

    // store
    const { 
        userStore: { clean, getAllUser, adminList, adminPaging, memberList, memberPaging, setAttrObservable, updateStatusUser, exportUser, deleteUser },
        modalStore: { hide, openWarningModal, show },
        authStore: {userInfo}
    } = useStore();

    // state
    const [selectedAdmin, setSelectedAdmin] = useState([]);
    const [selectedMember, setSelectedMember] = useState([]);
    const { register, handleSubmit, getValues } = useForm();

    const [isSelectedAllAdminCustom, setIsSelectedAllAdminCustom] = useState(false);
    const [isSelectedAllMemberCustom, setIsSelectedAllMemberCustom] = useState(false);
    // lifecycle
    const selectedAdminRowIds = useMemo(() => {
        const obj = {};
        selectedAdmin.forEach(item => {
            obj[item.id] = true;
        });
        return obj;
    }, [selectedAdmin])

    const selectedMemberRowIds = useMemo(() => {
        const obj = {};
        selectedMember.forEach(item => {
            obj[item.id] = true;
        });
        return obj;
    }, [selectedMember])

    useEffect(() => {
        onSearch();

        return () => {
            clean();
        }
    }, [])

    // modal
    const onShowConfirmChangeStatus = (id, status) => {
        const msg = status === USER_STATUS.INACTIVE.key ? MSG['modal.confirm_inactive_user']
            : MSG['modal.confirm_active_user'];
        openWarningModal(msg, () => onSubmitUpdate(1, id, { status }));
    }

    const onShowConfirmDeleteUser = (id) => {
        openWarningModal(MSG['modal.confirm_delete_user'], () => onSubmitUpdate(2, id));
    }

    const onShowAddAccount = (id) => {
        show({
            id: 'modal-add-account',
            isOpen: true,
            header: 'アカウント追加',
            onCancel: () => hide(),
            children: (
                <AddAccountScreen id={id} onSearch={onSearch} />
            ),
            type: 'medium'
        })
    }

    // function
    const onSearch = (data) => {
        setSelectedAdmin([]);
        setSelectedMember([]);
        clean();
        onFetchAdminData(data);
        onFetchMemberData(data);
    }

    const onFetchAdminData = (tableData) => {
        const role = getValues('role');
        setAttrObservable('adminPaging', tableData, true, false);
        getAllUser({
            ...getValues(),
            ...tableData,
            roles: role ? role != ROLE.MEMBER.key ? [role] : [-1] : [ROLE.SUPPER_ADMIN.key, ROLE.ADMIN.key, ROLE.STAFF.key]
        }, (res) => {
            setAttrObservable('adminList', res?.data?.elements);
            setAttrObservable('adminPaging', res?.data?.paginate, true, false);
        })
    }

    const onFetchMemberData = (tableData) => {
        const role = getValues('role');
        setAttrObservable('memberPaging', tableData, true, false);
        getAllUser({
            ...getValues(),
            ...tableData,
            roles: role ? role == ROLE.MEMBER.key ? [role] : [-1] : [ROLE.MEMBER.key]
        }, (res) => {
            setAttrObservable('memberList', res?.data?.elements);
            setAttrObservable('memberPaging', res?.data?.paginate, true, false);
        })
    }

    const onEditUser = (id) => {
        navigate(`${SYSTEM_PATH.USER_MANAGEMENT}/${id}`);
    }
    const onSubmitUpdate = async (mode, id, payload = null) => {
        let res;
        if(mode === 1) {
            res = await updateStatusUser(id, payload);
        } else if (mode === 2) {
            res = await deleteUser(id);
        }
        if(res) {
            ReactNotifications('success', mode === 1 ? MSG['inform.success.update'] : MSG['inform.success.delete']);
            onSearch();
            hide();
        } 
    }

    const onSubmitExportUser = async () => {
        if (adminList?.length || memberList?.length) {
            let adminIds = selectedAdmin.length ? [...selectedAdmin.map((item) => item?.id)] : [];
            let memberIds = selectedMember.length ? [...selectedMember.map((item) => item?.id)] : [];
            const payload = { ...getValues(), adminIds, memberIds };
            const res = await exportUser(payload);
            onSearch();
        }
    }

    const updateSelectUserList = (oldList, selectedIds = [], originalList = [], type) => {
        let data = [...oldList];
        originalList.forEach(userRow => {
            const index = data.findIndex(e => e.id === userRow.id);
            if (selectedIds.includes(String(userRow.id))) {
                if (index < 0) {
                    data = [...data, userRow];
                }
            } else {
                if (index > -1) {
                    data = [...data.slice(0, index), ...data.slice(index + 1)];
                }
            }
            
        })
        const dataIds = data.map(e => e.id);
        const isSelectedAll = originalList?.length ? originalList.every(e => dataIds.includes(e.id)) : false;
        type == ROLE.MEMBER.key ? setIsSelectedAllMemberCustom(isSelectedAll) : setIsSelectedAllAdminCustom(isSelectedAll);
       
        return data;
    }
    const handleSelectAdminUsers = (selectedRows, rows) => {
        const selectedIds = Object.keys(selectedRows);
        if (selectedIds?.length > 0) {
            setSelectedAdmin(oldAdminList => updateSelectUserList(oldAdminList, selectedIds, toJS(adminList), ROLE.ADMIN.key));
        } else {
            setSelectedAdmin([]);
            setIsSelectedAllAdminCustom(false);
        }
    }
    const handleSelectMemberUsers = (selectedRows, rows) => {
        const selectedIds = Object.keys(selectedRows);
        if (selectedIds?.length > 0) {
            setSelectedMember(oldMemberList => updateSelectUserList(oldMemberList, selectedIds, toJS(memberList), ROLE.MEMBER.key));
        } else {
            setSelectedMember([]);
            setIsSelectedAllMemberCustom(false);
        }
    }
    const onSelectedAllAdminChange = (value) => {
        setSelectedAdmin(oldAdminList => {
            let data = oldAdminList;
            toJS(adminList).forEach(userRow => {
                const index = data.findIndex(e => e.id === userRow.id);
                if (value) {
                    if (index < 0) data = [...data, userRow];
                } else {
                    if (index > -1) data = [...data.slice(0, index), ...data.slice(index + 1)];
                }
            })
            return data;
        })
        setIsSelectedAllAdminCustom(value);
    }
    const onSelectedAllMemberChange = (value) => {
        setSelectedMember(oldMemberList => {
            let data = oldMemberList;
            toJS(memberList).forEach(userRow => {
                const index = data.findIndex(e => e.id === userRow.id);
                if (value) {
                    if (index < 0) data = [...data, userRow];
                } else {
                    if (index > -1) data = [...data.slice(0, index), ...data.slice(index + 1)];
                }
            })
            return data;
        })
        setIsSelectedAllMemberCustom(value);
    }
    const onClickExportMemberlst = () => {
        show({
            id: 'modal-export-history',
            isOpen: true,
            header: '出力履歴を見る',
            onCancel: () => hide(),
            children: (
                <ExportMemberlstHistoryModal />
            ),
            type: 'medium min-width-800'
        })
    }

    // columns
    const adminColumns = createAdminColumn({ onShowConfirmChangeStatus, onShowConfirmDeleteUser, onEditUser, userInfo });
    const memberColumns = createMemberColumn({ onEditUser, onShowAddAccount, onShowConfirmDeleteUser, userInfo });
    
    return(
        <div className='user-screen'>
            <div className='container-title'>{SIDEBAR_TITLE.USER_MANAGEMENT}</div>
            <div className='container-search'>
                <form onSubmit={handleSubmit(onSearch)}>
                    <div className='row'>
                        <div className='d-flex col-6 align-items-center'>
                            <label className='col-3'>ユーザーID</label>
                            <input {...register('code')} className='col-8' />
                        </div>
                        <div className='d-flex col-6 align-items-center justify-content-end'>
                            <label className='col-3'>メールアドレス</label>
                            <input {...register('email')} className='col-8' />
                        </div>
                    </div>
                    <div className='row mg-t-20'>
                        <div className='d-flex col-6 align-items-center'>
                            <label className='col-3'>ユーザーネーム</label>
                            <input {...register('userName')} className='col-8' />
                        </div>
                        <div className='d-flex col-6 align-items-center justify-content-end'>
                            <label className='col-3'>名前-本名</label>
                            <input {...register('fullName')} className='col-8' />
                        </div>
                    </div>
                    <div className='row mg-t-20'>
                        {/* <div className='d-flex col-6 align-items-center'>
                            <label className='col-3'>役割</label>
                            <select {...register('role')} className='col-8'>
                                <option value={''}>すべて</option>
                                {
                                    Object.keys(ROLE).map(e => <option key={ROLE[e].key} value={ROLE[e].key} >{ROLE[e].label}</option>)
                                }
                            </select>
                        </div> */}
                        <div className='d-flex col-6 align-items-center'>
                            <label className='col-3'>ステータス</label>
                            <select {...register('status')} className='col-8'>
                                <option value={''}>すべて</option>
                                {
                                    Object.keys(USER_STATUS).map(e => 
                                        <option key={USER_STATUS[e].key} value={USER_STATUS[e].key}>{USER_STATUS[e].label}</option>
                                    )
                                }
                            </select>
                        </div>
                    </div>
                    <div className='text-center mg-t-30'>
                        <button type={'submit'} className={'btn-default btn-search-back'}>
                            <i className='fa-solid fa-magnifying-glass'></i>
                            <span className='mg-l-10'>{TEXT.SEARCH}</span>
                        </button>
                    </div>
                </form>
            </div>
            <div className='container-content'>
                {userInfo && userInfo.role !== ROLE.STAFF.key  && <div className='text-end'>
                    <button type={'button'} className={'btn btn-bg-cs-bold-green text-white pd-lr-30'}
                        onClick={onClickExportMemberlst}>
                        出力履歴を見る
                    </button>
                    <button type={'button'} className={classNames('btn btn-bg-cs-4 mg-l-15 text-white pd-lr-30', (!adminList?.length && !memberList?.length) && 'not-allowed')}
                        onClick={onSubmitExportUser}>
                        ユーザーエクスポート
                    </button>
                    <button type={'button'} className={'btn btn-bg-cs-1 mg-l-15 text-white pd-lr-30'}
                        onClick={() => navigate(SYSTEM_PATH.USER_ADD)}>
                        ユーザー追加
                    </button>
                    
                </div>}
                {
                    ( adminList?.length > 0 || memberList?.length > 0 ) ?
                        <div className='accordion mg-t-30' id='accordion-users'>
                            {
                                adminList?.length > 0 &&
                                <div className='accordion-item'>
                                    <h2 className='accordion-header' id='headingOne'>
                                        <button className='accordion-button collapsed rounded-pill fw-bolder' type='button'
                                            data-bs-toggle='collapse'
                                            data-bs-target='#collapse-admin'
                                            aria-expanded='false'
                                            aria-controls='collapse-admin'>
                                        管理者とスタッフ
                                        </button>
                                    </h2>
                                    <div id='collapse-admin' className='accordion-collapse collapse' aria-labelledby='headingOne'>
                                        <div className='accordion-body pd-lr-0'>
                                            <Table
                                                columns={adminColumns}
                                                data={adminList || []}
                                                disablePaging={false}
                                                enableServerSidePaging={true}
                                                initialTableState={adminPaging}
                                                onFetch={onFetchAdminData}
                                                className='lst-admin-table'
                                                enableSelectRow={true}
                                                useCustomSelectedAll={true}
                                                onSelectedChange={handleSelectAdminUsers}
                                                selectedRowIds={selectedAdminRowIds}
                                                rowKey={'id'}
                                                lstDisabledRowSelect={userInfo?.role === ROLE.STAFF.key ? adminList : []}    
                                                disableSubRowSelect={true}
                                                isSelectedAllCustom={isSelectedAllAdminCustom}
                                                isSelectedAll={false}
                                                onSelectedAllChange={onSelectedAllAdminChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                            }
                            {
                                memberList?.length > 0 && 
                                <div className='accordion-item mg-t-20'>
                                    <h2 className='accordion-header' id='headingOne'>
                                        <button className='accordion-button collapsed rounded-pill fw-bolder' type='button'
                                            data-bs-toggle='collapse'
                                            data-bs-target='#collapse-member'
                                            aria-expanded='false'
                                            aria-controls='collapse-member'>
                                        会員
                                        </button>
                                    </h2>
                                    <div id='collapse-member' className='accordion-collapse collapse' aria-labelledby='headingOne'>
                                        <div className='accordion-body pd-lr-0'>
                                            <Table
                                                columns={memberColumns}
                                                data={memberList || []}
                                                disablePaging={false}
                                                enableServerSidePaging={true}
                                                initialTableState={memberPaging}
                                                onFetch={onFetchMemberData}
                                                className='lst-member-table'
                                                enableSelectRow={true}
                                                useCustomSelectedAll={true}
                                                onSelectedChange={handleSelectMemberUsers}
                                                selectedRowIds={selectedMemberRowIds}
                                                rowKey={'id'}
                                                lstDisabledRowSelect={userInfo?.role === ROLE.STAFF.key ? memberList : []}
                                                disableSubRowSelect={true}
                                                isSelectedAllCustom={isSelectedAllMemberCustom}
                                                isSelectedAll={false}
                                                onSelectedAllChange={onSelectedAllMemberChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                        : <div className='text-center'>{MSG['inform.no_data']}</div>
                }

            </div>
        </div>
    )
})

export default UserScreen;