import { Chart } from 'react-chartjs-2';
import { CHART_LABELS_DEFAULT, DASHBOARD_TYPE } from '../../../../core/configs/constants';
import { useNavigate } from 'react-router-dom';
import { useEffect, useRef } from 'react';
import { useStore } from '../../../../core/utils/hook';
import { observer } from 'mobx-react';

const NewUsersByMonthStatistical = observer(({year}) => {

    //other
    const navigate = useNavigate();

    //store
    const { dashboardStore: { registedUserList, getDashboardNewUser } } = useStore();

    //life cycle
    useEffect(() => {
        fetchData();
    }, [year])

    //function
    const fetchData = async() => {
        await getDashboardNewUser({type: DASHBOARD_TYPE.OVERVIEW, year: year});
    }

    const data = {
        labels : CHART_LABELS_DEFAULT,
        datasets: [
            {
                label: '新規ユーザー数',
                data: registedUserList.map((item) => item.value),
                borderColor: '#0070c0',
                backgroundColor: '#0070c0',
            },
        ],
    };

    const handleBarClick = (event, elements) => {
        if (elements.length === 0) {
            // No bars were clicked
            return;
        }    
        const firstElement = elements[0];
        const index = firstElement.index;
        navigate(`/dashboard/new-user/${index+1}-${year}`)
    };

    const handleBarHover = (event, chartElement) => {
        event.native.target.style.cursor = chartElement[0] ? 'pointer' : 'default';
    }

    const options = {
        onClick: handleBarClick,
        onHover: handleBarHover,
        responsive: true,
        maintainAspectRatio: true,
        plugins: {
            legend: {
                position: 'bottom',
                display: false
            },
            title: {
                display: false,
            },
            tooltip: {
                callbacks: {
                    label: (context) => {
                        let label = '';
                        if (context.parsed.y) {
                            label = context.dataset.label + ': ' + context.parsed.y + ' 名'
                        }
                        return label;
                       
                    }
                }
            },
        },
    };

    return (
        <div className='d-flex justify-content-center align-items-center w-full height-500'>
            <Chart type='bar' options={options} data={data} />
        </div>
    );
});

export default NewUsersByMonthStatistical;