import { action, flow, makeObservable, observable } from 'mobx';
import { SubscriptionContractApi } from '../apis';
import BaseStore from './BaseStore';

class SubscriptionContractStore extends BaseStore {
    contractList = [];
    contract = {};
    histories = [];
    historyPaging = this.defaultPaging;

    constructor(rootStore) {
        super();
        makeObservable(this, {
            contractList: observable,
            contract: observable,
            histories: observable,
            historyPaging:observable,
            getAllContract: flow.bound,
            getContract: flow.bound,
            updateContract: flow.bound,
        })
        this.rootStore = rootStore;
        this.api = new SubscriptionContractApi();
    }

    *getAllContract(searchParams) {
        try {
            const { size, page, sortDir, sortKey } = this.paging;
            const payload = { size, page, sortDir, sortKey, ...searchParams };
            const res = yield this.rootStore.apiStore.call(this.api, this.api.getAllContract, payload);
            if(res?.ok) {
                this.contractList = res?.data?.elements || [];
                this.setAttrObservable('paging', res?.data?.paginate || {}, true, false);
            }
        } catch (error) {
            console.log(error);
        }
    }
    
    *getContract(id, searchParams) {
        try {
            const { size, page, sortDir, sortKey } = this.historyPaging;
            const payload = {size, page, sortDir, sortKey, ...searchParams}
            const res = yield this.rootStore.apiStore.call(this.api, this.api.getContract, { id, payload });
            if(res?.ok) {
                this.contract = res?.data?.contractInfo ?? {};
                this.histories = res?.data?.contractHistories?.elements ?? [];
                this.setAttrObservable('historyPaging', res?.data?.contractHistories?.paginate || {}, true, false);
            }
        } catch (error) {
            console.log(error);
        }
        
    }

    *updateContract({id, payload}) {
        try {
            const res = yield this.rootStore.apiStore.call(this.api, this.api.updateContract, { id, payload });
            if (res?.ok) {
                return true;
            }
            return false;
        } catch (error) {
            console.log(error)
        }
    }
    clean() {
        super.clean();
        this.contractList = [];
        this.contract = {};
    }
}

export default SubscriptionContractStore;
