import { EditorCustom } from '../../components';
import { useStore } from '../../core/utils/hook';
import { customEditEmailToolBarEditor, handleStringHtmlEncode } from '../../core/utils/common';
import { useEffect } from 'react';
import ReactNotifications from '../../components/Notifications/ReactNotifications';
import { observer } from 'mobx-react';
import { useForm } from 'react-hook-form';
import { MSG, TEXT, TYPE_GENERAL_SETTING } from '../../core/configs/constants';
import { useState } from 'react';
import yup from '../../core/utils/yupValidate';
import { yupResolver } from '@hookform/resolvers/yup';

const TemplateEmailScreen = observer(() => {

    // store
    const { generalSettingStore: { getGeneralSetting, setGeneralSetting } } = useStore();
    
    // state
    const [ type, setType ] = useState(TYPE_GENERAL_SETTING.TEMPLATE_EMAIL.SEND_CREATE_NEW_USER_ADMIN.key);
    const validateTemplateEmailScreen = yup.object().shape({
        title: yup.string(),
        value: yup.string()
    })
    const { 
        register, 
        handleSubmit,
        setValue, 
        getValues, 
        reset,
        formState: { isSubmitting } } = useForm({resolver: yupResolver(validateTemplateEmailScreen), mode: 'onChange'});

    // lifecycle
    useEffect(() => {
        fetchData();
    }, [type])

    // function
    const handleValueChanged = (event, editor) => {
        let value = editor.getData() || '';
        setValue('value', value, { shouldValidate: true });
    };

    const fetchData = async () => {
        try {
            const res = await getGeneralSetting(type);
            if (res?.ok) {
                const { title, value } = res.data;
                reset({ title, value })
            }
        } catch (error) {
            console.log(error);
        }
    };

    const handleClickSubmit = async (data) => {
        if (data?.value) {
            data.value = handleStringHtmlEncode(data.value);
        }

        const res = await setGeneralSetting(type, data);
        if (res?.ok) {
            ReactNotifications('success', MSG['inform.success.update']);
        }
    }

    return (
        <div className='ck-custom'>
            <div className='container-title'>メールテンプレート</div>
            <div className='mg-b-10 d-flex align-items-center justify-content-between'>
                <select onChange={(event) => setType(event.target.value)} className='pd-r-20'>
                    {
                        Object.values(TYPE_GENERAL_SETTING.TEMPLATE_EMAIL).map(({ key, label }) => {
                            return (
                                <option key={key} value={key}>
                                    {label}
                                </option>
                            )
                        })
                    }
                </select>
                <button type={'submit'} className={'btn btn-bg-cs-4 width-120'}>
                    <a className='text-white' 
                        href='https://raise-sml.s3.ap-northeast-1.amazonaws.com/files/Variables_Template_Email.xlsx'>変数説明</a>
                </button>
            </div>
            <div className='container-content'>

                <form onSubmit={handleSubmit(handleClickSubmit)}>
                    <div className='d-flex flex-column flex-gap-10'>
                        <label>タイトル</label>
                        <input {...register('title')} className='w-100' />
                    </div>
                    <div className='d-flex flex-column flex-gap-10 mg-t-10'>
                        <label>内容</label>
                        <EditorCustom
                            data={getValues('value')}
                            onChange={(event, editor) => handleValueChanged(event, editor)}
                            toolbarCustomItems={customEditEmailToolBarEditor}
                            elementToElements={[
                                {
                                    model: 'paragraph',                          
                                    view: 'div',
                                    converterPriority: 'high'
                                }
                            ]}
                            onReady={(editor) => {
                                editor.editing.view.document.on( 'enter', ( evt, data ) => {
                                    if ( data.isSoft ) {
                                        editor.execute( 'enter' );
                                    } else {
                                        editor.execute( 'shiftEnter' );
                                    }
                        
                                    data.preventDefault();
                                    evt.stop();
                                    editor.editing.view.scrollToTheSelection();
                                }, { priority: 'high' } );
                            }}
                        />
                    </div>
                    <div className='d-flex justify-content-center align-items-center flex-column mt-3'>
                        <button 
                            className='btn-bg-cs-1 btn-default'
                            onClick={handleClickSubmit}
                            disabled={isSubmitting}>
                            {TEXT.UPDATE}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
});

export default TemplateEmailScreen;