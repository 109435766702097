import { observer } from 'mobx-react';
import { useStore } from '../../core/utils/hook';
import { STATUS_CARD, TAB_TITLE } from '../../core/configs/constants';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import CardsInPossession from './components/CardsInPossession';

import './style.scss';
import { toJS } from 'mobx';
import AccountInfo from './components/AccountInfo';

const ViewCardList = observer((props) => {


    // others
    const navigate = useNavigate();
    const { id } = useParams();


    // store
    const {
        accountStore: { account, getAccount, getlistCardOfMember, listCardOfMember, setAttrObservable }
    } = useStore();


    //lifecycle
    useEffect(() => {
        const getData = async () => {
            if (id) {
                await getAccount(id);
                await getlistCardOfMember(id, { status: [STATUS_CARD.OWNING.key] });
            }
        }
        getData();

        return () => {
            setAttrObservable('account', {})
            setAttrObservable('listCardOfMember', {});
        }
    }, [])

    //function

    const handleTabChange = async (index) => {
        if (index === 0) {
            await getAccount(id);
        }

        if (index === 1) {
            return await getlistCardOfMember(id, { status: [STATUS_CARD.OWNING.key] });
        }
    }

    return (
        <div className='view-card-screen'>
            <div className='container-title'>{`#${account?.code} アカウントの詳細 `}</div>

            <div className='container-content'>
                <Tabs onSelect={handleTabChange} defaultIndex={0} className='tabs-container'>
                    <TabList className='row mg-lr-0 list-unstyled tab-list'>
                        <Tab className='col-6 tab'>{TAB_TITLE.EDIT_ACCOUNT }</Tab>
                        <Tab className='col-6 tab'>{TAB_TITLE.CARD_HOLD} ({listCardOfMember?.cards?.paginate?.totalRecord || 0})</Tab>
                    </TabList>
                    <TabPanel>
                        <AccountInfo />
                    </TabPanel>
                    <TabPanel>
                        <CardsInPossession listCardOwner={toJS(listCardOfMember?.cards?.elements || [])} />

                    </TabPanel>
                </Tabs>
            </div>
        </div>
    )
})

export default ViewCardList;