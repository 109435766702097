import './style.scss';

const PagingInfo = (props) => {

    const { tableState } = props;
    const from = tableState?.pageIndex * tableState?.pageSize;
    let to = from + tableState?.pageSize;
    if (to > tableState?.totalRecord){
        to = tableState?.totalRecord;
    }

    return(
        <span className='paging-info'>
            {tableState?.totalRecord} 件中 {from + 1}~{to} 件を表示
        </span>
    )
}

export default PagingInfo;