import { Route, Routes } from 'react-router-dom';
import ContainerBody from '../components/Container/ContainerBody';
import { PAYMENT_TYPE, SCREEN_MODE, SIDEBAR_TITLE, SYSTEM_PATH } from '../core/configs/constants';
import NotFoundScreen from '../screens/404/NotFoundScreen';
import ChangePasswordScreen from '../screens/Password/ChangePasswordScreen';
import UserScreen from '../screens/User/UserScreen';
import DashBoardScreen from '../screens/Dashboard/DashboardScreen';
import SettingUserScreen from '../screens/User/SettingUserScreen';
import InquiryManagement from '../screens/Inquiry/InquiryScreen';
import ListCardScreen from '../screens/Card/CardScreen';
import SettingCardScreen from '../screens/Card/SettingCardScreen';
import GeneralSettingScreen from '../screens/GeneralSetting/GeneralSettingScreen';
import { TYPE_GENERAL_SETTING } from '../core/configs/constants';
import PaymentScreen from '../screens/Payment/PaymentScreen';
import SubscriptionContractScreen from '../screens/SubscriptionContract/SubscriptionContractScreen';
import ViewCardList from '../screens/Card/ViewCardList';
import NewUsersByMonthDetailsStatistical from '../screens/Dashboard/components/Details/NewUsersByMonthDetailsStatistical';
import CardByMonthDetailsStatistical from '../screens/Dashboard/components/Details/CardByMonthDetailsStatistical';
import ListPremiumMember from '../screens/Dashboard/components/Details/ListPremiumMember';
import OtherSettingsScreen from '../screens/OtherSettings/OtherSettingsScreen';
import AccountListScreen from '../screens/User/AccountListScreen';
import BuyCardDetailScreen from '../screens/Payment/BuyCardDetailScreen';
import ViewUserAndCardpurchase from '../screens/User/ViewUserAndCardpurchase';
import ContractDetailScreen from '../screens/SubscriptionContract/ContractDetailScreen';
import TemplateEmailScreen from '../screens/TemplateEmail/TemplateEmailScreen';
import BuyHolderDetailScreen from '../screens/Payment/BuyHolderDetailScreen';
import CardTypeListScreen from '../screens/CardType/CardTypeListScreen';


const Root = () => {
    return (
        <Routes>
            <Route element={<ContainerBody />}>
                {/* change password */}
                <Route path={SYSTEM_PATH.CHANGE_PASSWORD} element={<ChangePasswordScreen />} />

                {/* dashboard */}
                <Route path={SYSTEM_PATH.HOME} element={<DashBoardScreen />} />
                <Route path={SYSTEM_PATH.DASHBOARD} element={<DashBoardScreen />} />
                <Route path={SYSTEM_PATH.DASHBOARD_DETAILS_NEW_USER} element={<NewUsersByMonthDetailsStatistical />} />
                <Route path={SYSTEM_PATH.DASHBOARD_DETAILS_CARD} element={<CardByMonthDetailsStatistical />} />
                <Route path={SYSTEM_PATH.DASHBOARD_DETAILS_PAID_MEMBER} element={<ListPremiumMember />} />

                {/* user */}
                <Route path={SYSTEM_PATH.USER_MANAGEMENT} element={<UserScreen />} />
                <Route path={SYSTEM_PATH.USER_ADD} element={<SettingUserScreen mode={SCREEN_MODE.ADD} />} />
                <Route path={SYSTEM_PATH.DETAIL_OF_ACCOUNT} element={<ViewCardList />} />
                <Route path={SYSTEM_PATH.USER_ACCOUNT_LIST} element={<AccountListScreen />} />
                <Route path={SYSTEM_PATH.USER_UPDATE} element={<ViewUserAndCardpurchase mode={SCREEN_MODE.EDIT} />} />

                {/* card type */}
                <Route path={SYSTEM_PATH.CARD_TYPE_MANAGEMENT} element={<CardTypeListScreen />} />

                {/* card */}
                <Route path={SYSTEM_PATH.CARD_MANAGEMENT} element={<ListCardScreen />} />
                <Route path={SYSTEM_PATH.CARD_ADD} element={<SettingCardScreen mode={SCREEN_MODE.ADD} />} />
                <Route path={SYSTEM_PATH.CARD_UPDATE} element={<SettingCardScreen mode={SCREEN_MODE.EDIT} />} />

                {/* payment management */}
                <Route path={SYSTEM_PATH.CARD_PAYMENT_MANAGEMENT} element={<PaymentScreen paymentType={PAYMENT_TYPE.CARD} />} />
                <Route path={SYSTEM_PATH.HOLDER_PAYMENT_MANAGEMENT} element={<PaymentScreen paymentType={PAYMENT_TYPE.HOLDER} />} />
                <Route path={SYSTEM_PATH.MEMBERSHIP_PAYMENT_MANAGEMENT} element={<PaymentScreen paymentType={PAYMENT_TYPE.MEMBERSHIP} />} />
                <Route path={SYSTEM_PATH.BUY_CARD_DETAIL} element={<BuyCardDetailScreen />} />
                <Route path={SYSTEM_PATH.BUY_HOLDER_DETAIL} element={<BuyHolderDetailScreen />} />

                {/* subscription contract */}
                <Route path={SYSTEM_PATH.SUBSCRIPTION_CONTRACT_MANAGEMENT} element={<SubscriptionContractScreen />} />
                <Route path={SYSTEM_PATH.SUBSCRIPTION_CONTRACT_DETAIL} element={<ContractDetailScreen />} />

                {/* inquiry */}
                <Route path={SYSTEM_PATH.INQUIRY_MANAGEMENT} element={<InquiryManagement />} />

                {/* template email */}
                <Route path={SYSTEM_PATH.TEMPLATE_EMAIL} element={<TemplateEmailScreen />} />

                {/* general setting */}
                <Route
                    path={SYSTEM_PATH.GENERAL_SETTING_TERM}
                    element={<GeneralSettingScreen
                        pageTitle={SIDEBAR_TITLE.GENERAL_SETTING.TERMS}
                        type={TYPE_GENERAL_SETTING.TERMS} />} />
                <Route path={SYSTEM_PATH.GENERAL_SETTING_ACT}
                    element={<GeneralSettingScreen
                        pageTitle={SIDEBAR_TITLE.GENERAL_SETTING.ACT_OF_SPECIFIED_COMMERCIAL_TRANSACTION}
                        type={TYPE_GENERAL_SETTING.ACT_OF_SPECIFIED_COMMERCIAL_TRANSACTION} />} />
                <Route path={SYSTEM_PATH.GENERAL_SETTING_PRIVACY}
                    element={<GeneralSettingScreen
                        pageTitle={SIDEBAR_TITLE.GENERAL_SETTING.PRIVACY_POLICY}
                        type={TYPE_GENERAL_SETTING.PRIVACY_POLICY} />} />
                <Route path={SYSTEM_PATH.GENERAL_SETTING_ABOUT}
                    element={<GeneralSettingScreen
                        pageTitle={SIDEBAR_TITLE.GENERAL_SETTING.ABOUT_US}
                        type={TYPE_GENERAL_SETTING.ABOUT_US} />} />

                {/* Other setting */}
                <Route path={SYSTEM_PATH.OTHER_SETTING} element={<OtherSettingsScreen />} />

                {/* not found */}
                <Route path="*" element={<NotFoundScreen />} />
            </Route>
        </Routes>
    );
};

export default Root;
