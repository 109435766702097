import { EditorCustom } from '../../components';
import { useStore } from '../../core/utils/hook';
import { customFullToolBarEditor } from '../../core/utils/common';
import { useEffect } from 'react';
import ReactNotifications from '../../components/Notifications/ReactNotifications';
import { observer } from 'mobx-react';
import { useForm } from 'react-hook-form';
import { MSG, SYSTEM_PATH, TEXT } from '../../core/configs/constants';

const GeneralSettingScreen = observer(({pageTitle, type}) => {

    // store
    const { generalSettingStore: { getGeneralSetting, setGeneralSetting } } = useStore();

    // state
    const { handleSubmit, formState: { isSubmitting }, setValue, getValues, reset } = useForm();

    // lifecycle
    useEffect(() => {
        fetchData();
    }, [type])

    // function
    const handleValueChanged = (event, editor) => {
        let value = editor.getData() || '';
        setValue('value', value, { shouldValidate: true });
    };

    const fetchData = async () => {
        try {
            const res = await getGeneralSetting(type);
            if (res?.ok) {
                reset({ value: res.data.value })
            }
        } catch (error) {
            console.log(error);
        }
    };

    const handleClickSubmit = async (data) => {
        const res = await setGeneralSetting(type, data );
        if (res?.ok) {
            ReactNotifications('success', MSG['inform.success.update']);
        }
    }

    return (
        <div className="ck-custom">
            <div className="container-title">{pageTitle}</div>

            <form onSubmit={handleSubmit(handleClickSubmit)} className="container-content">
                <EditorCustom
                    data={getValues('value')}
                    onChange={(event, editor) => handleValueChanged(event, editor)}
                    toolbarCustomItems={customFullToolBarEditor}
                />
                <div className='d-flex justify-content-center align-items-center flex-column mt-3'>
                    <button 
                        className='btn-bg-cs-1 btn-default'
                        onClick={handleClickSubmit}
                        disabled={isSubmitting}>
                        {TEXT.UPDATE}
                    </button>
                </div>
            </form>
        </div>
    );
});

export default GeneralSettingScreen;