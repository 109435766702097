import { observer } from 'mobx-react';
import { TransactionConfirmApi } from '../../apis';
import { useEffect, useMemo } from 'react';
import { useState } from 'react';
import { AIRDROP_STATUS, MSG, PURCHASE_STATUS, SYSTEM_PATH, TRANSACTION_CONFIRM_SCREEN } from '../../core/configs/constants';
import classNames from 'classnames';
import { ReactNotifications } from '../../components';

const TransactionConfirmScreen = observer(({ type }) => {

    // other
    const transactionConfirmApi = new TransactionConfirmApi();
    const urlParams = new URLSearchParams(window.location.search);
    const id = urlParams.get('id');

    // Lấy giá trị của tham số 'secret' từ URL
    let secret = urlParams.get('secret');

    // state
    const [ transactionInfo, setTransactionInfo ] = useState(null);
    const [ transactionError, setTransactionError ] = useState(null);
    const transactionStatus = useMemo(() => {
        if(transactionInfo) {
            if(type !== TRANSACTION_CONFIRM_SCREEN.ACCEPT) {
                if(transactionInfo.status == PURCHASE_STATUS.SUCCESS.key) {
                    return Object.values(AIRDROP_STATUS).filter(val => val.key == transactionInfo.airdropStatus)[0] || null;
                } else {
                    let errorText = Object.values(PURCHASE_STATUS)
                        .filter(val => val.key == transactionInfo.status)[0]?.errorTextUpdateAirdropStatus || '';
                    setTransactionError({
                        message: errorText
                    })
                    
                }             
            }
            return Object.values(PURCHASE_STATUS).filter(val => val.key == transactionInfo.status)[0] || null;
        }
        return null;
    }, [transactionInfo])

    // lifecycle
    useEffect(() => {
        getData();
    }, [])

    const getData = async () => {
        let res;
        try {
            res = await transactionConfirmApi.getTransactionInfo({ id, secret });
            setTransactionInfo(res?.data?.data || null);
        } catch (error) {
            setTransactionError(error?.response?.data || null);
        }
    }

    const onAccept = async () => {
        let res;
        try {
            if(type === TRANSACTION_CONFIRM_SCREEN.ACCEPT) {
                res = await transactionConfirmApi.transactionConfirmAccept({ id, secret });
            } else {
                res = await transactionConfirmApi.transactionConfirmAirdropStatus({ id, secret });
            }
            if(res) {
                getData();
            }
        } catch (error) {
            setTransactionError(error?.response?.data || null);
        }
    }

    const onReject = () => {
        setTransactionInfo({
            ...transactionInfo,
            status: PURCHASE_STATUS.FAILED.key
        })
    }

    if(transactionError) {
        return(
            <div className='login-screen vw-100 vh-100 d-flex justify-content-center align-items-center pd-lr-15'>
                <div className='container-form border shadow-box pd-tb-30 pd-lr-10 bg-body radius-div w-100 max-width-500
                    d-flex justify-content-center align-items-center flex-column'>
                    <i className='fa-solid fa-circle-xmark text-color-cs-red fs-cs-60'></i>
                    <div className='mg-t-30 fw-bolder fs-cs-16'>
                        { transactionError?.message || MSG['api.response.server_error'] }
                    </div>
                    <TransactionInfo transactionInfo={transactionInfo}/>
                </div>
            </div>
        )
    }

    if(transactionInfo) {
        return(
            <div className='login-screen vw-100 vh-100 d-flex justify-content-center align-items-center pd-lr-15'>
                <div className='container-form border shadow-box pd-tb-30 pd-lr-10 bg-body radius-div w-100 max-width-500
                    d-flex justify-content-center align-items-center flex-column'>
                    {
                        transactionStatus && <>
                            <i className={classNames('fs-cs-60', transactionStatus.icon, transactionStatus.colorIcon)}></i>
                            {
                                transactionStatus.textIcon && <div className='mg-t-30 fw-bolder fs-cs-16'>
                                    { transactionStatus.textIcon }
                                </div>
                            }
                            
                        </>
                    }
                    <TransactionInfo transactionInfo={transactionInfo}/>
                    {
                        (
                            transactionStatus && 
                            (type === TRANSACTION_CONFIRM_SCREEN.ACCEPT && transactionStatus.key == PURCHASE_STATUS.WAIT_FOR_PAYMENT.key) || 
                            (
                                type === TRANSACTION_CONFIRM_SCREEN.AIRDROP && 
                                transactionInfo.status == PURCHASE_STATUS.SUCCESS.key && 
                                transactionStatus.key == AIRDROP_STATUS.WAITING_PAYMENT.key
                            )
                        ) &&
                        <>
                            <div className='mg-t-30'>
                                この購入取引のステータスを本当に変更したいですか？
                            </div>
                            <div className='d-flex align-items-center flex-gap-20 mg-t-20'>
                                <button className='btn-default btn-cancel' onClick={onReject}>いいえ</button>
                                <button className='btn-default btn-bg-cs-1' onClick={onAccept}>はい</button>
                            </div>
                        </>
                    }
                </div>
            </div>
        )
    }
})

const TransactionInfo = ({ transactionInfo }) => {

    const onClickCopyButton = async (value, text = '') => {
        try {
            await navigator.clipboard.writeText(value);
            text && ReactNotifications('success', text);
        } catch (error) {
            console.log(error);
        }
    }

    return (
        transactionInfo &&
        <div className='transaction-confirm-info mg-t-30'>
            {
                transactionInfo.code && 
                <div className='d-flex align-items-center flex-wrap'>
                    <span className='fw-bold width-120'>取引ID</span>
                    <span>{transactionInfo.code}</span>
                </div>
            }
            {
                transactionInfo.airdrop && 
                <div className='d-flex align-items-center flex-wrap mg-t-5'>
                    <span className='fw-bold width-120'>Airdrop総量</span>
                    <span>
                        <span>{transactionInfo.airdrop} SML</span>
                        <span
                            role='button'
                            onClick={() => onClickCopyButton(transactionInfo.airdrop, MSG['inform.airdrop.clipboard'])}
                            className='mg-l-10'>
                            <i className='fa-regular fa-copy text-color-cs-red'></i>
                        </span>
                    </span>
                </div>
            }
            {
                transactionInfo.dropAddress && 
                <div className='d-flex align-items-center flex-wrap mg-t-5'>
                    <span className='fw-bold width-120'>Drop先アドレス</span>
                    <span>
                        <span>{transactionInfo.dropAddress}</span>
                        <span
                            role='button'
                            onClick={() => onClickCopyButton(transactionInfo.dropAddress, MSG['inform.dropAddress.clipboard'])}
                            className='mg-l-10'>
                            <i className='fa-regular fa-copy text-color-cs-red'></i>
                        </span>
                    </span>
                </div>
            }
        </div>
    )
}

export default TransactionConfirmScreen;