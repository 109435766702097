/* eslint-disable indent */
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { ACCOUNT_STATUS, MSG, ROLE, STATUS_CARD, SYSTEM_PATH, TEXT, TOOLTIP } from '../../../core/configs/constants';
import { useStore } from '../../../core/utils/hook';
import { yupResolver } from '@hookform/resolvers/yup';
import yup from '../../../core/utils/yupValidate'
import $ from 'jquery';

// // store

const ModalAssign = observer((props) => {


    //propd
    const {
        handleAssignCard = () => { },
    } = props;
    //state
    const {
        accountStore: { getAllAccount, accountList },
        modalStore: { hide, openWarningModal, show }
    } = useStore();

    // // state

    const validateSchema = yup.object().shape({
        accountId: yup.string().required(MSG['error.required']),
    })

    const { register, handleSubmit, getValues, setValue,
        formState: { errors } } = useForm({ resolver: yupResolver(validateSchema), mode: 'onChange' });


    // lifecycle
    useEffect(() => {
        const getData = async () => {
            await getAllAccount({ size: -1, status: ACCOUNT_STATUS.SUBSCRIBED.key });
        }
        getData();

    }, [])

    useEffect(() => {
        if(accountList.length > 0) {
            $('.selectpicker').selectpicker();
        }
    }, [accountList])

    //function

    const handleCancel = () => {
        hide();
    }
    const onSubmitAssignUser = async (value) => {
        await handleAssignCard(value);
    }

    return (
        <div className='font-bold resize width-670'>
            <div className='pd-lr-20 pd-tb-20'>
                <form onSubmit={handleSubmit(onSubmitAssignUser)}>
                    <div className='row mg-t-20 pd-lr-10'>
                        <label className='col-12 pd-0 field-required'>選択されたカードの保有者となるユーザーを一名指定してください。</label>
                        <div className='col-12 pd-0 mg-t-5'>
                            <select {...register('accountId')} className='w-100 selectpicker custom-filter-select'
                                data-live-search-placeholder="ユーザーネームまたはアカウントIDで検索" data-live-search="true"
                                data-none-results-text="データはありません">
                                <option value={''}>何も選択されていません。</option>
                                {
                                    // eslint-disable-next-line max-len
                                    accountList?.length > 0 &&
                                    accountList.map(e =>
                                        <option key={e?.id} value={e?.id} >{`(${e?.code}) ${e?.userName}`}</option>
                                    )
                                }
                            </select>
                            {
                                errors.accountId &&
                                <div className='text-danger fs-error mg-t-5 pd-0'>{errors.accountId?.message}</div>
                            }
                        </div>
                    </div>
                    <div className='d-flex justify-content-center align-items-center mg-t-40'>
                        <button type='button' className='btn btn-cs-3 width-150' onClick={handleCancel}>{TEXT.CANCEL}</button>
                        <button type='submit' className='btn btn-bg-cs-1 width-150 mg-l-50' >
                            カードを作る
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
})

export default ModalAssign;