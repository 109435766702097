export const SYSTEM_PATH = {
    LOGIN: '/login',
    FORGET_PASSWORD: '/forget-password',
    SET_PASSWORD: '/set-password',
    // change password
    CHANGE_PASSWORD: '/change-password',
    // confirm email
    CONFIRM_EMAIL: '/confirm-email',
    //home
    HOME: '/',
    // dashboard
    DASHBOARD: '/dashboard',
    DASHBOARD_DETAILS_NEW_USER: '/dashboard/new-user/:time',
    DASHBOARD_DETAILS_CARD: '/dashboard/realease-card/:time',
    DASHBOARD_DETAILS_PAID_MEMBER: '/dashboard/paid-member/:time',
    // user
    USER_MANAGEMENT: '/user',
    USER_ADD: '/user/add',
    USER_UPDATE: '/user/:id',
    USER_ACCOUNT_LIST: '/user/account-list/:id',
    DETAIL_OF_ACCOUNT: '/user/account/:id',
    //Inquiry
    INQUIRY_MANAGEMENT: '/inquiry',
    // general setting
    GENERAL_SETTING_TERM: '/general-setting/term',
    GENERAL_SETTING_ACT: '/general-setting/act',
    GENERAL_SETTING_PRIVACY: '/general-setting/privacy',
    GENERAL_SETTING_ABOUT: '/general-setting/about',
    // card
    CARD_MANAGEMENT: '/card',
    CARD_ADD: '/card/add',
    CARD_UPDATE: '/card/:id',
    CARD_LIST_OF_MEMBER: '/card-list-of-member/:id',
    // payment management
    CARD_PAYMENT_MANAGEMENT: '/buy-card',
    HOLDER_PAYMENT_MANAGEMENT: '/holder-payment',
    MEMBERSHIP_PAYMENT_MANAGEMENT: '/membership-payment',
    BUY_CARD_DETAIL: '/buy-card/:id',
    BUY_HOLDER_DETAIL: '/buy-holder/:id',
    //Subcription contract
    SUBSCRIPTION_CONTRACT_MANAGEMENT: '/subscription-contract',
    SUBSCRIPTION_CONTRACT_DETAIL: '/subscription-contract/:id',
    // other setting
    OTHER_SETTING: '/other-settings',
    // template email
    TEMPLATE_EMAIL: '/template-email',
    // transaction confirm
    TRANSACTION_CONFIRM_ACCEPT: '/transaction-confirm/accept',
    TRANSACTION_CONFIRM_AIRDROP: '/transaction-confirm/airdrop-status',
    // line inquiry detail
    LINE_INQUIRY_DETAIL: '/line-inquiry-detail',
    // card type
    CARD_TYPE_MANAGEMENT: '/card-type'
};

export const MSG = {
    //#region Api message
    'api.response.worng_format': '応答本文は正しい形式ではありません',
    'api.response.no_message': 'エラーメッセージの応答はありません',
    'api.response.server_error':
        'サーバ接続で問題が発生しました。\nお手数ですが、もう一度接続してみて下さい。',
    'api.response.no_network':
        'サーバ接続で問題が発生しました。\nお手数ですが、もう一度接続してみて下さい。',
    'api.response.authorization': '再度ログインして下さい。',
    //#endregion Api message

    //#region inform
    'inform.no_data': 'データが見つかりません。',
    'inform.success.create': '新規登録が完了しました。',
    'inform.success.update': '更新が完了しました。',
    'inform.success.delete': '削除が完了しました。',
    'inform.success.forget_password': 'メールを送信しました! \n パスワード再設定用のメールを送信しました。\n メール記載のリンクをクリックし、パスワード再設定に進んでください。',
    'inform.success.assign_card': 'カード配布が完了しました。',
    'inform.airdrop.clipboard': 'Airdropの値がコピーされました。',
    'inform.dropAddress.clipboard': 'Drop先アドレスの値がコピーされました。',
    //#endregion

    //#region validate error message
    'error.required': '必須項目をご入力ください。',
    'error.email_format': 'メールアドレスの形式は正しくありません。再度確認してください。',
    'error.password_format':
        'パスワードの形式は正しくありません。再度確認してください。\n パスワードの条件は以下となります。\n・８桁以上であること \n・少なくとも１桁の大文字を含むこと \n・少なくとも１桁の小文字を含むこと \n・少なくとも１桁の数字を含むこと \n・少なくとも１桁の記号を含むこと',
    'error.furigana_format': 'フリガナでご入力ください。',
    'error.confirm_password_not_match': '新しいパスワードとパスワードの確認が一致しません。再度入力してください。',
    'error.number': '有効な数値を入力してください',
    'eror.number.positive': '数量は正の数である必要があります',
    'error.length_9': '最大9文字までしか入力できません。',
    'error.length_12': '最大12文字までしか入力できません。',
    'error.length_24': '最大24文字までしか入力できません。',
    'error.length_150': '最大150文字までしか入力できません。',
    'error.phone_number_length': '最大11文字までしか入力できません。',
    'error.address_length': '最大54文字までしか入力できません。',
    'error.phone_number_format': '数字で入力してください。',
    'error.image_format': 'サポートされているファイル形式はJPGとPNGだけです。もう一度お試しください。',
    'error.number_3': '最大3文字までしか入力できません。',
    'error.number_10': '最大10文字までしか入力できません。',
    'error.number_12': '最大12文字までしか入力できません。',
    'error.number_min_1': '「発行枚数」は一枚以上である必要があります',
    'error.string_max_length': max => `${max}文字以内で入力してください。`,
    //#endregion

    //#region modal
    'modal.confirm_active_user': 'このユーザーを有効にしますが、よろしいですか。有効ユーザーはシステムにログインできます。',
    'modal.confirm_inactive_user': 'このユーザーを無効にしますが、よろしいですか。無効ユーザーはシステムにログインできなくなります。',
    'modal.confirm_delete_user': 'このユーザーを削除しますが、よろしいですか。',
    'modal.confirm_delete_account': 'このアカウントを削除しますが、よろしいですか。',
    'modal.tittle_alert_changeStatusAIRDrop': 'この購入取引のステータスを「送金完了」に更新しますが、よろしいですか。',
    'modal.tittle_alert_changePaymentStatus': 'この取引のステータスを「成功」に変更しますが、よろしいですか。',
    'modal.tittle_alert_cancelPurchase': '本取引をキャンセルしてよろしいでしょうか？',
    'modal.confirm_active_card_type': 'このカード種類を有効にしてよろしいですか？',
    'modal.confirm_inactive_card_type': 'このカード種類を無効にしてよろしいですか？',
    'modal.confirm_delete_card_type': 'このカード種類を削除してよろしいですか？'
    //#endregion
};

export const DEFAULT_PAGE_SIZE = 10;
export const PAGE_SIZE_OPTIONS = [10, 50, 100, 500, 1000];
// There are alot of table in system. Example: Document, Form difinition, User, Tenant.
export const TABLE_CODE = {
    DOCUMENT: 0
}

export const SCREEN_MODE = {
    ADD: 0,
    EDIT: 1,
    DETAIL: 2
}

export const WIDTH_COLUMN_SELECT_TABLE = '50px';

export const FORMAT_DATE = 'YYYY/MM/DD';
export const FORMAT_DATE_TIME = 'YYYY/MM/DD HH:mm:ss';
export const FORMAT_DATE_ISO = 'YYYY-MM-DD';

export const AVATAR_DEFAULT_URL = '/images/avatar_default.png';

export const IMAGE_DEFAULT_URL = '/images/image_default.png';

export const CHART_LABELS_DEFAULT = ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月']

export const TAB_TITLE = {
    EDIT_USER: 'ユーザー情報の編集',
    CARD_PURCHASE: '購入済みカード一覧',
    EDIT_ACCOUNT: 'アカウント情報',
    CARD_HOLD: '保有カード'
}

export const ROLE = {
    SUPPER_ADMIN: {
        key: 0,
        label: 'スーパー管理者'
    },
    ADMIN: {
        key: 1,
        label: '管理者'
    },
    STAFF: {
        key: 2,
        label: 'スタッフ'
    },
    MEMBER: {
        key: 3,
        label: '会員'
    }
}

export const STATUS_COMMON = {
    INACTIVE: {
        key: 0,
        label: '無効',
        color: '#D9001B'
    },
    ACTIVE: {
        key: 1,
        label: '有効',
        color: '#03B615'
    }
}

export const USER_STATUS = {
    INACTIVE: {
        key: 0,
        label: '無効',
        color: '#D9001B'
    },
    ACTIVE: {
        key: 1,
        label: '有効',
        color: '#03B615'
    },
    WAITING_CONFIRM: {
        key: 2,
        label: 'アカウント確認待ち',
        color: '#6300BF'
    }
}

export const ACCOUNT_STATUS = {
    SUBSCRIBED: {
        key: 1,
        label: 'サブスク契約中',
        color: '#03B615'
    },
    SUBSCRIPTION_SUSPENDED: {
        key: 2,
        label: 'サブスク停止中',
        color: '#F59A23'
    },
    FORCED_TERMIMATION: {
        key: 3,
        label: '強制解約',
        color: '#D9001B'
    },
    WITHDRAW: {
        key: 4,
        label: '退会',
        color: '#D9001B'
    }
}

export const MEMBERSHIP_TYPE = {
    MONTH: {
        key: 1,
        label: '月額利用料',
        labelPayment: '月額会費'
    },
    YEAR: {
        key: 2,
        label: '年間利用料',
        labelPayment: '年間会費'
    }
}

export const CONTRACT_STATUS = {
    SUBSCRIBED: {
        key: 1,
        label: 'サブスク契約中',
        color: '#03B615'
    },
    SUBSCRIPTION_SUSPENDED: {
        key: 2,
        label: 'サブスク停止中',
        color: '#F59A23'
    },
    FORCED_TERMIMATION: {
        key: 3,
        label: '強制解約',
        color: '#D9001B'
    },
    WITHDRAW: {
        key: 4,
        label: '退会 ',
        color: '#D9001B'
    }
}

export const REDUND_STATUS = {
    NONE: {
        key: null,
        label: '-',
        color: '#000000'
    },
    NOT_YET: {
        key: 0,
        label: '未返金',
        color: '#D9001B'
    },
    REDUNDED: {
        key: 1,
        label: '返金済み',
        color: '#8de203'
    }
}

export const CONTRACT_HISTORY_TYPE = {
    CONTRACT_CREATION: {
        key: 1,
        label: 'サブスク再契約'
    },
    MEMBERSHIP_FEE: {
        key: 2,
        label: '月額料を支払う'
    },
    CANCEL_MEMBER: {
        key: 3,
        label: 'サブスク解約'
    },
    FORCED_TERMINATION: {
        key: 4,
        label: '強制解約'
    },
    WITHDRAW_USER: {
        key: 5,
        label: '退会'
    },
    REFUND: {
        key: 6,
        label: '返金済み'
    },
    SUBSCRIPTION_SUSPENDED: {
        key: 7,
        label: 'サブスク停止中'
    }
}

export const TOOLTIP = {
    ACTIVE: 'ユーザー有効化',
    INACTIVE: 'ユーザー無効化',
    EDIT: '編集',
    VIEW_DETAILS: '詳細表示',
    ADD_ACCOUNT: 'アカウント追加',
    EDIT_ACCOUNT: 'アカウント編集',
    DELETE_ACCOUNT: 'アカウント削除',
    EDIT_USER: 'ユーザー編集',
    EDIT_CONTRACT: 'サブスク契約編集',
    DELETE: '削除',
    DELETE_USER: 'ユーザー削除',
    VIEW_CARD_LIST: 'カード一覧表示',
    VIEW_INQUIRY: 'お問い合わせ表示',
    DELETE_INQUIRY: 'お問い合わせ削除',
    UN_PUBLIC: '非公開',
    PUBLIC: '公開',
    COMMON: {
        ACTIVE: '有効化',
        INACTIVE: '無効化'
    }
}

export const GENDER = {
    MALE: {
        key: 0,
        label: '男性'
    },
    FEMALE: {
        key: 1,
        label: '女性'
    }
}

export const MODAL = {
    TITLE_WARNING_MODAL: '確認'
}

export const TEXT = {
    CANCEL: 'キャンセル',
    CREATE: '保存',
    UPDATE: '保存',
    DELETE: 'Delete',
    SEARCH: '検索',
    CLOSE: '閉じる',
    NO: 'いいえ',
    YES: 'はい'
}

export const STATUS_CARD = {
    NO_OWNER: {
        key: 1,
        label: '保有者なし',
        color: '#03B615'

    },
    NOT_PUBLIC: {
        key: 0,
        label: '非公開',
        color: '#D9001B'
    },
    OWNING: {
        key: 2,
        label: '保有中',
        color: '#F59A23'
    },
    WAIT_FOR_DEPOSIT: {
        key: 3,
        label: '送金待ち',
        color: '#6300BF'
    },
    SENT_MONEY: {
        key: 4,
        label: '送金完了',
        color: 'blue'
    }
}

export const SIDEBAR_TITLE = {
    DASHBOARD: 'ダッシュボード',
    USER_MANAGEMENT: 'ユーザー管理',
    // card
    CARD_MANAGEMENT: 'カード管理',
    CARD_ADD: 'ユーザー追加',
    CARD_UPDATE: 'ユーザー情報の編集・更新',
    PAYMENT_MANAGEMENT: {
        LABEL: '決済管理',
        CARD: 'カード購入取引',
        HOLDER: 'Holder追加取引',
        MEMBERSHIP: '月額料'
    },
    SUBSCRIPTION_CONTRACT_MANAGEMENT: 'サブスク契約管理',
    INQUIRY_MANAGEMENT: 'お問い合わせ管理',
    TEMPLATE_EMAIL: 'メールテンプレート',
    GENERAL_SETTING: {
        LABEL: 'コンテンツ設定',
        TERMS: '利⽤規約設定',
        ACT_OF_SPECIFIED_COMMERCIAL_TRANSACTION: '特定商取引法に基づく表記設定',
        PRIVACY_POLICY: 'プライバシーポリシー設定',
        ABOUT_US: '会社概要設定'
    },
    OTHER_SETTING: 'その他の設定',
    // card type
    CARD_TYPE_MANAGEMENT: 'カード種類の管理'
}

export const TYPE_GENERAL_SETTING = {
    PRIVACY_POLICY: 'privacy_policy',
    ACT_OF_SPECIFIED_COMMERCIAL_TRANSACTION: 'act_of_specified_commercial_transaction',
    TERMS: 'terms',
    ABOUT_US: 'about_us',
    TEMPLATE_EMAIL: {
        SEND_CREATE_NEW_USER_ADMIN: {
            key: 'send_create_new_user_admin',
            label: '[管理者]新規ユーザーの作成'
        },
        SEND_CREATE_NEW_USER_MEMBER: {
            key: 'send_create_new_user_member',
            label: '[ユーザー]新規ユーザーの作成'
        },
        SEND_SUCCESS_CREATE_NEW_USER_MEMBER: {
            key: 'send_success_create_new_user_member',
            label: '[ユーザー]新規ユーザーの作成が成功しました'
        },
        SEND_OLD_EMAIL_ADMIN_CHANGE: {
            key: 'send_old_email_admin_change',
            label: '[管理者][旧メール]メール変更'
        },
        SEND_OLD_EMAIL_MEMBER_CHANGE: {
            key: 'send_old_email_member_change',
            label: '[ユーザー][旧メール]メール変更'
        },
        SEND_NEW_EMAIL_ADMIN_CHANGE: {
            key: 'send_new_email_admin_change',
            label: '[管理者][新メール]メール変更'
        },
        SEND_NEW_EMAIL_MEMBER_CHANGE: {
            key: 'send_new_email_member_change',
            label: '[ユーザー][新メール]メール変更'
        },
        SEND_SUCCESS_EMAIL_ADMIN_CHANGE: {
            key: 'send_success_email_admin_change',
            label: '[管理者]メール変更が成功しました'
        },
        SEND_SUCCESS_EMAIL_MEMBER_CHANGE: {
            key: 'send_success_email_member_change',
            label: '[ユーザー]メール変更が成功しました'
        },
        SEND_ADMIN_FORGET_PASSWORD: {
            key: 'send_admin_forget_password',
            label: '[管理者]パスワード忘れ'
        },
        SEND_MEMBER_FORGET_PASSWORD: {
            key: 'send_member_forget_password',
            label: '[ユーザー]パスワード忘れ'
        },
        SEND_REGISTER_MEMBERSHIP: {
            key: 'send_register_membership',
            label: '[ユーザー]会員登録'
        },
        SEND_ADD_HOLDER: {
            key: 'send_add_holder',
            label: '[ユーザー]ホルダーを追加'
        },
        SEND_ADD_HOLDER_FEE: {
            key: 'send_add_holder_fee',
            label: '[ユーザー]ホルダーを延長'
        },
        SEND_BUY_CARD: {
            key: 'send_buy_card',
            label: '[ユーザー]カードを購入'
        },
        SEND_UPDATE_STATUS_TRANSACTION: {
            key: 'send_update_status_transaction',
            label: '[ユーザー]支払いが成功しました'
        },
        SEND_UPDATE_CARD_INFO: {
            key: 'send_update_card_info',
            label: '[管理者]カード情報の更新'
        },
        SEND_NOTIFY_CARD: {
            key: 'send_notify_card',
            label: '[ユーザー]カードの騰落率通知'
        },
        SEND_INQUIRY: {
            key: 'send_inquiry',
            label: '[ユーザー]お問い合わせを送信'
        },
        SEND_UPDATE_MEMBERSHIP_FEE: {
            key: 'send_update_membership_fee',
            label: '[全ユーザー]会員料金の更新'
        },
        SEND_UPDATE_ADD_HOLDER_FEE: {
            key: 'send_update_add_holder_fee',
            label: '[全ユーザー]ホルダー料金の更新'
        },
        SEND_UPDATE_ACCOUNT_FORCE_TERMINATION: {
            key: 'send_update_account_force_termination',
            label: '[ユーザー]アカウントが強制終了された通知'
        }
    }
};

export const DASHBOARD_CHART_TITTLE = {
    NEW_USER: '新規ユーザー数の統計',
    CARD: 'カード数の統計',
    PAID_MEMBER: '有料会員の統計',
    REVENUE: '売上の統計'
};

export const DASHBOARD_CHART_TYPE = {
    NEW_USER: 'new-user',
    CARD: 'card-type'
};

export const DASHBOARD_DETAILS_TITTLE = {
    NEW_USER: 'ユーザー一覧',
    CARD: 'カード一覧',
    PAID_MEMBER: '有料会員一覧',
    REVENUE: ''
};

export const AIRDROP_STATUS = {
    WAITING_PAYMENT: {
        key: 3,
        label: '送金待ち',
        icon: 'fa-regular fa-circle-question',
        colorIcon: 'text-color-cs-red',
        textIcon: ''
    },
    COMPLETED_PAYMENT: {
        key: 4,
        label: '送金完了',
        icon: 'fa-solid fa-circle-check',
        colorIcon: 'text-color-cs-green',
        textIcon: '送金完了'
    }
};

export const PURCHASE_STATUS = {
    WAIT_FOR_PAYMENT: {
        key: 0,
        label: '支払い待ち',
        colorClassName: 'text-color-cs-4',
        icon: 'fa-regular fa-circle-question',
        colorIcon: 'text-color-cs-red',
        textIcon: '',
        errorTextUpdateAirdropStatus: '本取引は支払い待ちの状態です。'
    },
    SUCCESS: {
        key: 1,
        label: '成功',
        colorClassName: 'text-color-cs-green',
        icon: 'fa-solid fa-circle-check',
        colorIcon: 'text-color-cs-green',
        textIcon: '成功'
    },
    FAILED: {
        key: 2,
        label: '失敗',
        colorClassName: 'text-color-cs-red',
        icon: 'fa-solid fa-circle-xmark',
        colorIcon: 'text-color-cs-red',
        textIcon: '失敗',
        errorTextUpdateAirdropStatus: '本取引は支払い失敗しました。'
    },
    CANCELED: {
        key: 3,
        label: 'キャンセル',
        colorClassName: 'text-color-cs-gray',
        icon: 'fa-solid fa-circle-xmark',
        colorIcon: 'text-color-cs-gray',
        textIcon: 'キャンセル',
        errorTextUpdateAirdropStatus: '本取引はキャンセルされました。'
    }
};

export const PAYMENT_TYPE = {
    CARD: 3,
    HOLDER: 2,
    MEMBERSHIP: 1
}

export const DETAIL_PAYMENT_HEADER = '決済詳細';

export const DASHBOARD_TYPE = {
    OVERVIEW: 0,
    DETAIL: 1,
    EXPORT: 2
}

export const DELETE_FLAG = {
    NOT_DELETED: 0,
    DELETED: 1
}

export const CARD_DASHBOARD_TYPE = {
    NO_OWNER: {
        key: 'noOwner',
        value: 1
    },

    OWNING: {
        key: 'owning',
        value: 2
    },

    PURCHASED: {
        key: 'purchased',
        value: 3
    },

    NEW: {
        key: 'new',
        value: 4
    }
}

export const TRANSACTION_CONFIRM_SCREEN = {
    ACCEPT: 1,
    AIRDROP: 2
}

export const HOLDER_TYPE = {
    FREE: 0,
    FEE: 1
}

export const HOLDER_TYPE_MAPPING = {
    LABEL: {
        [HOLDER_TYPE.FREE]: '無料',
        [HOLDER_TYPE.FEE]: '有料'
    }
}

export const TRANSACTION_FORM = {
    NEW: 1,
    EXTEND: 2
}

export const TRANSACTION_FORM_MAPPER = {
    [TRANSACTION_FORM.NEW]: {
        text: '新規'
    },
    [TRANSACTION_FORM.EXTEND]: {
        text: '継続'
    }
}

export const INQUIRY_STATUS = {
    NOT_STARTED: {
        key: 0,
        label: '未着手',
        colorClassName: 'text-color-cs-red',
        backgroundColorClassName: 'btn-bg-cs-red'
    },
    PROCESSING: {
        key: 1,
        label: '対応中',
        colorClassName: 'text-color-cs-4',
        backgroundColorClassName: 'btn-bg-cs-4'
    },
    COMPLETED: {
        key: 2,
        label: '対応完了',
        colorClassName: 'text-color-cs-green',
        backgroundColorClassName: 'btn-bg-cs-green'
    }
}