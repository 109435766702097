import ApiService from './ApiService';

class CardTypeApi extends ApiService {

    constructor() {
        super('card-type');
    }

    getAllCardType(payload) {
        return this.get('', payload);
    }

    
    createCardType(payload) {
        return this.post('', payload, { 'Content-Type': 'multipart/form-data' });
    }

    getCardType({ id, payload }) {
        return this.get(id, payload);
    }

    updateCardType({ id, payload }) {
        return this.put(id, payload, { 'Content-Type': 'multipart/form-data' });
    }

    updateStatusCardType({ id, payload }) {
        return this.put(`status/${id}`, payload);
    }

    deleteCardType({ id, payload }) {
        return this.delete(id, payload);
    }
}

export default CardTypeApi;
