import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { ReactNotifications, Table } from '../../../../components';
import { MSG, ROLE, USER_STATUS, DASHBOARD_DETAILS_TITTLE, STATUS_CARD, DASHBOARD_TYPE, FORMAT_DATE_TIME } from '../../../../core/configs/constants';
import { useStore } from '../../../../core/utils/hook';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { FormatNumber } from '../../../../components';
import moment from 'moment';

const CardByMonthDetailsStatistical = observer(() => {
    //other
    const navigate = useNavigate();
    const { time } = useParams();

    // store
    const { dashboardStore: { cardsDetail, getDashboardCard, cardPaging, clean, setAttrObservable } } = useStore();

    // state
    const { register, watch, getValues } = useForm();
    const status = watch('status');

    // lifecycle
    useEffect(() => {
        onSearch();
        return () => {
            clean();
        }
    }, []) // status

    // function
    const onSearch = (data) => {
        clean();
        onFetchCardData(data);
    }

    const onFetchCardData = (tableData) => {
        const month = time.split('-')[0];
        const year = time.split('-')[1];
        const key = time.split('-')[2];
        setAttrObservable('cardPaging', tableData, true, false);
        getDashboardCard({
            ...getValues(),
            ...tableData,
            year,
            month,
            type: DASHBOARD_TYPE.DETAIL,
            key: key
        })
    }


    // columns
    const cardColumns = [
        {
            Header: 'カードID',
            accessor: 'code',
            disableSortBy: true,
            Cell: ({ row: { original } }) => {
                return (
                    <div>
                        {
                            original?.deleteFlag === 0 ? (
                                <Link to={`/card/${original?.id}`} >{original?.code}</Link>
                            ) : (
                                <div>{original?.code}</div>
                            )
                        }
                    </div>
                );

            },
            width: '10%'
        },
        {
            Header: '発行日時',
            accessor: 'publicAt',
            disableSortBy: true,
            Cell: ({ row: { original } }) => {
                return (
                    <div>
                        {original?.publicAt ? moment(original?.publicAt).format(FORMAT_DATE_TIME) : ''}

                    </div>
                );
            },
            width: '15%'
        },
        {
            Header: '基準レート',
            accessor: 'baseRate',
            disableSortBy: true,
            Cell: ({ row: { original } }) => {
                return (
                    <div>
                        <FormatNumber value={original?.baseRate} suffix=' USDT' />
                    </div>
                );
            },
            width: '10%'
        },
        {
            Header: 'カード価格',
            accessor: 'purchase_price',
            disableSortBy: true,
            Cell: ({ row: { original } }) => {
                return (
                    <div>
                        <FormatNumber value={original?.cardPrice} suffix=' 円' />
                    </div>
                );
            },
            width: '10%'
        },
        {
            Header: 'Airdrop',
            accessor: 'airdrop',
            disableSortBy: true,
            Cell: ({ row: { original } }) => {
                return (
                    <div>
                        <FormatNumber value={original?.airdrop} suffix=' SML'/>
                    </div>
                );
            },
            width: '10%'
        }
        // {
        //     Header: '公開ステータス',
        //     accessor: 'status',
        //     disableSortBy: true,
        //     Cell: ({ row: { original } }) => {
        //         // const statusKey = Object.keys(USER_STATUS).filter(e => USER_STATUS[e].key === original?.status)?.[0] ?? null;
        //         const roleKey = Object.keys(STATUS_CARD).filter(e => STATUS_CARD[e]?.key === original?.status)?.[0] ?? null;

        //         return (
        //             <div style={{ 'color': STATUS_CARD[roleKey]?.color }} >
        //                 {roleKey && STATUS_CARD[roleKey]?.label}
        //             </div>
        //         );
        //     },
        //     width: '10%'
        // },
    ]

    return (
        <div className='user-screen'>
            <div className='d-flex justify-content-between align-items-center'>
                <div className='container-title'>{DASHBOARD_DETAILS_TITTLE.CARD}</div>
                <button className='btn-default btn-search-back' onClick={() => navigate('/')}>
                    <i className="fa-solid fa-angles-left"></i>
                    <span className='mg-l-10'>戻る</span>
                </button>
            </div>

            <div className='container-content'>
                {/* <div className='float-end'>
                    {
                        <select {...register('status')} className='min-width-200'>
                            <option value={''}>すべて</option>
                            {
                                Object.keys(STATUS_CARD).map(e =>
                                    <option key={STATUS_CARD[e]?.key} value={STATUS_CARD[e]?.key}>{STATUS_CARD[e].label}</option>
                                )
                            }
                        </select>
                    }
                </div> */}
                <Table
                    columns={cardColumns}
                    data={cardsDetail || []}
                    disablePaging={false}
                    enableServerSidePaging={true}
                    initialTableState={cardPaging}
                    onFetch={(tableData) => onFetchCardData(tableData)}
                    className='lst-admin-table'
                />
            </div>
        </div>
    );
});

export default CardByMonthDetailsStatistical;