import { useStore } from '../../../core/utils/hook';
import { FormatNumber } from '../../../components';
import { useNavigate } from 'react-router-dom';
import { FORMAT_DATE_TIME, PURCHASE_STATUS, TRANSACTION_FORM_MAPPER } from '../../../core/configs/constants';
import './style.scss';
import classNames from 'classnames';
import moment from 'moment';
import { useMemo } from 'react';

const DetailsPayment = ({ type }) => {
    //other
    const navigate = useNavigate();

    //store
    const { modalStore: { hide }, paymentStore: { transactionDetail } } = useStore();

    // eslint-disable-next-line max-len
    const findStatus = useMemo(() => Object.keys(PURCHASE_STATUS).filter(key => PURCHASE_STATUS[key].key === transactionDetail?.status)?.[0], [transactionDetail]);

    return (
        <div className='modal-body disable'>
            <div className='row mg-t-4'>
                <div className='row justify-content-between'>
                    <div className='row col-6 align-items-center'>
                        <span className='col-5 fw-bold'>取引ID</span>
                        <span className='col-7 fw-normal'>{transactionDetail?.code}</span>
                    </div>
                    <div className='row col-6 align-items-center justify-content-end'>
                        <span className='col-5 fw-bold'>ユーザーID</span>
                        <span className='col-7 fw-normal'>
                            <span 
                                role='button'
                                className='text-color-cs-4 cursor-pointer'
                                onClick={() => {
                                    hide();
                                    navigate(`/user/${transactionDetail?.account?.user?.id}`);
                                }}
                            ><u>{transactionDetail?.account?.user?.code}</u></span>
                        </span>
                    </div>
                </div>
                <div className='row mg-t-16 justify-content-between'>
                    <div className='row col-6 align-items-center'>
                        <span className='col-5 fw-bold'>作成日</span>
                        <span className='col-7 fw-normal'>{moment(transactionDetail?.createdAt).format(FORMAT_DATE_TIME)}</span>
                    </div>
                    <div className='row col-6 align-items-center justify-content-end'>
                        <span className='col-5 fw-bold'>アカウントID</span>
                        <span className='col-7 fw-normal'>
                            <span 
                                role='button'
                                className='text-color-cs-4 cursor-pointer'
                                onClick={() => {
                                    hide();
                                    navigate(`/user/account/${transactionDetail?.account?.id}`);
                                }}
                            ><u>{transactionDetail?.account?.code}</u></span>
                        </span>
                    </div>
                </div>
                <div className='row mg-t-16 justify-content-between'>
                    <div className='row col-6 align-items-center'>
                        <span className='col-5 fw-bold'>支払日</span>
                        <span className='col-7 fw-normal'>
                            {transactionDetail?.status ===  PURCHASE_STATUS.SUCCESS.key? (
                                <div>{moment(transactionDetail?.transactionDate).format(FORMAT_DATE_TIME)}</div>
                            ): (
                                <div></div>
                            )}
                        </span>
                    </div>
                    <div className='row col-6 align-items-center justify-content-end'>
                        <span className='col-5 fw-bold'>名前-本名</span>
                        <span className='col-7 fw-normal'>{transactionDetail?.account?.user?.fullName}</span>
                    </div>
                </div>
                <div className='row mg-t-16 justify-content-between'>
                    <div className='row col-6 align-items-center'>
                        <span className='col-5 fw-bold'>分類</span>
                        <span className='col-7 fw-normal'>{transactionDetail?.transactionForm ? 
                            TRANSACTION_FORM_MAPPER[transactionDetail?.transactionForm].text : ''}</span>
                    </div>
                    <div className='row col-6 align-items-center justify-content-end'>
                        <span className='col-5 fw-bold'>メールアドレス</span>
                        <span className='col-7 fw-normal'>{transactionDetail?.account?.user?.email}</span>
                    </div>
                </div>
                <div className='row mg-t-16 justify-content-between'>
                    {
                        type === 'holder' ? (
                            <div className='row col-6 align-items-center'>
                                <span className='col-5 fw-bold'>Holder数</span>
                                <span className='col-7 fw-normal'>{transactionDetail?.numberHolder}</span>
                            </div>
                        ) : (
                            <div className='row col-6 align-items-center'>
                                <span className='col-5 fw-bold'>会員料金プラン</span>
                                <span className='col-7 fw-normal'>{transactionDetail?.membershipType === 1 ? '月額会費' : '年間会費'}</span>
                            </div>
                        )
                    }
                    <div className='row col-6 align-items-center justify-content-end'>
                        <span className='col-5 fw-bold'>電話番号</span>
                        <span className='col-7 fw-normal'>{transactionDetail?.account?.user?.phoneNumber}</span>
                    </div>
                </div>
                <div className='row mg-t-16 justify-content-between'>
                    <div className='row col-6 align-items-center'>
                        <span className='col-5 fw-bold'>金額</span>
                        <span className='col-7 text-color-cs-red fw-bold'>
                            <FormatNumber value={transactionDetail?.totalPayment} suffix=' 円'/>
                        </span>
                    </div>
                    <div className='row col-6 align-items-center  justify-content-end'>
                        <span className='col-5 fw-bold'>支払いステータス</span>
                        {
                            findStatus && <span className={classNames('col-7', PURCHASE_STATUS[findStatus]?.colorClassName ?? '')}>
                                {PURCHASE_STATUS[findStatus]?.label}
                            </span>
                        }

                    </div>
                </div>
            </div>
        </div>
    );
}

export default DetailsPayment;