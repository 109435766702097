import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import 'react-tabs/style/react-tabs.css';
import '../style.scss';
import { FormatNumber } from '../../../components';
import { MSG } from '../../../core/configs/constants';
const ViewCardList = observer((props) => {
    //props
    const { listCardOwner } = props
    const navigate = useNavigate();




    // props





    return (
        <div className=''>
            {listCardOwner?.length > 0 ?
                listCardOwner.map((item) => (
                    <div key={item?.id} className='d-flex align-items-center justify-content-center col-12  border-bottom border-dark py-4 '>
                        <div className='width-121 height-154 col-2'>
                            <img className='width-120 height-154' src={item?.image}></img>
                        </div>
                        {item?.variationRate?.toFixed(2) < 0 ?
                            <div className='d-flex  align-items-center justify-content-center  text-danger col-2 fs-3'>
                                <div className='col-2 m-auto'>
                                    {/* <i className="fa-solid fa-arrow-down"></i> */}
                                    <i className="fa-solid fa-down-long"></i>
                                </div>
                                <div className='m-auto col-10 d-block'>
                                    <div className='mg-b-1 w-100' >騰落率</div>
                                    <span className='font-bold fs-4'>{item?.variationRate?.toFixed(2)} %</span>
                                </div>
                            </div> : <div className='d-flex  align-items-center justify-content-center text-success col-2 fs-3'>
                                <div className='col-2 m-auto'>
                                    <i className="fa-solid fa-up-long"></i>
                                </div>
                                <div className='m-auto col-10 d-block '>
                                    <div className='mg-b-1 w-100' >騰落率</div>
                                    <span className='font-bold fs-4 '>+ {item?.variationRate?.toFixed(2)} %</span>
                                </div>
                            </div>}
                        <div className='col-4 flex'>
                            <div className='d-flex align-items-center mb-2  '>
                                <span className='width-120 fw-bold font-bold'>発行日時</span>
                                <span >{item?.publicAt}</span>
                            </div>

                            <div className='d-flex align-items-center mb-2 '>
                                <span className='width-120 fw-bold font-bold'>基準レート</span>
                                <span >{item?.baseRate} USDT</span>
                            </div>
                            <div className='d-flex align-items-center mb-2 '>
                                <span className='width-120 fw-bold font-bold'>現在レート</span>
                                <span >{(+item?.currentRate)?.toFixed(6)} USDT</span>
                            </div>
                            <div className='d-flex align-items-center mb-2 '>
                                <span className='width-120 fw-bold font-bold'>Airdrop</span>
                                {(item?.airdrop || item?.airdrop === 0) ?
                                    <span>
                                        <FormatNumber statusDisplay='text' value={item?.airdrop} suffix=' SML' />
                                    </span> : ''}
                            </div>

                        </div>
                        <div className='col-4' >
                            <div className='d-flex align-items-center  mb-2'>
                                <span className='width-120 fw-bold font-bold'>管理番号</span>
                                <span >{item?.code}</span>
                            </div>
                            <div className='d-flex align-items-center  mb-2'>
                                <span className='width-120 fw-bold font-bold'>カード価格</span>
                                <span>
                                    <FormatNumber statusDisplay='text' value={item?.cardPrice} suffix=' 円' />
                                </span>
                            </div>
                            <div className='d-flex align-items-center  mb-2'>
                                <span className='width-120 fw-bold font-bold'>現在価格</span>
                                <span>
                                    <FormatNumber statusDisplay='text' value={item?.currentPrice} decimalScale={0} suffix=' 円' />
                                </span>
                            </div>
                        </div>

                    </div>
                )) : <div className='text-center fs-default'>{MSG['inform.no_data']}</div>}
        </div>

    )
})

export default ViewCardList;